.purchaseOrderChartSearchContainer {
    padding: 0 15px;
}

.purchaseOrderChartSearchContainer > #purchaseOrderChart {
    width: calc(50vw - 160px);
    height: 204px;
}
/* Plotly's support for being "responsive" doesn't support media queries nor many sizing options, this is a hack to make it work with what it seems to support (viewport size + px) */
.purchaseOrderChartSearchContainer.sm > #purchaseOrderChart {
    width: calc(100vw - 285px);
    height: 204px;
}
/* Plotly's support for being "responsive" doesn't support media queries nor many sizing options, this is a hack to make it work with what it seems to support (viewport size + px) */
.purchaseOrderChartSearchContainer.xs > #purchaseOrderChart {
    width: calc(100vw - 45px);
    height: 204px;
}

.purchaseOrderChartSearchContainer > .button-processing {
    width: 100%;
    height: 204px;
    background-color: #eee;
}

.purchaseOrderChartSearchContainer > #purchaseOrderDetailsChart {
    max-width: calc(100vw - 80px);
    width: 950px;
    height: 204px;
    margin: 0 auto;
}


purchase-order-details .tab-content,
purchase-order-details .tab-content > .active {
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
}

purchase-order-details oc-window-container .row {
    margin-left: 0px;
    margin-right: 0px;
}