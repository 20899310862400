.amt-filter {
    margin-bottom: 10px;
}

.amt-footer.amt-filter-footer {
    padding-bottom: 10px;
}

.amt-filter-dropdown {
    background: #efeff2;
}

.amt-filter-footer, .amt-wider-footer {
    margin: 0px;
}

.amt-filter-arrow {
    float: left;
    margin: 0 10px 0 0;
    cursor: pointer;
}

.amt-filter-text {
    cursor: pointer;
}
.amt-filter-arrow:hover {
    color: #ee2722;
}

.amt-filter-control {
    height: 30px;
}

.amt-filter-date{
    border-radius: 5px;
}
