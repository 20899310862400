@import "../base/variables";

//TODO: pretty sure everything here needs to be deleted with fire, who calls a table element table, what is that supposed to mean?!

.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px 15px;
    border-top: 1px solid $grid-border;
}

.table > thead > tr > th {
    padding: 8px 15px;
    border-bottom: 1px solid $grid-border;
    vertical-align: middle;
}

.table-bordered {
    border-color: $grid-border;
}

.table-bordered > tbody > tr > td {
    border-color: $grid-border;
}

.table-bordered > thead > tr > th {
    border-color: $grid-border;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: $grid-alt-row-bg;
}

.table-striped > thead > th {
    background-color: $grid-header-bg;
    border-right: 1px solid $grid-border;
}

.table-striped > thead > th:last-child {
    border-right: none;
}

html.mobile table.table td {
    font-size: 1.2em;
}

html.mobile .table > tbody > tr > td.active,
html.mobile .active-cell {
    background-color: #d9edf7;
}