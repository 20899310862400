@import '../../../../styles/base/_variables.scss';

visual-inspection-history {

    .inspection-details {
        display: grid;        
        grid-template-columns: 50fr 50fr;
    }

    .due-date {
        font-weight: bold;
    }

    .overdue {
        color: $red;
    }

    .inspection-history {
        margin-top: 10px;
        height: 400px;
    }
}
