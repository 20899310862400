@import "../base/variables";

html.mobile .receive-title, .receive-summary-title {
    font-size: $large-font-size;
}

html.mobile .receive-summary-label {
    font-size: $large-font-size;
}

html.mobile .receive-summary-text {
    font-size: $large-font-size;
}

html.mobile .purchase-type-control {
    input[type="radio"] {
        height: 30px;
        width: 30px;
    }

    .radio-inline {
        padding-left: 35px;
        margin-right: 30px;
        line-height: 35px;
    }

    .amt-radio-description {
        left: 0px;
        margin-left: 40px;
        display: inline-block;
    }

    .amt-radio-label {
        top: -8px;
    }
}

component-receive amt-wizard {
    flex: 1 0 0px;
    display: flex;
}

//TODO: '.component-receive amt-grid' used by vehicle receive?
.component-receive amt-grid,
component-receive .flex-grid .amt-grid,
component-receive .k-grid-content,
component-receive oc-dynamic-grid {
    display: flex;
    flex: 1 0 0px;
}

component-receive .flex-grid .amt-grid,
component-receive oc-dynamic-grid {
    flex-direction: column;
}

component-receive .attachment-grid-header {
    margin-right: 19px !important;
}

component-receive .row:not(.attachment-grid-header) {
    margin: 0px !important;
}

vehicle-receive-popup {
    flex: 1 0 630px;
    display: flex;
    flex-direction: column;
}

vehicle-receive-popup .tab-content {
    display: flex;
    flex: 1 0 0px;
}

vehicle-receive-popup .tab-content > .active {
    display: flex !important;
    flex-direction: column !important;
    flex: 1 0 0px !important;
}

vehicle-receive-popup .receive-tabs .tab-content {
    padding: 10px 0px 0px 0px;
}

vehicle-receive-popup oc-dynamic-grid {
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
}

vehicle-receive oc-dynamic-grid {
    flex-direction: column;
}

vehicle-receive amt-wizard,
vehicle-receive oc-dynamic-grid {
    flex: 1 0 0px;
    display: flex;
}

vehicle-receive .attachment-grid-header {
    margin-right: 19px !important;
}

vehicle-receive .row:not(.attachment-grid-header) {
    margin: 0px !important;
}

vehicle-receive amt-content {
    padding: 0px !important;
}
