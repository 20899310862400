.vehicle-picker {
    margin-left: 10px;
    margin-right: 10px;
    width: inherit;
}

.fieldsurvey-picker .multi-field-picker-tablelist td /*,
.status-change-picker .multi-field-picker-tablelist td */ {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.fieldsurvey-picker .multi-field-picker-tablelist th,
.status-change-picker .multi-field-picker-tablelist th {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: normal;
}

.fieldsurvey-picker .multi-field-picker-tablelist .th-inner-label,
.fieldsurvey-picker .multi-field-picker-tablelist td div,
.status-change-picker .multi-field-picker-tablelist .th-inner-label,
.status-change-picker .multi-field-picker-tablelist td div {
    display: inline; //I dunno who thought it was a good idea to put divs in, but make it like there aren't divs here
}

.fieldsurvey-picker th.date-col {
    min-width: 13.333ch;
    width: 15%;
}

.fieldsurvey-picker th.spec-col {
    width: auto;
}

.fieldsurvey-picker th.surv-col,
.status-change-picker th.tick-col {
    width: 48px;
}

.fieldsurvey-picker td.surv-col,
.status-change-picker td.tick-col {
    text-align: center;
}

.fieldsurvey-picker td.date-col {
    text-align: center;
}

.status-change-picker th.serial-col {
    width: 20ch;
}

.status-change-picker th.spec-col {
    width: 30%;
}

.status-change-picker th.stat-col {
    width: auto;
}

.status-change-picker td.stat-col {
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-change-picker th.desc-col {
    width: 20ch;
}

.status-change-picker th.dis-col {
    width: 10ch;
}

.status-change-picker th.num-col {
    width: 10ch;
}

.status-change-picker td.num-col,
.status-change-picker td.dis-col {
    text-align: right;
}

.status-change-picker .fixed-table-container .fixed-table-container-inner {
    overflow-y: auto;
}

@media (orientation: portrait) {
    .status-change-picker .dis-col {
        display: none;
    }
}
