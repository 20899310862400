@import "../base/variables";

.client-details {
    .logo-thumbnail .ocThumbnail > span > img {
        height: 50px !important;
        margin-left: 15px;
    }
}

//HACK: dirty hack to get Site Details to fill screen (not sure if this is ideal, don't replicate, partial written this way so it could be released as CSS only patch if needed as issue affects prod)
site-details,
site-details > ng-form {
    display: contents !important;
    flex: none !important;
}

.site-details {
    width: 100%;

    .logo-thumbnail .ocThumbnail > span > img {        
        height: 50px !important;
        margin-left: 15px;
    }

    .components-group-header {
        background-color: $black4;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 100%;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .components-group-body {
        margin-bottom: 15px;
    }
}

.admin-warning {
    font-weight: 600;
}

#reportFrame {
    width: 100%;
    position: absolute;
    height: calc(100vh - 50px);
    height: -webkit-fill-available;
    height: stretch;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}