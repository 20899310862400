@import "../base/variables";

.form-validation-list {
    list-style: none;
}

.form-validation-list > .ng-invalid {
    color: $validation-red;
}

.form-validation-header {
    font-weight: bold;
    color: $validation-red;
}


.form-horizontal .control-label {
    margin-bottom: 0.5em; /* Add some spacing between this control and the next */
    margin-top: 0;
}

.form-group {
    margin-bottom: 10px;
}


.form-control {
    border-color: $badge-color;
    border-radius: 5px;
}

.form-control,
.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control:focus {
    border-color: #A59D9D;
}


html.k-ie10 div.modal div.modal-body input.form-control {
    padding-right: 0;
}

html.k-ie10 div.modal div.modal-body input.form-control:focus {
    padding-right: 12px;
}

.form-control-spin {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    margin-top: -7px;
}

.form-control-string {
    padding: 6px 12px;
    box-sizing: border-box;
    display: block;
    height: 30px;
}


.form-validation .form-control.ng-dirty.ng-invalid {
    border-color: #E91D25;
}

.form-validation .form-control.ng-dirty.ng-valid,
.form-validation .form-control.ng-dirty.ng-valid:focus {
    border-color: #9BBB59;
}

.form-validation .i-checks .ng-invalid.ng-dirty + i {
    border-color: #E91D25;
}


.control-group .control-label {
    text-align: left;
    color: #575757;
    font-size: 0.9em;
    padding-top: 16px;
}

.receive-title {
    color: #575757;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
}

.receive-summary-title {
    @extend .receive-title;
    align-self: center;
}

.receive-summary {
    border-top: 1px solid #ddd;
    margin-left: 10px;
    margin-top: 15px;
}

.bulk-move-label {
    font-size: 14px;
    font-weight: bold;
}

/* TODO: this is a hack to avoid updating uses of form-group on like the component search page, and to be more specific with this rule */
:not(.form-horizontal) .form-group:not(.form-horizontal) .control-label {
    text-align: left;
    color: #575757;
    font-size: 0.9em;
}

.text-error .form-group .control-label {
    color: #ff0000
}

.form-control-static span {
    line-height: 30px;
}

.control-label {
    margin-bottom: 2px;
    margin-top: 11px;
}


.required-indicator {
    border: 1px solid $red1;
}

.invalid {
    border: 1px $validation-red solid !important;
}


/*this following CSS block can be uncommented to highlight dirty, pristine and invalid form controls*/
/*
amt-combobox.ng-pristine > div > div > div,
input.ng-pristine,
.form-control.ng-pristine, 
.form-control.ng-untouched {
    border-left: 3px solid #9BBB59 !important;
}

amt-combobox.ng-dirty > div > div > div,
input.ng-dirty,
.form-control.ng-dirty {
    border-left: 3px solid #ffd800 !important;
}

amt-combobox.ng-invalid > div > div > div,
input.ng-invalid,
.form-control.ng-invalid {
    border-left: 3px solid $red1 !important;
}*/


.option.selected {
    background: $grid-selected-row-bg;
    color: $grid-pagination-button-selected-fg;
}


.visual-inspection-item-form {
    max-height: 500px;
    overflow-y: auto;
}


.form-checkbox-padding {
    padding-top: 2px
}

.form-checkbox {
    margin-top: 7px !important;
}

.status-change-header {
    color: #575757;
    font-size: 1.8em;
    font-weight: bold;
}

oc-property-row > ng-transclude,
oc-property-row > amt-display > ng-transclude {
    display: contents;
}