@import "../base/variables";

// disabled is being overriden by glyphicons.css, on the keypad, so we're setting it specifically here
oc-keypad button:disabled {
    color: #bebebe;
}

oc-keypad {
    display: block;
    margin: 0 7px 7px 7px;
    position: relative;
    width: 200px;
    height: 100%;
    background: $grey2;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    touch-action: manipulation;
}

oc-keypad button {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #ffffff), color-stop(100%, #e5e5e5));
    background: -webkit-linear-gradient(#ffffff, #e5e5e5);
    background: -moz-linear-gradient(#ffffff, #e5e5e5);
    background: -o-linear-gradient(#ffffff, #e5e5e5);
    background: linear-gradient(#ffffff, #e5e5e5);
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 10px;
    box-sizing: border-box;
    display: block;
    float: left;
    height: calc(16.667vh - 25px);
    max-height: 56px;
    padding: 0px;
    border: none;
    font-size: 22px;
    touch-action: none; //old versions of like iOS safari should still use "touch-action: manipulation" from the parent rule
}


oc-keypad button:active {
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #dadada), color-stop(100%, #bebebe));
    background: -webkit-linear-gradient(#dadada, #bebebe);
    background: -moz-linear-gradient(#dadada, #bebebe);
    background: -o-linear-gradient(#dadada, #bebebe);
    background: linear-gradient(#dadada, #bebebe);
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px;
}

oc-keypad .controls,
oc-keypad .numpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    padding: 8px;
    position: absolute;
    width: 200px;
}

stocktake oc-keypad .controls {
    grid-template-columns: repeat(2, 1fr);
}

stocktake oc-keypad .ctrl-tab,
stocktake oc-keypad .ctrl-shtab,
stocktake oc-keypad .numpad-ctrl {
    display: none;
}

oc-keypad .numpad {
    bottom: 0px;
}

stocktake oc-keypad {
    height: calc(100vh - 170px);
}

stocktake oc-keypad .numpad-zero {
    grid-column: span 3;
}

html.desktop stocktake oc-keypad .numpad,
html.desktop stocktake oc-keypad .mobile-only {
    display: none;
}

html.desktop stocktake oc-keypad .ctrl-clear {
    grid-column: span 2;
}

field-surveys oc-keypad .stocktake-only,
maintenance oc-keypad .stocktake-only,
html.desktop field-surveys oc-keypad,
html.desktop maintenance oc-keypad {
    display: none;
}

field-surveys oc-keypad .ctrl-mvleft,
maintenance oc-keypad .ctrl-mvleft {
    order: 0;
}

field-surveys oc-keypad .ctrl-up,
maintenance oc-keypad .ctrl-up {
    order: 1;
}

field-surveys oc-keypad .ctrl-mvright,
maintenance oc-keypad .ctrl-mvright {
    order: 2;
}

field-surveys oc-keypad .ctrl-shtab,
maintenance oc-keypad .ctrl-shtab {
    order: 3;
}

field-surveys oc-keypad .ctrl-down,
maintenance oc-keypad .ctrl-down {
    order: 4;
}

field-surveys oc-keypad .ctrl-tab,
maintenance oc-keypad .ctrl-tab {
    order: 5;
}


oc-keypad .numpad-ctrl {
    font-size: 14px;
}


field-surveys oc-keypad {
    height: calc(100vh - 74px);
}

maintenance oc-keypad {
    height: calc(100vh - 133px);
}

@media (orientation: portrait) {

    html.mobile field-surveys oc-keypad {
        height: 200px;
    }

    html.mobile maintenance oc-keypad {
        height: 200px;
    }

    html.mobile .field-survey-column {
        width: 100vw;                
        z-index: 100;
        position: fixed;
        bottom: 0;
    }

    html.mobile maintenance .field-survey-column {
        left: 3px; //HACK: should really convert keypad sizing and placement to flexbox (though the current design might allow seeing through the middle - which was implied by the design to be split*)
        //*except that doesn't work of course as the keypad would still cover/obscure the fields and be unusable without further major changes to how it works
    }

    html.mobile field-surveys oc-keypad .numpad,
    html.mobile maintenance oc-keypad .numpad {
        left: 0;
    }

    html.mobile field-surveys oc-keypad .controls,
    html.mobile maintenance oc-keypad .controls {
        bottom: 0;
        right: 0;
    }

    html.mobile .fs-content-pane {
        max-height: calc(100vh - 275px);
    }

    html.mobile .readings-tab-content {
        padding-bottom: 208px;
    }

    html.mobile .displayKeyPadLeft oc-keypad .numpad {
        right: 0;
        left: auto;
    }

    html.mobile .displayKeyPadLeft oc-keypad .controls {
        right: auto;
        left: 0;
    }

    html.mobile field-surveys oc-keypad {
        width: auto;
    }

    html.mobile maintenance oc-keypad {        
        width: auto;
    }

    html.mobile field-surveys oc-keypad button,
    html.mobile maintenance oc-keypad button {
        max-height: 40px;
    }
}

@media (orientation: portrait) and (max-width: 419px) {
    field-surveys oc-keypad .controls,
    maintenance oc-keypad .controls {
        display: none;
    }

    field-surveys oc-keypad .numpad,
    maintenance oc-keypad .numpad {
        width: 100%;
    }
}

html.mobile stocktake .right-panel {
    flex: none;
}

html.desktop stocktake .right-panel {
    padding-top: 8px;
}

stocktake oc-keypad {
    position: fixed;
}

stocktake oc-keypad button {
    height: calc(12.5vh - 18px);
}

html.mobile stocktake oc-keypad {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    top: 64px;
    right: 0;
}

html.mobile stocktake oc-keypad.numpad-left {
    left: 0;
    right: auto;
}

html.desktop stocktake oc-keypad {
    position: relative;
    height: 137px;
    display: block;
    margin: 0;
}

html.desktop .displayStocktakeGrid {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 8px;
}

html.mobile .displayStocktakeGrid {
    width: calc(100vw - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 8px;
    position: fixed;
    height: 100%;
}

.displayStocktakeGridRight {
    left: 215px;
}
