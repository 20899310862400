@import "../base/variables";

html.mobile {
    .synchronisationModule {
        height: 92px;
        font-size: 22px;
        width: 100%;
        box-sizing: border-box;

        @media (max-width: 767px) {
            font-size: 18px;
        }

        div {
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .progress {
        background: rgba(245, 245, 245, 1);
        border: 0 solid rgba(245, 245, 245, 1);
        border-radius: 6px;
        height: 80px;
        font-size: 20px;
        box-sizing: border-box;
        margin-bottom: 0px;
    }

    .oc-progress {
        border-top: 1px solid $progress-button-border;
        background: $white;
        border-radius: 0px 0px 6px 6px;
        height: 80px;
        box-sizing: border-box;
    }


    .transparent {
        opacity: 0.6 !important;

        .progress-button-data-sync span {
            color: $black;
        }

        .progress-button-data-sync .progress-bar {
            color: $black;
        }
    }
    /* force the included span to centre - by default bootstrap places text in the progress*/
    oc-progress .progress {
        position: relative;
    }

    .progress-bar-not-active {
        background: rgba(0, 0, 0, 1);
    }

    .processing {
        font-size: $large-font-size;
    }
}

.progress-button-text {
    font-size: 1.4rem;
    color: $progress-button-color;
}


.downloading {
    .progress-button-data-sync span {
        color: $black;
    }

    .progress-button-data-sync .progress-bar {
        color: $black;
    }

    .oc-progress .progress-text-box span {
        color: $black;
    }
}

.data-sync-button {
    background: $progress-button-bg;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border: 1px solid $progress-button-border;
    border-radius: 6px;
}

.progress-button-data-sync.disabled {
    opacity: .3;
    cursor: not-allowed;
    pointer-events: none;
    touch-action: none;
}

/* progress buttons for Mobile data sync */
.progress-button-data-sync {
    position: relative;
    background: $white;
    color: $black2;
    border: 1px solid $progress-button-border !important;
    border-radius: 4px;
    height: 35px;
    font-size: 20px;
    box-sizing: border-box;

    span {
        position: absolute;
        display: block;
        font-size: 20px;
        line-height: 25px;
        width: 100%;
        top: 4px;

        &.subtext {
            font-size: 10px;
            line-height: 20px;
            top: 35px;
        }
    }

    .progress-bar {
        border-radius: 3px;
        display: flex;
        align-items: center;
    }
}

.progress-bar-loading {
    border-radius: 6px;
    display: flex;
    color: $black;
    align-items: center;
}
.progress-button-data-sync-msg {
    text-align: center;
    font-size: $large-font-size;
}

.oc-progress .progress-text-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;

    span {
        width: 100%;
    }
}

.module-status-ready {
    background-color: $progress-button-green;
    color: $black;
}

.module-status-data {
    background-color: $progress-button-blue;
    color: $white;
}

.module-status-warning {
    background-color: $progress-button-yellow;
    color: $black;
}

.module-status-old {
    background-color: $progress-button-red;
    color: $white;
}

.module-status-inactive {
    background: rgba(33,33,33,1);
    background-color: $black;
    color: $white;
}

.data-form {
    border-top: 1px solid $black3;
    margin-top: 15px;
    padding-top: 15px;
}

.data-sync-content  {
    margin-top:30px;
}
