.amt-form-control {
    width: 100%;
}

.amt-form-control .amt-form-control-button {
    border: 1px rgb(204, 204, 204) solid;
    border-radius: 5px;
    height: 34px;
    cursor: pointer;
    position: relative;
    padding-right: 60px;
    background-color: white;
    box-sizing: border-box;
}

.amt-form-control .amt-form-control-button.disabled {
    background-color: #EBEBE4;
}

.amt-form-control .item-display {
    height: 28px;
    padding: 2px 10px 2px 10px;
    width: 100%;
    width: -webkit-fill-available;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
    background-color: inherit;
    margin-top: 2px;
    margin-left: 2px;
    z-index: 1000;
    font-size: 14px;
    box-sizing: border-box;
}

.amt-form-control .caret-container {
    border-left: 1px rgb(204, 204, 204) solid;
    height: 100%;
    width: 30px;
    position: absolute;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 0;
    right: 0;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
}

.amt-form-control .caret {
    /*position: relative;
        display: inline-block;
        vertical-align: middle;*/
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-left: 0;
    margin-top: 12px;
    /*text-align: center;*/
}

.amt-form-control .closebutton {
    padding: 0;
    position: absolute;
    right: 40px;
    top: 4px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    color: #ccc;
    text-shadow: 0 1px 0 #ffffff;
}


.amt-form-control .closebutton:hover {
    text-decoration: none;
    cursor: pointer;
    color: #6f6e6e;
}

.amt-form-control .disabled .closebutton:hover {
    text-decoration: none;
    cursor: not-allowed;
    color: #ccc;
}

.amt-form-control .dropdown-menu {
    width: 100%;
    position: absolute;
    background: #F3F3F3;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px 0;
}

.amt-form-control .dropdown-menu-up {
    box-shadow: rgba(0, 0, 0, 0.2) 0 -5px 10px 0;
}

.amt-form-control .dropdown-menu li {
    padding: 0 5px;
    margin: 0;
    display: block;
    overflow: hidden;
}

.amt-form-control .btn-group {
    width: 100%;
}

.amt-form-control .btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.amt-form-control .open button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.amt-form-control .open ul {
    /*border-top: 0;*/
    margin-top: 1px;
}

.amt-form-control span {
    line-height: 20px;
}

.amt-form-control .max-items-reached {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    border-radius: 5px;
    box-sizing: border-box;
}

.amt-form-control .disabled span, .amt-form-control .disabled input, .amt-form-control .disabled .closebutton {
    background-color: #eaeaea;
    cursor: not-allowed;
}

.v-middle {
    vertical-align: middle;
}

.amt-radio-description {
    left: 0px;
    margin-left: 22px;
    display: inline-block;
}
.amt-radio-label {
    position: relative;

}
