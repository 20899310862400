/*
 * This is not a standard kendo file, it appears to be overrides written by iSolutions to make kendo/bootstrap match
 * the AMT theme/style. As such, I'm putting this in the "safe" to modify bucket and changing things where needed.
 */
.k-in, .k-item, .k-window-action {
    border-color: transparent;
}

.k-block, .k-widget, .k-input, .k-textbox, .k-group, .k-content, .k-header, .k-editable-area, .k-separator, .k-colorpicker .k-i-arrow-s, .k-textbox > input, .k-autocomplete, .k-dropdown-wrap, .k-toolbar, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-grid-header, .k-grid-header-wrap, .k-grid-header-locked, .k-grid-footer-locked, .k-grid-content-locked, .k-grid td, .k-grid td.k-state-selected, .k-grid-footer-wrap, .k-pager-wrap, .k-pager-wrap .k-link, .k-pager-refresh, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-panelbar > .k-item > .k-link, .k-panel > .k-item > .k-link, .k-panelbar .k-panel, .k-panelbar .k-content, .k-calendar th, .k-slider-track, .k-splitbar, .k-dropzone-active, .k-tiles, .k-toolbar, .k-tooltip, .k-button-group .k-tool, .k-upload-files {
    border-color: #ccc;
}

    .k-group, .k-toolbar, .k-grouping-header, .k-pager-wrap, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-widget .k-status, .k-calendar th, .k-dropzone-hovered, .k-widget.k-popup {
        background-color: #f5f5f5;
    }

.k-grouping-row td, td.k-group-cell, .k-resize-handle-inner {
    background-color: #f5f5f5;
}

.k-list-container {
    border-color: rgba(0,0,0,0.2);
    background-color: #fff;
}

.k-content, .k-editable-area, .k-panelbar > li.k-item, .k-panel > li.k-item, .k-tiles {
    background-color: #fff;
}

.k-alt, .k-separator {
    background-color: #f5f5f5;
}

.k-textbox, .k-autocomplete.k-header, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-numeric-wrap.k-state-active {
    border-color: #ccc;
    background-color: #ebebeb;
}

    .k-textbox > input, .k-autocomplete .k-input, .k-dropdown-wrap .k-input, .k-autocomplete.k-state-focused .k-input, .k-dropdown-wrap.k-state-focused .k-input, .k-picker-wrap.k-state-focused .k-input, .k-numeric-wrap.k-state-focused .k-input {
        border-color: #ccc;
    }

    input.k-textbox, textarea.k-textbox, input.k-textbox:hover, textarea.k-textbox:hover, .k-textbox > input {
        background: 0;
    }

        .k-input, input.k-textbox, textarea.k-textbox, input.k-textbox:hover, textarea.k-textbox:hover, .k-textbox > input, .k-multiselect-wrap {
            background-color: #fff;
            color: #333;
        }

            .k-input[readonly] {
                background-color: #fff;
                color: #333;
            }

.k-block, .k-widget, .k-popup, .k-content, .k-dropdown .k-input {
    color: #333;
}

.k-block {
    color: #333;
}

.k-link:link, .k-link:visited, .k-nav-current.k-state-hover .k-link {
    color: #231f20;
}

.k-tabstrip-items .k-link, .k-panelbar > li > .k-link {
    color: #333;
}

.k-header {
    color: #333;
}

.k-header, .k-grid-header, .k-toolbar, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap, .k-grouping-header, .k-pager-wrap, .k-textbox, .k-button, .k-progressbar, .k-draghandle, .k-autocomplete, .k-state-highlight, .k-tabstrip-items .k-item, .km-pane-wrapper > .km-pane > .km-view > .km-content {
    background-image: none;
    background-position: 50% 50%;
    background-color: #f5f5f5;
}

.k-widget.k-tooltip {
    background-image: none;
}

.k-block, .k-header, .k-grid-header, .k-toolbar, .k-grouping-header, .k-pager-wrap, .k-button, .k-draghandle, html .km-pane-wrapper .k-header {
    background-color: #f5f5f5;
}

.k-icon:hover, .k-state-hover .k-icon, .k-state-selected .k-icon, .k-state-focused .k-icon, .k-column-menu .k-state-hover .k-sprite, .k-column-menu .k-state-active .k-sprite {
    opacity: 1;
}

.k-icon, .k-state-disabled .k-icon, .k-column-menu .k-sprite {
    opacity: 1;
}

.k-mobile-list .k-check:checked, .k-mobile-list .k-edit-field [type=checkbox]:checked, .k-mobile-list .k-edit-field [type=radio]:checked {
    opacity: 1;
}

.k-tool {
    border-color: transparent;
}

.k-icon, .k-tool-icon, .k-grouping-dropclue, .k-drop-hint, .k-column-menu .k-sprite, .k-grid-mobile .k-resize-handle-inner:before, .k-grid-mobile .k-resize-handle-inner:after {
    border-color: transparent;
}

.k-mobile-list .k-check:checked, .k-mobile-list .k-edit-field [type=checkbox]:checked, .k-mobile-list .k-edit-field [type=radio]:checked {
    border-color: transparent;
}

.k-loading, .k-state-hover .k-loading {
    background-image: url("/Content/common/spinner.gif");
    background-position: 50% 50%;
}

.k-loading-color {
    background-color: #fff;
}

.k-button {
    color: #333;
    border-color: #ccc;
    background-color: #fff;
}

    .k-button:focus, .k-button.k-state-focused {
        border-color: #67afe9;
    }

    .k-button:hover {
        color: #333;
        border-color: #adadad;
        background-color: #ebebeb;
    }

    .k-button:active {
        color: #fff;
        background-color: #ebebeb;
        border-color: #231f20;
    }

    .k-button.k-state-disabled, .k-state-disabled .k-button, .k-state-disabled .k-button:hover, .k-button.k-state-disabled:hover, .k-state-disabled .k-button:active, .k-button.k-state-disabled:active {
        color: #999;
        border-color: #ccc;
        background-color: #fff;
        background-image: none;
    }

    .k-button:focus:not(.k-state-disabled):not([disabled]) {
        -webkit-box-shadow: 0 0 7px 0 #67afe9;
        box-shadow: 0 0 7px 0 #67afe9;
    }

    .k-button:focus:active:not(.k-state-disabled):not([disabled]) {
        -webkit-box-shadow: inset 0 0 5px 3px #d4d4d4;
        box-shadow: inset 0 0 5px 3px #d4d4d4;
    }

.k-draghandle {
    border-color: #fff;
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 1px #c4c4c4;
    box-shadow: 0 0 0 1px #c4c4c4;
}

    .k-draghandle:hover {
        border-color: #adadad;
        background-color: #ebebeb;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

.k-scheduler {
    color: #fff;
    background-color: #fff;
}

.k-scheduler-layout {
    color: #333;
}

.k-scheduler-datecolumn, .k-scheduler-groupcolumn {
    background-color: #fff;
    color: #333;
}

.k-scheduler-times tr, .k-scheduler-times th, .k-scheduler-table td, .k-scheduler-header th, .k-scheduler-header-wrap, .k-scheduler-times {
    border-color: #e7e7e7;
}

.k-nonwork-hour {
    background-color: #f5f5f5;
}

.k-scheduler-table .k-today, .k-today > .k-scheduler-datecolumn, .k-today > .k-scheduler-groupcolumn {
    background-color: #e9e9e9;
}

.k-scheduler-now-arrow {
    border-left-color: #eed3d7;
}

.k-scheduler-now-line {
    background-color: #eed3d7;
}

.k-event {
    border-color: #0187cb;
    background: #0187cb 0 -257px none repeat-x;
    color: #fff;
}

.k-event-inverse {
    color: #333;
}

.k-event.k-state-selected {
    background-position: 0 0;
}

.k-ie7 .k-event .k-resize-handle, .k-event .k-resize-handle:after {
    background-color: #fff;
}

.k-scheduler-marquee:before, .k-scheduler-marquee:after {
    border-color: #231f20;
}

.k-panelbar .k-content, .k-panelbar .k-panel, .k-panelbar .k-item {
    background-color: #fff;
    color: #333;
    border-color: #ccc;
}

.k-panelbar > li > .k-link {
    color: #333;
}

.k-panelbar > .k-item > .k-link {
    border-color: #ccc;
}

.k-panel > li.k-item {
    background-color: #fff;
}

.k-state-active, .k-state-active:hover, .k-active-filter, .k-tabstrip .k-state-active {
    background-color: #fff;
    border-color: #ccc;
    color: #333;
}

.k-menu .k-state-hover > .k-state-active {
    background-color: transparent;
}

.k-state-highlight {
    background: #fff;
    color: #333;
}

.k-state-focused, .k-grouping-row .k-state-focused {
    border-color: #67afe9;
}
.k-calendar{
    width: 300px;
}
.k-calendar .k-link {
    color: #333;
}

.k-calendar .k-footer {
    padding: 0;
}

    .k-calendar .k-footer .k-nav-today {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: .5em 0;
        color: #333;
        text-decoration: none;
        background-color: #f5f5f5;
    }

        .k-calendar .k-footer .k-nav-today:hover, .k-calendar .k-footer .k-nav-today.k-state-hover {
            background-color: #fff;
            text-decoration: underline;
        }

        .k-calendar .k-footer .k-nav-today:active {
            background-color: #fff;
        }

.k-calendar .k-link.k-nav-fast {
    color: #333;
}

.k-calendar .k-nav-fast.k-state-hover {
    text-decoration: none;
    background-color: #ebebeb;
    color: #333;
}

.k-calendar .k-link.k-state-hover, .k-window-titlebar .k-link {
    border-radius: 4px;
}

.k-calendar .k-footer .k-link {
    border-radius: 0;
}

.k-calendar th {
    background-color: #f5f5f5;
    width: 25px;
    justify-content: center;
    text-align: center;
}

.k-calendar-container.k-group {
    border-color: rgba(0,0,0,0.2);
}

.k-state-selected, .k-state-selected:link, .k-state-selected:visited, .k-list > .k-state-selected, .k-list > .k-state-highlight, .k-panel > .k-state-selected, .k-ghost-splitbar-vertical, .k-ghost-splitbar-horizontal, .k-draghandle.k-state-selected:hover, .k-scheduler .k-scheduler-toolbar .k-state-selected, .k-scheduler .k-today.k-state-selected, .k-marquee-color {
    color: #fff;
    background-color: #231f20;
    border-color: #231f20;
}

.k-marquee-text {
    color: #fff;
}

.k-state-focused, .k-list > .k-state-focused, .k-listview > .k-state-focused, .k-grid-header th.k-state-focused, td.k-state-focused, .k-button.k-state-focused {
    -webkit-box-shadow: inset 0 0 7px 0 #67afe9;
    box-shadow: inset 0 0 7px 0 #67afe9;
}

    .k-state-focused.k-state-selected, .k-list > .k-state-focused.k-state-selected, .k-listview > .k-state-focused.k-state-selected, td.k-state-focused.k-state-selected {
        -webkit-box-shadow: inset 0 0 10px 3px #3276b1;
        box-shadow: inset 0 0 10px 3px #3276b1;
    }

.k-ie8 .k-panelbar span.k-state-focused, .k-ie8 .k-menu li.k-state-focused, .k-ie8 .k-listview > .k-state-focused, .k-ie8 .k-grid-header th.k-state-focused, .k-ie8 td.k-state-focused, .k-ie8 .k-tool.k-state-hover, .k-ie8 .k-button:focus, .k-ie8 .k-button.k-state-focused, .k-ie7 .k-panelbar span.k-state-focused, .k-ie7 .k-menu li.k-state-focused, .k-ie7 .k-listview > .k-state-focused, .k-ie7 .k-grid-header th.k-state-focused, .k-ie7 td.k-state-focused, .k-ie7 .k-tool.k-state-hover, .k-ie7 .k-button:focus, .k-ie7 .k-button.k-state-focused {
    background-color: #ebebeb;
}

.k-list > .k-state-selected.k-state-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-state-selected > .k-link, .k-panelbar > li > .k-state-selected, .k-panelbar > li.k-state-default > .k-link.k-state-selected {
    color: #fff;
}

.k-state-selected, .k-button:active, .k-draghandle.k-state-selected:hover {
    background-image: none;
}

.k-button:active, .k-draghandle.k-state-selected:hover {
    background-position: 50% 50%;
}

.k-state-hover, .k-state-hover:hover, .k-splitbar-horizontal-hover:hover, .k-splitbar-vertical-hover:hover, .k-list > .k-state-hover, .k-scheduler .k-scheduler-toolbar ul li.k-state-hover, .k-pager-wrap .k-link:hover, .k-dropdown .k-state-focused, .k-imagebrowser-dropzone, .k-mobile-list .k-item > .k-link:active, .k-mobile-list .k-item > .k-label:active, .k-mobile-list .k-edit-label.k-check:active, .k-mobile-list .k-scheduler-timezones .k-edit-field:nth-child(2):active, .k-mobile-list .k-recur-view .k-check:active {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.k-ie7 .k-window-titlebar .k-state-hover, .k-ie8 .k-window-titlebar .k-state-hover {
    border-color: #adadad;
}

.k-state-hover > .k-select, .k-state-focused > .k-select {
    border-color: #adadad;
}

.k-button:hover, .k-button:focus, .k-button.k-state-focused, .k-textbox:hover, .k-state-hover, .k-state-hover:hover, .k-pager-wrap .k-link:hover, .k-other-month.k-state-hover .k-link, div.k-imagebrowser-dropzone em, .k-draghandle:hover {
    background-image: none;
}

.k-pager-wrap {
    background-color: #f5f5f5;
    color: #333;
}

.k-autocomplete.k-state-active, .k-picker-wrap.k-state-active, .k-numeric-wrap.k-state-active, .k-dropdown-wrap.k-state-active, .k-state-active, .k-state-active:hover, .k-state-active > .k-link, .k-button:active, .k-panelbar > .k-item > .k-state-focused {
    background-image: none;
}

.k-state-hover > .k-link, .k-other-month.k-state-hover .k-link, div.k-imagebrowser-dropzone em {
    color: #333;
}

.k-autocomplete.k-state-hover, .k-autocomplete.k-state-focused, .k-picker-wrap.k-state-hover, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-hover, .k-numeric-wrap.k-state-focused, .k-dropdown-wrap.k-state-hover, .k-dropdown-wrap.k-state-focused {
    background-color: #ebebeb;
    background-image: none;
    background-position: 50% 50%;
    border-color: #adadad;
}

.km-pane-wrapper .k-mobile-list input:not([type="checkbox"]):not([type="radio"]), .km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]), .km-pane-wrapper .k-mobile-list textarea, .k-dropdown .k-state-focused .k-input {
    color: #333;
}

.k-dropdown .k-state-hover .k-input {
    color: #333;
}

.k-state-error {
    border-color: #eed3d7;
    background-color: #f2dede;
    color: #b94a48;
}

.k-state-disabled {
    filter: alpha(opacity=70);
    opacity: .7;
}

.k-tile-empty.k-state-selected, .k-loading-mask.k-state-selected {
    border-width: 0;
    background-image: none;
    background-color: transparent;
}

.k-state-disabled, .k-state-disabled .k-link, .k-state-disabled .k-button, .k-other-month, .k-other-month .k-link, .k-dropzone em, .k-dropzone .k-upload-status, .k-tile-empty strong, .k-slider .k-draghandle {
    color: #999;
}

//.k-progressbar-indeterminate {
//    background: url('/Content/Bootstrap/indeterminate.gif');
//}

    .k-progressbar-indeterminate .k-progress-status-wrap, .k-progressbar-indeterminate .k-state-selected {
        display: none;
    }

.k-slider-track {
    background-color: #ccc;
}

.k-slider-selection {
    background-color: #231f20;
}

.k-slider-horizontal .k-tick {
    background-image: url('/Content/Bootstrap/slider-h.gif');
}

.k-slider-vertical .k-tick {
    background-image: url('/Content/Bootstrap/slider-v.gif');
}

.k-widget.k-tooltip {
    border-color: #000;
    background-color: #000;
    color: #fff;
}

.k-widget.k-tooltip-validation {
    border-color: #fbeed5;
    background-color: #fcf8e3;
    color: #c09853;
}

.input-prepend .k-tooltip-validation, .input-append .k-tooltip-validation {
    font-size: 12px;
    position: relative;
    top: 3px;
}

.k-callout-n {
    border-bottom-color: #000;
}

.k-callout-w {
    border-right-color: #000;
}

.k-callout-s {
    border-top-color: #000;
}

.k-callout-e {
    border-left-color: #000;
}

.k-tooltip-validation .k-callout-n {
    border-bottom-color: #fbeed5;
}

.k-tooltip-validation .k-callout-w {
    border-right-color: #fbeed5;
}

.k-tooltip-validation .k-callout-s {
    border-top-color: #fbeed5;
}

.k-tooltip-validation .k-callout-e {
    border-left-color: #fbeed5;
}

.k-splitbar {
    background-color: #f5f5f5;
}

.k-restricted-size-vertical, .k-restricted-size-horizontal {
    background-color: #b94a48;
}

.k-file {
    background-color: #fff;
    border-color: #e7e7e7;
}

.k-file-progress {
    color: #06c;
}

    .k-file-progress .k-progress {
        background-color: #d0f8ff;
    }

.k-file-success {
    color: #fff;
}

    .k-file-success .k-progress {
        background-color: #f0ad4e;
    }

.k-file-error {
    color: #c00;
}

    .k-file-error .k-progress {
        background-color: #ffded7;
    }

.k-tile {
    border-color: #fff;
}

.k-textbox:hover, .k-tiles li.k-state-hover {
    border-color: #adadad;
}

.k-tiles li.k-state-selected {
    border-color: #231f20;
}

.k-tile .k-folder, .k-tile .k-file {
    background-image: url('/Content/Bootstrap/imagebrowser.png');
}

.k-widget, .k-button {
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.2),0 1px 2px rgba(0,0,0,0.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2),0 1px 2px rgba(0,0,0,0.05);
}

.k-slider, .k-treeview, .k-upload {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-state-hover {
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.2);
    box-shadow: 0 1px 0 rgba(255,255,255,0.2);
}

.k-autocomplete.k-state-focused, .k-dropdown-wrap.k-state-focused, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-focused {
    -webkit-box-shadow: 0 0 7px 0 #67afe9;
    box-shadow: 0 0 7px 0 #67afe9;
}

.k-state-selected {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
    box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
}

.k-state-active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-grid td.k-state-selected.k-state-focused {
    background-color: #4e92cd;
}

.k-popup, .k-menu .k-group, .k-grid .k-filter-options, .k-time-popup, .k-datepicker-calendar, .k-autocomplete.k-state-border-down, .k-autocomplete.k-state-border-up, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-multiselect.k-state-focused, .k-imagebrowser .k-image, .k-tooltip {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
}

.k-window {
    border-color: rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 1px 7px 1px rgba(128,128,128,0.2);
    box-shadow: 1px 1px 7px 1px rgba(128,128,128,0.2);
    background-color: #fff;
}

    .k-window.k-state-focused {
        border-color: rgba(0,0,0,0.2);
        -webkit-box-shadow: 1px 1px 7px 1px rgba(0,0,0,0.2);
        box-shadow: 1px 1px 7px 1px rgba(0,0,0,0.2);
    }

    .k-window.k-window-maximized, .k-window-maximized .k-window-titlebar, .k-window-maximized .k-window-content {
        border-radius: 0;
    }

.k-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
}

.k-inset {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.2);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.2);
}

.k-editor-inline ::selection {
    background-color: #231f20;
    text-shadow: none;
    color: #fff;
}

.k-editor-inline ::-moz-selection {
    background-color: #231f20;
    text-shadow: none;
    color: #fff;
}

.k-notification.k-notification-info {
    background-color: #e5f5fa;
    color: #0487c4;
    border-color: #b7e1f0;
}

.k-notification.k-notification-success {
    background-color: #eaf7ec;
    color: #349946;
    border-color: #c3e8ca;
}

.k-notification.k-notification-warning {
    background-color: #fff4d9;
    color: #0487c4;
    border-color: #fff0c8;
}

.k-notification.k-notification-error {
    background-color: #ffe0d9;
    color: #ef470a;
    border-color: #ffb7a8;
}

.k-block, .k-button, .k-textbox, .k-drag-clue, .k-touch-scrollbar, .k-window, .k-window-titleless .k-window-content, .k-window-action, .k-inline-block, .k-grid .k-filter-options, .k-grouping-header .k-group-indicator, .k-autocomplete, .k-multiselect, .k-combobox, .k-dropdown, .k-dropdown-wrap, .k-datepicker, .k-timepicker, .k-colorpicker, .k-datetimepicker, .k-notification, .k-numerictextbox, .k-picker-wrap, .k-numeric-wrap, .k-colorpicker, .k-list-container, .k-calendar-container, .k-calendar td, .k-calendar .k-link, .k-treeview .k-in, .k-editor-inline, .k-tooltip, .k-tile, .k-slider-track, .k-slider-selection, .k-upload {
    border-radius: 4px;
}

.k-tool {
    text-align: center;
    vertical-align: middle;
}

    .k-tool.k-group-start {
        border-radius: 4px 0 0 4px;
    }

.k-rtl .k-tool.k-group-start {
    border-radius: 0 4px 4px 0;
}

.k-tool.k-group-end {
    border-radius: 0 4px 4px 0;
}

.k-rtl .k-tool.k-group-end {
    border-radius: 4px 0 0 4px;
}

.k-group-start.k-group-end.k-tool {
    border-radius: 4px;
}

.k-calendar-container.k-state-border-up, .k-list-container.k-state-border-up, .k-autocomplete.k-state-border-up, .k-multiselect.k-state-border-up, .k-dropdown-wrap.k-state-border-up, .k-picker-wrap.k-state-border-up, .k-numeric-wrap.k-state-border-up, .k-window-content, .k-filter-menu {
    border-radius: 0 0 4px 4px;
}

    .k-autocomplete.k-state-border-up .k-input, .k-dropdown-wrap.k-state-border-up .k-input, .k-picker-wrap.k-state-border-up .k-input, .k-picker-wrap.k-state-border-up .k-selected-color, .k-numeric-wrap.k-state-border-up .k-input {
        border-radius: 0 0 0 4px;
    }

    .k-multiselect.k-state-border-up .k-multiselect-wrap {
        border-radius: 0 0 4px 4px;
    }

.k-window-titlebar, .k-block > .k-header, .k-tabstrip-items .k-item, .k-panelbar .k-tabstrip-items .k-item, .k-tabstrip-items .k-link, .k-calendar-container.k-state-border-down, .k-list-container.k-state-border-down, .k-autocomplete.k-state-border-down, .k-multiselect.k-state-border-down, .k-dropdown-wrap.k-state-border-down, .k-picker-wrap.k-state-border-down, .k-numeric-wrap.k-state-border-down {
    border-radius: 4px 4px 0 0;
}

.k-dropdown-wrap .k-input, .k-picker-wrap .k-input, .k-numeric-wrap .k-input {
    border-radius: 3px 0 0 3px;
}

.k-rtl .k-dropdown-wrap .k-input, .k-rtl .k-picker-wrap .k-input, .k-rtl .k-numeric-wrap .k-input {
    border-radius: 0 3px 3px 0;
}

.k-numeric-wrap .k-link {
    border-radius: 0 3px 0 0;
}

    .k-numeric-wrap .k-link + .k-link {
        border-radius: 0 0 3px 0;
    }

.k-colorpicker .k-selected-color {
    border-radius: 3px 0 0 3px;
}

.k-rtl .k-colorpicker .k-selected-color {
    border-radius: 0 3px 3px 0;
}

.k-autocomplete.k-state-border-down .k-input {
    border-radius: 4px 4px 0 0;
}

.k-dropdown-wrap.k-state-border-down .k-input, .k-picker-wrap.k-state-border-down .k-input, .k-picker-wrap.k-state-border-down .k-selected-color, .k-numeric-wrap.k-state-border-down .k-input {
    border-radius: 4px 0 0 0;
}

.k-numeric-wrap .k-link.k-state-selected {
    background-color: #ebebeb;
}

.k-multiselect.k-state-border-down .k-multiselect-wrap {
    border-radius: 3px 3px 0 0;
}

.k-dropdown-wrap .k-select, .k-picker-wrap .k-select, .k-numeric-wrap .k-select, .k-datetimepicker .k-select + .k-select, .k-list-container.k-state-border-right {
    border-radius: 0 4px 4px 0;
}

.k-rtl .k-dropdown-wrap .k-select, .k-rtl .k-picker-wrap .k-select, .k-rtl .k-numeric-wrap .k-select, .k-rtl .k-datetimepicker .k-select + .k-select, .k-rtl .k-list-container.k-state-border-right {
    border-radius: 4px 0 0 4px;
}

.k-numeric-wrap.k-expand-padding .k-input {
    border-radius: 4px;
}

.k-textbox > input, .k-autocomplete .k-input, .k-multiselect-wrap {
    border-radius: 3px;
}

.k-list .k-state-hover, .k-list .k-state-focused, .k-list .k-state-highlight, .k-list .k-state-selected, .k-dropzone {
    border-radius: 3px;
}

.k-slider .k-button, .k-grid .k-slider .k-button {
    border-radius: 13px;
}

.k-draghandle {
    border-radius: 13px;
}

.k-scheduler-toolbar > ul li:first-child, .k-scheduler-toolbar > ul li:first-child .k-link {
    border-radius: 4px 0 0 4px;
}

.k-rtl .k-scheduler-toolbar > ul li:first-child, .k-rtl .k-scheduler-toolbar > ul li:first-child .k-link, .km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child, .km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child .k-link {
    border-radius: 0 4px 4px 0;
}

.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today, .k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today .k-link, .k-edit-field > .k-scheduler-navigation {
    border-radius: 4px;
}

.k-scheduler-toolbar .k-nav-next, .k-scheduler-toolbar ul + ul li:last-child, .k-scheduler-toolbar .k-nav-next .k-link, .k-scheduler-toolbar ul + ul li:last-child .k-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.k-rtl .k-scheduler-toolbar .k-nav-next, .k-rtl .k-scheduler-toolbar ul + ul li:last-child, .k-rtl .k-scheduler-toolbar .k-nav-next .k-link, .k-rtl .k-scheduler-toolbar ul + ul li:last-child .k-link {
    border-radius: 4px 0 0 4px;
}

.k-scheduler div.k-scheduler-footer ul li, .k-scheduler div.k-scheduler-footer .k-link {
    border-radius: 4px;
}

.k-more-events, .k-event, .k-event .k-link {
    border-radius: 3px;
}

.k-scheduler-mobile .k-event {
    border-radius: 2px;
}

.k-grid-mobile .k-column-active + th.k-header {
    border-left-color: #333;
}

html .km-pane-wrapper .km-widget, .k-ie .km-pane-wrapper .k-widget, .k-ie .km-pane-wrapper .k-group, .k-ie .km-pane-wrapper .k-content, .k-ie .km-pane-wrapper .k-header, .k-ie .km-pane-wrapper .k-popup-edit-form .k-edit-field .k-button, .km-pane-wrapper .k-mobile-list .k-item, .km-pane-wrapper .k-mobile-list .k-edit-label, .km-pane-wrapper .k-mobile-list .k-edit-field {
    color: #333;
}

@media screen and (-ms-high-contrast:active) and (-ms-high-contrast:none) {
    div.km-pane-wrapper a {
        color: #333;
    }

    .km-pane-wrapper .k-icon {
        background-image: url('/Content/Bootstrap/sprite_2x.png');
        background-size: 21.2em 21em;
    }
}

.km-pane-wrapper .k-mobile-list .k-item, .km-pane-wrapper .k-mobile-list .k-edit-field, .km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
    background-color: #fff;
    border-top: 1px solid #e7e7e7;
}

    .km-pane-wrapper .k-mobile-list .k-item.k-state-selected {
        background-color: #231f20;
        border-top-color: #231f20;
    }

    .km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
        border-top-color: transparent;
    }

    .km-pane-wrapper .k-mobile-list .k-item:last-child {
        -webkit-box-shadow: inset 0 -1px 0 #e7e7e7;
        box-shadow: inset 0 -1px 0 #e7e7e7;
    }

.km-pane-wrapper .k-mobile-list > ul > li > .k-link, .km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3), .km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
    color: #9b9b9b;
}

.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
    border-bottom: 1px solid #e7e7e7;
}

.km-pane-wrapper .k-mobile-list .k-edit-field {
    -webkit-box-shadow: 0 1px 1px #e7e7e7;
    box-shadow: 0 1px 1px #e7e7e7;
}

.km-actionsheet .k-grid-delete, .km-actionsheet .k-scheduler-delete, .km-pane-wrapper .k-scheduler-delete, .km-pane-wrapper .k-filter-menu .k-button[type=reset] {
    color: #fff;
    border-color: #eed3d7;
    background-color: red;
    background-image: -webkit-gradient(linear,50% 0,50% 100%,from(rgba(255,255,255,.3)),to(rgba(255,255,255,.15)));
    background-image: -webkit-linear-gradient(top,rgba(255,255,255,.3),rgba(255,255,255,.15));
    background-image: -moz-linear-gradient(top,rgba(255,255,255,.3),rgba(255,255,255,.15));
    background-image: -ms-linear-gradient(top,rgba(255,255,255,.3),rgba(255,255,255,.15));
    background-image: linear-gradient(to bottom,rgba(255,255,255,.3),rgba(255,255,255,.15));
}

    .km-actionsheet .k-grid-delete:active, .km-actionsheet .k-scheduler-delete:active, .km-pane-wrapper .k-scheduler-delete:active, .km-pane-wrapper .k-filter-menu .k-button[type=reset]:active {
        background-color: #900;
    }

.k-autocomplete.k-state-default, .k-picker-wrap.k-state-default, .k-numeric-wrap.k-state-default, .k-dropdown-wrap.k-state-default {
    background-image: none;
    background-position: 50% 50%;
    background-color: #fff;
    border-color: #ccc;
}

.k-autocomplete.k-state-hover, .k-picker-wrap.k-state-hover, .k-numeric-wrap.k-state-hover, .k-dropdown-wrap.k-state-hover {
    background-color: #ebebeb;
    background-image: none;
    background-position: 50% 50%;
    border-color: #adadad;
}

.k-multiselect.k-header {
    border-color: #ccc;
}

    .k-multiselect.k-header.k-state-hover {
        border-color: #adadad;
    }

    .k-autocomplete.k-state-focused, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-focused, .k-dropdown-wrap.k-state-focused, .k-multiselect.k-header.k-state-focused {
        background-color: #ebebeb;
        background-image: none;
        background-position: 50% 50%;
        border-color: #67afe9;
        -webkit-box-shadow: 0 0 7px 0 #67afe9;
        box-shadow: 0 0 7px 0 #67afe9;
    }

.k-list-container {
    color: #333;
}

.k-dropdown .k-input, .k-dropdown .k-state-focused .k-input, .k-menu .k-popup {
    color: #333;
}

.k-state-default > .k-select {
    border-color: #ccc;
    width: 32px;
    background-color: #F3F3F3;
}

.k-state-hover > .k-select {
    border-color: #adadad;
}

.k-state-focused > .k-select {
    border-color: #67afe9;
}

.k-tabstrip-items .k-state-default .k-link, .k-panelbar > li.k-state-default > .k-link {
    color: #231f20;
}

    .k-tabstrip-items .k-state-hover .k-link, .k-panelbar > li.k-state-hover > .k-link, .k-panelbar > li.k-state-default > .k-link.k-state-hover {
        color: #333;
    }

.k-panelbar .k-state-focused.k-state-hover {
    background: #ebebeb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-tabstrip-items .k-state-default, .k-ie7 .k-tabstrip-items .k-state-default .k-loading {
    border-color: transparent;
}

.k-tabstrip-items .k-state-hover, .k-ie7 .k-tabstrip-items .k-state-hover .k-loading {
    border-color: #adadad;
}

.k-tabstrip-items .k-state-active, .k-ie7 .k-tabstrip-items .k-state-active .k-loading {
    background-color: #fff;
    background-image: none;
    border-color: #ccc;
}

.k-tabstrip .k-content.k-state-active {
    background-color: #fff;
    color: #333;
}

/* Needed in CM for kendo updates */
.k-tabstrip > .k-content.k-state-active {
    display: block;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active {
    margin-bottom: -1px;
    border-bottom-width: 1px;
    border-bottom-color: transparent !important;
}
/* Needed in CM for kendo updates */

.k-menu.k-header, .k-menu .k-item {
    border-color: #ccc;
}

.k-column-menu, .k-column-menu .k-item {
    border-color: #ccc;
}

    .k-column-menu .k-separator {
        border-color: #ccc;
        background-color: transparent;
    }

.k-menu .k-group {
    border-color: rgba(0,0,0,0.2);
}

.k-grid-filter.k-state-active {
    background-color: #fff;
}

.k-grouping-row td, .k-group-footer td, .k-grid-footer td {
    color: #333;
    border-color: #ccc;
    font-weight: bold;
}

.k-grouping-header {
    color: #333;
}

.k-grid td.k-state-focused {
    -webkit-box-shadow: inset 0 0 0 1px, inset 0 0 7px 0 #67afe9;
    box-shadow: inset 0 0 0 1px, inset 0 0 7px 0 #67afe9;
}

.k-header, .k-grid-header-wrap, .k-grid .k-grouping-header, .k-grid-header, .k-pager-wrap, .k-pager-wrap .k-textbox, .k-pager-wrap .k-link, .k-grouping-header .k-group-indicator {
    border-color: #ccc;
}

.k-primary {
    color: #fff;
    border-color: #367fbd;
    background-image: none;
    background-position: 50% 50%;
    background-color: #231f20;
    -webkit-box-shadow: none;
    box-shadow: none;
}

    .k-primary:focus, .k-primary.k-state-focused {
        color: #fff;
        border-color: #eff8ff;
        background-image: none;
        -webkit-box-shadow: 0 0 8px 0 #cfe6f8;
        box-shadow: 0 0 8px 0 #cfe6f8;
    }

    .k-primary:hover {
        color: #fff;
        border-color: #295f8e;
        background-image: none;
        background-color: #3276b1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .k-primary:focus:active:not(.k-state-disabled):not([disabled]), .k-primary:focus:not(.k-state-disabled):not([disabled]) {
        -webkit-box-shadow: 0 0 8px 0 #cfe6f8;
        box-shadow: 0 0 8px 0 #cfe6f8;
    }

    .k-primary:active {
        color: #fff;
        border-color: #285e8e;
        background-image: none;
        background-color: #3276b1;
        -webkit-box-shadow: inset 0 0 8px 0 #66afe9;
        box-shadow: inset 0 0 8px 0 #66afe9;
    }

    .k-primary.k-state-disabled, .k-state-disabled .k-primary, .k-primary.k-state-disabled:hover, .k-state-disabled .k-primary:hover, .k-primary.k-state-disabled:hover, .k-state-disabled .k-primary:active, .k-primary.k-state-disabled:active {
        color: #fff;
        border-color: #7dabd4;
        background-color: #84b3dc;
        background-image: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

.k-pager-numbers .k-link, .k-treeview .k-in {
    border-color: transparent;
}

.k-treeview .k-icon, .k-scheduler-table .k-icon, .k-grid .k-hierarchy-cell .k-icon {
    background-color: transparent;
    border-radius: 4px;
}

.k-scheduler-table .k-state-hover .k-icon {
    background-color: transparent;
}

a:focus, button:focus {
    outline: 0;
}

.k-grid, .k-scheduler, .k-menu, .k-editor {
    border-radius: 4px;
}

.k-widget.k-treeview {
    color: #231f20;
}

.k-treeview .k-in.k-state-hover {
    color: #231f20;
}

.k-treeview .k-state-selected, .k-draghandle.k-state-selected {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-tabstrip-items .k-state-hover .k-link {
    color: #231f20;
}

.k-tabstrip-items .k-state-active .k-link {
    color: #333;
}

.k-tabstrip-items .k-state-active .k-link {
    color: #333;
}

.k-tabstrip-items .k-item {
    background: transparent;
}

    .k-tabstrip-items .k-item.k-state-active {
        background: #fff;
    }

    .k-tabstrip-items .k-item.k-state-hover {
        background: #ebebeb;
    }

.k-tabstrip-items .k-state-focused {
    border-color: #67afe9;
    -webkit-box-shadow: 0 0 7px 0 #67afe9;
    box-shadow: 0 0 7px 0 #67afe9;
}

.k-tabstrip-items .k-state-default.k-state-hover, .k-ie7 .k-tabstrip-items .k-state-default .k-loading {
    border-color: #ebebeb;
}

.k-multiselect .k-button {
    color: #fff;
    border-color: #357ebd;
    background-color: #231f20;
}

    .k-multiselect .k-button.k-state-hover {
        border-color: #295f8e;
        background-color: #3276b1;
    }

    .k-multiselect .k-button:active {
        color: #fff;
        -webkit-box-shadow: inset 0 0 10px 3px #3276b1;
        box-shadow: inset 0 0 10px 3px #3276b1;
    }

.k-list > .k-state-hover {
    border-color: #ebebeb;
}

.k-scheduler-toolbar > ul > li {
    background-color: #fff;
    border-color: #ccc;
}

.k-menu .k-group {
    background: #fff;
}

.k-menu .k-state-default .k-state-border-down {
    background-color: #e7e7e7;
    -webkit-box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
}

.k-slider-track {
    background-color: #fff;
    border-color: #ccc;
    border-radius: 0;
}

.k-slider-selection {
    background-color: #ebebeb;
    border-radius: 0;
}

.k-slider-horizontal .k-tick {
    background-image: url('/Content/Bootstrap/slider-h.gif');
}

.k-draghandle.k-state-selected, .k-draghandle.k-state-selected:link, .k-draghandle.k-state-selected:hover {
    background-color: #ccc;
    border-color: #ccc;
}

.k-draghandle.k-state-focused, .k-draghandle.k-state-focused:link, .k-slider-buttons .k-button:focus, .k-slider-buttons .k-button:active {
    -webkit-box-shadow: 0 0 7px 0 #67afe9;
    box-shadow: 0 0 7px 0 #67afe9;
    border-color: #67afe9;
    background-color: #fff;
}

.k-autocomplete.k-state-default, .k-picker-wrap.k-state-default, .k-numeric-wrap.k-state-default, .k-dropdown-wrap.k-state-default, .k-multiselect.k-header {
    border-color: #ccc;
    height: 32px;
}

/* to make the numeric spinner fit correctly in the existing 32px above */
    .k-numeric-wrap {
        height: 35px;   /* for disabled state */
    }
    .k-numeric-wrap .k-select {
        min-height: 31px;
    }

    .k-numerictextbox .k-i-arrow-60-up {
        height: 14px;
    }

    .k-numerictextbox .k-i-arrow-60-down {
        height: 11px;
    }
/*  */

.k-widget.k-autocomplete, .k-widget.k-combobox, .k-widget.k-dropdown, .k-widget.k-datepicker, .k-widget.k-datetimepicker, .k-widget.k-timepicker, .k-widget.k-numerictextbox, .k-widget.k-multiselect, .k-widget.k-menu, .k-widget.k-progressbar, .k-progressbar > .k-state-selected {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-autocomplete.k-state-default .k-input, .k-combobox .k-state-default .k-input, .k-picker-wrap.k-state-default .k-input, .k-numeric-wrap.k-state-default .k-input, .k-multiselect-wrap, .k-slider-track, .k-slider-selection, .k-progress-status-wrap {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    height: 27px;
}

.k-textbox:focus, .k-autocomplete.k-state-focused, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-focused, .k-dropdown-wrap.k-state-focused, .k-multiselect.k-header.k-state-focused {
    background-color: #fff;
    background-image: none;
    background-position: 50% 50%;
    border-color: #67afe9;
    -webkit-box-shadow: 0 0 7px 0 #67afe9;
    box-shadow: 0 0 7px 0 #67afe9;
}

.k-multiselect .k-textbox:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-textbox:hover, .k-autocomplete.k-state-hover, .k-picker-wrap.k-state-hover, .k-numeric-wrap.k-state-hover, .k-dropdown-wrap.k-state-hover {
    background-color: #ebebeb;
    background-image: none;
    background-position: 50% 50%;
    border-color: #adadad;
}

.k-autocomplete.k-state-border-down, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-numeric-wrap.k-state-active {
    border-color: #ccc;
    background-color: #ebebeb;
}

.k-grid-filter.k-state-active {
    border-color: #adadad;
    background-color: #ebebeb;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
    box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
}

.k-picker-wrap.k-state-active.k-state-border-down, .k-dropdown-wrap.k-state-active.k-state-border-down, .k-numeric-wrap .k-link.k-state-selected {
    -webkit-box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
}

.k-picker-wrap.k-state-focused > .k-select, .k-picker-wrap.k-state-active > .k-select .k-dropdown-wrap.k-state-focused > .k-select, .k-dropdown-wrap.k-state-active > .k-select {
    border-color: #ccc;
}

.k-panelbar > .k-item > .k-link {
    border-color: #ccc;
}

.k-panelbar > li.k-state-default > .k-link {
    color: #333;
}

.k-panelbar .k-state-focused {
    -webkit-box-shadow: inset 0 0 7px 0 #67afe9;
    box-shadow: inset 0 0 7px 0 #67afe9;
}

.k-panelbar > li > .k-link.k-state-selected {
    color: #fff;
}

.k-panelbar .k-link.k-state-selected.k-state-hover {
    color: #333;
}

.k-panelbar .k-state-focused.k-state-selected, td.k-state-focused.k-state-selected, .k-state-selected td.k-state-focused, .k-listview > .k-state-focused.k-state-selected {
    -webkit-box-shadow: inset 0 0 10px 3px #3276b1;
    box-shadow: inset 0 0 10px 3px #3276b1;
}

.k-panelbar .k-state-focused.k-state-hover {
    background: #ebebeb;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-pager-wrap {
    color: #a5a5a5;
}

    .k-pager-wrap > .k-link, .k-pager-numbers .k-link, .k-pager-numbers .k-state-selected {
        border-radius: 0;
    }

.k-grid tr td {
    border-style: solid;
    border-color: #ccc;
}

.k-pager-wrap .k-link {
    background: #fff;
    border-color: #ccc;
    border-right: 0;
}

.k-pager-wrap .k-pager-refresh {
    background: #f5f5f5;
}

.k-pager-wrap .k-link:hover {
    color: #231f20;
    border-color: #ccc;
    background: #ebebeb;
}

.k-grid-header .k-link:link, .k-grid-header .k-link:visited, .k-grid-header .k-nav-current.k-state-hover .k-link, .k-grouping-header .k-link {
    color: #333;
}

.k-pager-wrap .k-pager-nav.k-pager-last {
    border-radius: 0 4px 4px 0;
    border-right-color: #ccc;
}

.k-pager-nav.k-pager-first {
    border-radius: 4px 0 0 4px;
}

.k-dropzone .k-upload-status {
    color: #f0ad4e;
}

.k-menu .k-group, .k-grid .k-filter-options, .k-time-popup, .k-datepicker-calendar, .k-autocomplete.k-state-border-down, .k-autocomplete.k-state-border-up, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-multiselect.k-state-focused, .k-imagebrowser .k-image, .k-tooltip {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-window {
    border-color: #ccc;
}

.k-state-focused, .k-button:focus, .k-button.k-state-focused, .k-button:focus:active {
    -webkit-box-shadow: 0 0 7px 0 #67afe9;
    box-shadow: 0 0 7px 0 #67afe9;
}

.k-list > .k-state-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-button {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-popup {
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.k-list-container {
    border-color: #ccc;
}

.k-flatcolorpicker .k-hue-slider .k-draghandle {
    -webkit-box-shadow: 0 0 0 1px #c4c4c4;
    box-shadow: 0 0 0 1px #c4c4c4;
    background: transparent;
    border: 5px solid #fff;
}

    .k-flatcolorpicker .k-hue-slider .k-draghandle:hover, .k-flatcolorpicker .k-hue-slider .k-draghandle:focus {
        border-color: #fff;
        -webkit-box-shadow: 0 0 8px 0 rgba(102,175,233,0.75);
        box-shadow: 0 0 8px 0 rgba(102,175,233,0.75);
        border-width: 5px;
        padding: 0;
    }

.k-pager-numbers .k-link, .k-pager-numbers .k-state-selected {
    margin-right: 0;
}

.k-grid .k-pager-numbers, .k-pager-wrap > .k-link {
    margin: 0;
}

.k-pager-wrap .k-link {
    border-right: 0;
}

    .k-pager-wrap .k-link.k-pager-last {
        border-right-width: 1px;
        border-right-style: solid;
    }

.k-state-disabled .k-button, .k-state-disabled .k-button:hover, .k-button.k-state-disabled:hover, .k-state-disabled .k-button:active, .k-button.k-state-disabled:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.k-primary:active {
    color: #fff;
    border-color: #285e8e;
    background-image: none;
    background-color: #3276b1;
    -webkit-box-shadow: inset 0 0 8px 0 #66afe9;
    box-shadow: inset 0 0 8px 0 #66afe9;
}

.k-editor-toolbar .k-tool {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

    .k-editor-toolbar .k-tool.k-state-hover {
        color: #333;
        border-color: #adadad;
        background-color: #ebebeb;
    }

.k-editor-toolbar .k-state-selected {
    -webkit-box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 8px rgba(0,0,0,0.125);
    background-color: #ebebeb;
    border-color: #adadad;
}

.k-editor-toolbar .k-button-group .k-tool-icon {
    border-color: #ccc;
}

.k-splitbar, .k-splitbar:hover {
    border-color: transparent;
}

.k-button:active {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

/* The no records was pushing out of the grid to create scroll bars */
.k-grid-norecords {
    width: 99%;
    height: 99%;
    text-align: center;
}
