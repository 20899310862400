@import "../base/variables";

.chart-tooltip-background {
    fill: $black;
    fill-opacity: 0.65;
    stroke: $grey2;
    stroke-width: 1;
    rx: 3px; /* csslint allow: known-properties */
    ry: 3px; /* csslint allow: known-properties */
}

.chart-area {
    stroke-width: 2px;
    stroke: $grey2;
    fill: none;
    
}

.chart-tooltip-property {
    stroke: none;
    fill: $white;
    font-family: 'Courier New';
    text-align: right;
    font-size: 11px;
    font-weight: bold;
    text-anchor: end;
}

.chart-tooltip-value {
    stroke: none;
    fill: $white;
    font-family: 'Courier New';
    text-align: left;
    font-size: 11px;
    font-weight: normal;
}

.chart-tooltip-string {
    stroke: none;
     fill: $white;
    font-family: 'Courier New';
    font-size: 11px;
    font-weight: normal;
    text-anchor: middle;
}

.chartBackground > rect {
    stroke-width: 1;
    stroke: $grey2;
    fill: $white;
}

.chartScale {
    font-weight: normal;
    font-size: small;
    text-anchor: middle;
}

.chartScale.day > text {
    font-size: smaller;
}

.chartScale > rect {
    stroke: $grey2;
    stroke-width: 1;
    fill: $white;
}

.chartScale.month.background > rect {
    stroke: #eee;
}

.chartScale.alternate > rect {
    stroke: $grey2;
    stroke-width: 1;
    fill: #eee;
}

.chartLegend {
    font-size: smaller;
    stroke: none;
}

.chartLegend > g > .boundingBox {
    stroke: $black;
    stroke-width: 1;
    fill: $white;
}

.chartLegend > g > .samplePatch {
    stroke: $black;
    stroke-width: 1;
    stroke-opacity: 0.5;
    fill-opacity: 0.5;
}

.chartLegend > .readingType_HOURS > .samplePatch {
    stroke: $reading-hours-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_DISTANCE > .samplePatch {
    stroke: $reading-distance-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_Speed > .samplePatch {
    stroke: $reading-speed-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_TREADDEPTH > .samplePatch {
    stroke: $reading-treaddepth-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_CHAINDEPTH > .samplePatch {
    stroke: $reading-treaddepth-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_PRESSURE > .samplePatch {
    stroke: $reading-pressure-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_TEMPERATURE > .samplePatch {
    stroke: $reading-temp-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_TREADTEMP > .samplePatch {
    stroke: $reading-temp-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_NDT > .samplePatch {
    stroke: $reading-ndt-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .readingType_TORQUE > .samplePatch {
    stroke: $reading-torque-color;
    stroke-width: $reading-stroke-width;
}

.chartLegend > .statusType_FITTED {
    fill: $status-fitted-color;
}

.chartLegend > .statusType_PRODUCTION {
    fill: $status-production-color;
}

.chartLegend > .statusType_MAINTENANCE {
    fill: $status-maintenance-color;
}

.chartLegend > .statusType_NEW {
    fill: $status-new-color;
}

.chartLegend > .statusType_SPARE {
    fill: $status-spare-color;
}

.chartLegend > .statusType_INSPECTION {
    fill: $status-inspection-color;
}

.chartLegend > .statusType_SCRAPPED {
    fill: $status-scrapped-color;
}

.chartLegend > .statusType_DISPOSED {
    fill: $status-disposed-color;
}

.chartRange {
    opacity: 0.5;
    stroke: $blue1;
    stroke-width: 1;
    fill: $blue3;
}

.chartPoint.adjusted > circle {
    opacity: 0.5;
    stroke-width: 1;
    stroke: $black;
    fill: $blue1;
}

.chartPoint.projected > circle {
    opacity: 0.5;
    stroke-width: 1;
    stroke: $black;
    fill: $white;
}

.chartPoint.new-meter-true > circle {
    stroke-width: 2;
    opacity: 1;
}

.chartPoint > circle {
    opacity: 0.5;
    stroke-width: 1;
    stroke: $black;
    fill: $white;
}

.chartPoint:hover > circle {
    opacity: 0.8;
    stroke-width: 1;
}


.chartPositionLabel > text {
    stroke: none;
    fill: $black;
    text-anchor: middle;
    font-size: small;
}

.chartPositionLabel > rect {
    stroke: $black;
    stroke-width: 1;
    fill: $white;
}

.chartPositionFitment > text {
    fill: $blue1;
    text-anchor: middle;
    font-size: small;
    cursor: pointer;
}

.chartPositionFitment > rect {
    fill: $green1;
    fill-opacity: 0.5;
    rx: 4px; /* csslint allow: known-properties */
    ry: 4px; /* csslint allow: known-properties */
    stroke: $black;
    stroke-width: 1;
}

.sbBackground {
    stroke: $grey;
    stroke-width: 1;
    fill: $grey;
    fill-opacity: 0.2;
}

.sbNav {
    stroke: $grey;
    stroke-width: 1;
    fill: $black;
    fill-opacity: 0.2;
}

.sbNavBackground {
    stroke: $grey;
    rx: 3px; /* csslint allow: known-properties */
    ry: 3px; /* csslint allow: known-properties */
    stroke-width: 1;
    fill: $black;
    fill-opacity: 0.2;
}

.sbRange {
    stroke: $grey;
    rx: 6px; /* csslint allow: known-properties */
    ry: 6px; /* csslint allow: known-properties */
    stroke-width: 1;
    fill: $white;
    fill-opacity: 0.50;
}

.sbAxleSilhouette {
    stroke: #ddd;
    stroke-width: 1;
    fill: $grey2;
    rx: 1px; /* csslint allow: known-properties */
    ry: 1px; /* csslint allow: known-properties */
}

oc-chart {
    .status > rect {
        stroke: $grey2;
        stroke-width: 1;
        fill-opacity: 0.5;
        rx: 5px; /* csslint allow: known-properties */
        ry: 5px; /* csslint allow: known-properties */
    }

    .status > text {
        text-anchor: middle;
        text-overflow: ellipsis;
        font-size: small;
        stroke: none;
    }

    .status.statusType_FITTED {
        stroke: $status-fitted-color;
        fill: $status-fitted-color;
    }

    .status.statusType_PRODUCTION {
        stroke: $status-production-color;
        fill: $status-production-color;
    }

    .status.statusType_MAINTENANCE {
        stroke: $status-maintenance-color;
        fill: $status-maintenance-color;
    }

    .status.statusType_NEW {
        stroke: $status-new-color;
        fill: $status-new-color;
    }

    .status.statusType_SPARE {
        stroke: $status-spare-color;
        fill: $status-spare-color;
    }

    .status.statusType_INSPECTION {
        fill: $status-inspection-color;
    }

    .status.statusType_SCRAPPED {
        fill: $status-scrapped-color;
    }

    .status.statusType_DISPOSED {
        fill: $status-disposed-color;
    }

    .event {
        stroke: $black;
        stroke-width: 1;
        fill: $black;
        fill-opacity: 0.5;
    }

    .event.MovedToMaintenance {
        stroke: $orange;
        fill: $orange;
    }

    .event.MovedToProduction {
        stroke: $schematic-green;
        fill: $schematic-green;
    }

    .event.FIELDSURVEY {
        stroke: $schematic-blue;
        fill: $schematic-blue;
    }

    .event.MovedToScrapped {
        stroke: $schematic-red;
        fill: $schematic-red;
    }

    .reading {
        stroke-width: $reading-stroke-width;
        fill: none;
        opacity: 0.3;
    }

    .reading.readingType_HOURS {
        stroke: $reading-hours-color;
    }

    .reading.readingType_Speed {
        stroke: brown;
        stroke-dasharray: 5 5;
        stroke-width: 1;
    }

    .reading.readingType_DISTANCE {
        stroke: $reading-distance-color;
    }

    .reading.readingType_TREADDEPTH {
        stroke: $reading-treaddepth-color;
    }

    .reading.readingType_CHAINDEPTH {
        stroke: $reading-treaddepth-color;
    }

    .reading.readingType_PRESSURE {
        stroke: $reading-pressure-color;
    }

    .reading.readingType_TEMPERATURE {
        stroke: $reading-temp-color;
    }

    .reading.readingType_TREADTEMP {
        stroke: $reading-temp-color;
    }

    .reading.readingType_TORQUE {
        stroke: $reading-torque-color;
    }

    .reading.readingType_NDT {
        stroke: $reading-ndt-color;
    }
}
//end of oc-chart