@import "../../../styles/base/variables";

site-dashboard-configuration-details {

    .dashboard-widget-selection {        
        width: 100%;
        text-align: center;

        button {
            margin: 10px;
            height: 8em;
            width: 8em;
            vertical-align: middle;
            background-color: #D8D8D8; // the same grey currently used in grid headers
            padding: 5px;
            font-size: 16px;
        }

        button:hover {
            background-color: $black;
            color: $white;
        }
    }

    amt-number-input input[type='number'] {
        width: 50%;
    }

    input[type='text'] {
        width: 100%;
    }
}
