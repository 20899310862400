@import "../base/variables";

change-status-popup .attachment-grid-header {
    margin-right: 19px !important;
}

change-status-popup .row:not(.attachment-grid-header) {
    margin: 0px !important;
}

html.mobile .status-change amt-display label {
    font-size: $medium-font-size;
}

html.mobile .status-change .form-control-static {
    font-size: $medium-font-size !important;
}

html.mobile .status-change .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

html.mobile .status-change textarea {
    font-size: $medium-font-size;
}

html.mobile .status-change oc-comment > div {
    font-size: $medium-font-size;
}

html.mobile .status-change-display {
    font-size: $medium-font-size;
    font-weight: bold;
}

html.mobile .status-change {
    .action {
        font-size: 1.25rem;
    }

    .combobox .message {
        font-size: $medium-font-size;
    }

    amt-combobox > .amt-form-control .item-display {
        font-size: $medium-font-size;
        background-color: transparent;
        line-height: unset !important;
        height: 100%;
        display: inline-flex;
        align-items: center;
    }

    amt-combobox > .amt-form-control .closebutton {
        font-size: 2rem;
        line-height: $medium-control-height !important;
    }

    amt-combobox > .amt-form-control .caret {
        margin-top: 14px;
    }

    amt-combobox .option {
        font-size: $medium-font-size;
        line-height: $medium-control-height !important;
    }

    .amt-form-control .amt-form-control-button {
        height: $medium-control-height;
    }

    input[type=text], input[type=number] {
        height: $medium-control-height;
        font-size: $medium-font-size !important;
    }

    input[type=checkbox] {
        min-width: 30px;
        max-width: 30px;
    }

    .header {
        max-height: $status-change-header-height;
        padding-bottom: 10px;
    }

    .footer {
        height: $status-change-details-footer-height;
    }

    .component-details {
        height: calc(100vh - #{$status-change-header-height} - #{$status-change-details-footer-height} - 20px);
    }
}
