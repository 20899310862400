@import "../base/variables";

/*CHILD WINDOW LIST*/

oc-window-list {
    padding-bottom: 64px; // add some padding to reserve space for the Zendesk Web Widget
}
.window-list-container {
    font-size: small;
    max-height: 500px;
    border-top: 1px solid #ccc;
    padding: 10px 10px 0px 0px;
}


.window-list-button {
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 9px 5px 0 0 !important;
    background-color: transparent !important;
}

.window-list-col:hover > .window-list-button, .window-list-col:hover > .window-list-title {
    background-color: $black !important;
    color: $white !important;
}

.window-list-row {
    background-color: $off-white;
    padding: 3px;
    vertical-align: middle;
}

.window-list-title {
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 235px;
    padding-right: 32px !important;
    box-sizing: border-box;
}

.window-list-title-dirty {
    background-color: $dirtyYellow !important;
}

.window-list-windows {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.window-list-header-row {
    font-weight: bold;
    border-bottom: 1px solid $black4;
    padding: 3px;
    background-color: $off-white;
}

/*CHILD WINDOW*/
.child-window-content > div {
    overflow-x: auto;
    overflow-y: auto;
}

.child-window-buttons {
    position: absolute;
    top: 5px;
    right: 2px;
    margin: 0;
}

.child-windows-container {
    position: absolute;
    top: 100px;
    left: 0;
    width: 0;
    height: 0;
    border: 1px solid $grey2;
}

.full-screen glyphicon-fullscreen {
    display: none;
}

.full-only {
    display: none;
}

.full-screen .full-only {
    display: inline;
}

.full-screen .child-window-container {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 3px;
    bottom: 3px;
    top: 54px; /* with room for thead butterbar */
    left: 250px;
    background-color: #fcfcfc;
    border-radius: 0px;
    box-shadow: 0px;
}

.child-window-container {
    position: absolute;
    width: 100%;
    z-index: 150;
    border: 1px solid $grey2;
    background-color: #fcfcfc;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,.2) 0 5px 10px 0;
}

.child-window-title-container {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: #FCFCFC;
    border-bottom: 1px solid #EEEEEE;
}

.child-break {
    clear: both;
    display: block;
    width: 100%;
}

.child-window-title {
    position: relative;
    top: 10px;
    right: 120px;
    left: 6px;
    padding-left: 5px;
    height: 35px;
    color: $black;
    width: 100%;
    font-size: large;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.child-window-right-title {
    position: absolute;
    top: 10px;
    right: 85px;
    padding-left: 5px;
    height: 35px;
    color: $black;
    font-size: large;
    white-space: nowrap;
}

.child-window-title > span {
    overflow: hidden;
}

.child-window-footer-container {
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #FCFCFC;
    border-top: 2px solid #EEEEEE;
}

.fixed-height .child-window-footer-container {
    position: absolute;
    bottom: 0;
}

.full-screen .child-window-footer-container {
    position: absolute;
    bottom: 0;
}

.child-window-footer {
    width: 100%;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: medium; /* Default */
    padding-top: 10px;
}

.child-window-footer .pull-right {
    margin-right: 15px; /* Something (the shitty CSS most likely) makes it go too far right, this pulls it back properly. */
}

.child-window-footer .pull-right.btn-primary {
    min-width: 0; /* amt-theme-bootstrap sets it to 110px but this looks bad in the footer / when other buttons are dynamically sized. */
}

.rounded-left-top {
    border-top-left-radius: 6px;
}

.rounded-right-top {
    border-top-right-radius: 6px;
}

.rounded-borders {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.child-window-body {
    position: relative;
    background-color: $white;
    border: 1px solid #eee;
    min-height: 75px;
    width: 100%;
    border-radius: 6px;
    padding-top: 10px;
}

.child-window-body {
    max-height: calc(100vh - /* remove.js? */ 20px);
}

.child-window-body.has-header {
    max-height: calc(100vh - /* remove.js? */ 10px - /* header */ 40px);
}

.child-window-body.has-footer {
    max-height: calc(100vh - /* remove.js? */ 10px - /* footer */ 55px);
}

.child-window-body.has-header.has-footer {
    max-height: calc(100vh - /* remove.js? */ 10px - /* header */ 40px - /* footer */ 55px);
}

html.mobile .child-window-body.has-footer {
    max-height: calc(100vh - /* remove.js? */ 10px - /* footer */ 90px);
}

html.mobile .child-window-body.has-header.has-footer {
    max-height: calc(100vh - /* remove.js? */ 10px - /* header */ 40px - /* footer */ 90px);
}

.fixed-height .child-window-body {
    position: absolute;
    overflow: auto;
    top: 40px;
    bottom: 55px;
}

.fixed-height.footer-off .child-window-body {
    bottom: 0;
}

.fixed-height.header-off .child-window-body {
    top: 0;
}

.child-window-close-button {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 32px;
    height: 32px;
}

.child-window-fs-button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 76px;
    top: 4px;
}

.child-window-minimise-button {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 40px;
    top: 4px;
}

.child-window-button {
    color: $black;
    background-color: $white;
    width: 25px;
}

html.mobile {
    .child-window-close-button {
        height: 40px;
        width: 40px;
        top: 5px;
        right: 5px;
    }

    .child-window-minimise-button {
        height: 40px;
        width: 40px;
        right: 50px;
    }

    .child-window-body {
        padding-top: 10px;
    }

    .child-window-footer-container {
        height: 70px !important;
    }

    .child-window-title-container {
        height: 50px !important;
    }

    .child-window-title {
        top: 12px;
    }

    .fixed-height .child-window-body {
        top: $large-control-height;
        bottom: 43px;
        padding-top: 0px;
    }

    alert-window .center-box > div {
        font-size: $large-font-size;
    }

    .alert {
        font-size: $large-font-size;
    }

    alert-window .left-box {
        font-size: $large-font-size;
    }

}


field-surveys,
component-specification-details,
vehicle-specification-details,
user-details,
notifications-list,
error-panel,
field-survey-delete-all,
maintenance,
component-details,
component-receive,
vehicle-receive,
vehicle-details,
end-of-month-details,
purchase-order-details {
    display: flex;
    flex: 1 0 0px;
    height: 100%;
}

.child-window-body.field-surveys,
.child-window-body.component-specification-details,
.child-window-body.vehicle-specification-details,
.child-window-body.user-details,
.child-window-body.notifications-list,
.child-window-body.maintenance,
.child-window-body.component-details
.child-window-body.component-receive,
.child-window-body.vehicle-receive-popup
.child-window-body.vehicle-details,
.child-window-body.end-of-month-details,
.child-window-body.purchase-order-details {
    display: flex;
    flex-direction: column;
}