//stocktake actions menu (perhaps these selectors should be more specific to avoid name collision?)
.action-menu-sub-menu {
    list-style: none;
    float: right;
    position: absolute;
    background-color: white;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    background-color: #fff;    
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    left: auto;
}

.action-menu-sub-menu > li {
    white-space: nowrap;
    margin-right: 10px;
}

.action-menu-sub-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    padding: 5px 15px;
    padding-right: 20px;
}

.action-menu-sub-menu > li > a:hover {
    background-color: #F3F3F3 !important;
    text-decoration: none;
}

//for Maintenance Sessions (mobile) Event's tab's Actions menu...
.maintenance-events-actions-menu-win-top.modal > .modal-dialog {
    top: 53vh;
    bottom: unset;
}

.maintenance-events-actions-menu {
    font-size: 1.375rem;

    .info {
        min-width: 60%;
        float: left;
        text-align: center;
    }

    .position-info {
        min-width: 20%;
        text-align: left;
        float: left;
    }

    ul {
        text-align: center;
        list-style: none;
        padding: .375em 0;
    }
}