
.component-specification {

    .current-price-display {
        margin-bottom: 20px;

        span {
            margin-right: 10px;
            font-weight: bold;
        }
    }

    .component-specification-loader {
        height: 100px;
    }

    .component-specification-hierarchy {
        flex: none;
        margin-bottom: 10px;
    }

    .component-specification-client {
        width: 70%;
    }

    .component-specification-site {
        width: 70%;
    }
}
