@import "variables";


.extra-small {
    font-size: 75%;
}

.text-black {
    color: $black;
}

.text-white {
    color: $white;
}

.grey {
    color: $black2;
}


.unit {
    color: $black2;
}

.font-normal {
    font-weight: normal;
}

.font-thin {
    font-weight: 300;
}

.font-bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.text-u-c {
    text-transform: uppercase;
}

.text-l-t {
    text-decoration: line-through;
}

.text-u-l {
    text-decoration: underline !important;
}

.text-active,
.active > .text,
.active > .auto .text {
    display: none !important;
}

.active > .text-active,
.active > .auto .text-active {
    display: inline-block !important;
}

.number_overwritten {
    color: $red1;
}

.charsremaining {
    color: $black3;
}

.glyphicon-black {
    color: $black;
}

.glyphicon-blue {
    color: $blue1;
}

.glyphicon-grey {
    color: $black4;
}

.glyphicon-red {
    color: $red1;
}

.glyphicon-green {
    color: $primary-color1;
}

.glyphicon.glyphicon-info-sign.amt-tooltip {
    margin-left: 5px;
}

.support-highlight {
    color: $blue;
    font-size: 125%;
    font-weight: bold;
    line-height: 175%;
}

.support-text {
    white-space: pre-wrap;
    padding: 0 12px;
}

.closeOnSaveCheckbox {
    font-weight: normal;
    font-size: small;
    padding-left: 15px;
}

.text-shadow {
    font-size: 170px;
    text-shadow: 0 1px 0 #E0E0E0, 0 2px 0 #fcfdfd, 0 5px 10px rgba(0,0,0,0.125), 0 10px 20px rgba(0,0,0,0.2);
}

.courier-new {
    font-family: 'Courier New';
}

.capitalize {
    text-transform: capitalize;
}

.center {
   text-align: center;
}

.font-x-small {
    font-size: x-small;
}

.font-small {
    font-size: small;
}

.font-medium {
    font-size: medium;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.white-space-normal {
    white-space: normal;
}

.overflow-wrap {
    overflow-wrap: break-word;
    white-space: normal;
}

.text-middle {
    text-align: center;
    vertical-align: middle;
}

.header {
	display: block;
	font-weight: bold;
	text-align: center;
}

.column-header {
	display: block;
	font-weight: bold;
	font-size: medium;
	text-align: center;
}

.text-center {
	display: block;
	text-align: center;
}

.text-right {
    display: block;
    text-align: right;
}

.sub-text {
	font-size: 0.83em;
}

.error {
    color: $red1;
    font-weight:bold;
}

.form-section-heading {
    font-weight:bold;
    font-size:16px;
    margin: 10px 0px 8px 0px;
}
.form-section-text {
    margin: 0px 0px 15px 0px;
}