@import '../../../styles/base/variables';

/*.k-grid .k-pager-wrap .k-icon {
    margin-top: 6px;
}*/

.k-grid .k-pager-wrap .k-pager-refresh.k-link:hover {
    background: transparent;
}

.k-grid .k-state-selected, .k-grid .k-state-selected:link, .k-grid .k-state-selected:visited {
    background-color: $grid-selected-row-bg;
    border-color: $grid-border;
}


.k-grid .k-state-selected .btn-link {
    color: #fff;
}

.k-header .k-dropdown .k-select {
    padding-top: 0px;
}

.k-grid .btn-link {
    color: #333333;
}

.k-grid .k-primary {
    background-color: #231f20;
}

.k-grid .k-command-col {
    text-align: center;
}

.k-grid .k-command-col .k-grid-edit,
.k-grid .k-command-col .k-grid-update,
.k-grid .k-command-col .k-grid-remove,
.k-grid .k-command-col .k-grid-cancel {
    position: relative;
}

.k-grid .k-command-col .k-grid-update {
    left: 38%;
}

.k-grid .k-command-col .k-grid-cancel {
    right: 38%;
}

.k-grid .k-command-col .k-button .k-icon {
    width: 0;
    height: 0;
}


.k-grid .k-state-focused.k-state-selected {
    color: #fff !important;
    background-color: #CA141B;
    border-color: #A01015;
    -webkit-box-shadow: none!important;
    -ms-box-shadow: none!important;
    box-shadow: none!important;
}

.k-grid .k-pager-wrap {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.k-grid, .k-grid-toolbar{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.no-scrollbar .k-grid-header {
	padding: 0 !important;
}

.no-scrollbar .k-grid-content {
	overflow-y: hidden;    
}

ul.k-group.k-menu-group.k-popup.k-reset.k-state-border-left, ul.k-group.k-menu-group.k-popup.k-reset.k-state-border-right {
    padding-bottom: 10px;
}

.k-state-focused, .k-list > .k-state-focused, .k-listview > .k-state-focused, .k-grid-header th.k-state-focused, td.k-state-focused, .k-button.k-state-focused {
    box-shadow: none;
}

.k-grid .k-state-selected, .k-grid .k-state-selected:link, .k-grid .k-state-selected:visited {
    background-color: $grid-selected-row-bg;
    border-color: $grid-border;
}

.k-grid tr.k-state-selected:hover td {
    background-color: $grid-selected-hover-row-bg;
    color: $white;
}

.k-grid tr:hover td {
    background-color: $grid-hover-row-bg
}

.k-grid td.k-state-selected:hover, .k-grid tr.k-state-selected:hover td a, k-grid td.k-state-selected, .k-grid tr.k-state-selected td a {
    color: $white;
}

.k-grid tr.k-state-selected:hover td a {
    text-decoration: underline;
}

.k-grid tr.k-state-selected > td {
    background-color: rgba(255,99,88,.25);
}

.k-grid tr.k-state-selected td a {
    text-decoration: none;
}

.k-state-active, .k-state-active:hover, .k-active-filter, .k-tabstrip .k-state-active {
    border-color: #ccc;
}

.k-grid-norecords-template {
    white-space: nowrap;
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0px !important;
    border: none;
}

.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
    top: 50%;
    left: 40%;
}

form.k-filter-menu .k-textbox {
    padding-left:0px;
}


.k-grid-header .k-grid-filter.k-state-active, .k-grid-header .k-grid-filter.k-active, .k-grid-header .k-header-column-menu.k-state-active, .k-grid-header .k-header-column-menu.k-active, .k-grid-header .k-hierarchy-cell .k-icon.k-state-active, .k-grid-header .k-hierarchy-cell .k-icon.k-active {
    background-color: $blue;
    color: $white;
}
.k-link{
    color: black;
}
