@import "../base/variables";

html {
    background-color: $background-color;
}

body {
    position: fixed;
    background-color: transparent;
    line-height: 1;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

html,
body {
    width: 100%;
    height: 100%;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0;
}

.relative {
    position: relative;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.tab-content {
    padding: 10px;
}

.flex-container-column > .tab-content {
    display: flex;
    flex: 1 0 0px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.flex-container-column > .tab-content > .active {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
}

.no-margin {
    margin: 0px !important;
}

.no-padding {
    padding: 0px;
}

.no-left-padding {
    padding-left: 0px !important;
}

.no-right-padding {
    padding-right: 0px !important;
}


.no-left-padding {
    padding-left: 0px !important;
}

.no-l-r-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* used for boot strap grid layout display */
.layout-grid {
    position: absolute;
    width: 100%;
}

.layout-grid .col-xs-1 div {
    height: 2000px;
    border-color: #171e28;
    opacity: 0.3 !important;
    background-color: #dddddd;
}

.hard-left {
    padding-left: 0px;
}

.left-title {
    margin-top: 10px;
    margin-left: -30px;
}

.row-vertical-space {
    margin-bottom: 10px;
}

.pos-abt {
    display: none !important;
}

.app-header .nav > li > a:hover,
.app-header .nav > li > a:focus {
    background-color: transparent;
}

.app-header .nav > li.active > a {
    background-color: $primary-color1;
    border-bottom: 5px solid $white;
    padding-bottom: 12px;
}

.multiselect-list-container {
    width: 100%;
    height: 250px;
    background-color: white;
    overflow: auto;
    border: 1px solid #ccc
}

input.medium-checkbox,
.medium-checkbox input[type="checkbox"] {
    min-width: 20px;
    min-height: 20px;
    max-height: 20px; //seems like a garbage rule (was in 1common.scss, mobile only)
    margin-top: 0px !important;
}

.medium-checkbox.checkbox-inline label {
    padding-left: 5px;
    font-weight: normal;

    html.mobile & {
        padding-left: 32px;
    }
}

.col-lg-2-4 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-0 {
    clear: left;
}

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}


.no-gutter [class*="col"] { /* csslint allow: regex-selectors */
    padding: 0;
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

.row-sm > div {
    padding-left: 10px;
    padding-right: 10px;
}

.app {
    height: auto;
    min-height: 100%;
    width: 100%;
    position: relative;
}

.app-header-fixed {
    padding-top: 50px;
}

.app-header-fixed .app-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
}

.app-header {
    z-index: 100;
    border-radius: 0;
}

.app-aside {
    position: fixed;
    left: 0;
    top: 50px;
    bottom: 0;
    background-color: $actions-bg-color;
    color: $black;
}

.app-aside a:hover {
    background-color: $black;
    color: $white;
}

.app-aside .nav li > a {
    color: $black;
    background-color: $header-bg-color;
}

.app-aside .nav li.active > a {
    background-color: $black;
    color: #F7F7F7;
}

.app-aside ul.nav li li a {
    padding-left: 35px;
}

oc-window-list .nav li a:hover,
oc-window-list .nav li a:focus,
.app-aside .nav li > a:hover,
.app-aside .nav li.active > a:hover {
    background-color: $black;
    color: $white;
}

.app-aside:before {
    content: "";
    position: fixed;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border: inherit;
    box-sizing: border-box;
}


.app-content-grid {
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
}

.app-content {
    overflow: auto;
    position: absolute;
    top: 50px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.mobile .app-content {
    position: static;
    height: 100%
}


.app-content:before,
.app-content:after {
    content: " ";
    display: table;
}

.app-content:after {
    clear: both;
}

.app-content-body {
    padding-bottom: 10px;
    float: left;
    width: 100%;
}

.app-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}


.one-row-search-offset {
    top: 176px;
}

.two-row-search-offset {
    top: 246px;
}

.three-row-search-offset {
    top: 321px;
}

.four-row-search-offset {
    top: 382px;
}

.v-middle {
    vertical-align: middle !important;
}

.v-top {
    vertical-align: top !important;
}

.v-bottom {
    vertical-align: bottom !important;
}

.app-aside {
    width: 25px;
    z-index: 1;
    overflow: hidden;
    border-right: 1px dotted $black;
}

.app-aside button {
    display: none;
}

.app-aside:hover,
.app-aside:focus {
    width: 250px;
}

.app-aside:hover button,
.app-aside:focus button {
    display: block;
}


.app-content,
.app-footer {
    margin-left: 25px;
}

@media (min-width: 768px) {

    .container {
        -webkit-box-shadow: 0 3px 60px rgba(0,0,0,0.3);
        box-shadow: 0 3px 60px rgba(0,0,0,0.3);
        border-left: 1px solid #c1ced2;
        border-right: 1px solid #c1ced2;
    }

    .app-aside {
        width: 250px;
        border-right: 1px solid $black4;
    }

    .app-aside button {
        display: block;
    }

    .app-content,
    .app-footer {
        margin-left: 250px;
    }

    .visible-folded {
        display: none;
    }

    .bg-auto:before {
        content: "";
        position: absolute;
        width: inherit;
        top: 0;
        bottom: 0;
        z-index: -2;
        background-color: inherit;
        border: inherit;
    }

    .bg-auto.bg-auto-left:before {
        left: 200px;
    }

    .bg-auto.bg-auto-right:before {
        right: 0;
    }

    .bg-auto.bg-auto-right {
        background-color: $off-white;
    }

    .col.show {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {

    .navbar-form {
        box-shadow: 0 -1px 0 rgba(0,0,0,0.1);
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .navbar-form .form-group {
        margin-bottom: 0;
    }
}

.streamline {
    position: relative;
    border-color: $black4;
}

.streamline .sl-item:after,
.streamline:after {
    content: '';
    position: absolute;
    background-color: $white;
    border-color: inherit;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 9px;
    height: 9px;
    margin-left: -5px;
    bottom: 0;
    left: 0;
}

.sl-item {
    border-color: $black4;
    position: relative;
    padding-bottom: 1px;
}

.sl-item:before,
.sl-item:after {
    content: " ";
    display: table;
}

.sl-item:after {
    clear: both;
}

.sl-item:after {
    top: 6px;
    bottom: auto;
}

.sl-item.b-l {
    margin-left: -1px;
}

.tl-item {
    display: block;
}

.tl-item:before,
.tl-item:after {
    content: " ";
    display: table;
}

.tl-item:after {
    clear: both;
}

.visible-left {
    display: none;
}

.tl-wrap {
    display: block;
    margin-left: 6em;
    padding: 15px 0 15px 20px;
    border-style: solid;
    border-color: $black4;
    border-width: 0 0 0 4px;
}

.tl-wrap:before,
.tl-wrap:after {
    content: " ";
    display: table;
}

.tl-wrap:after {
    clear: both;
}

.tl-wrap:before {
    position: relative;
    content: "";
    float: left;
    top: 15px;
    margin-left: -27px;
    width: 10px;
    height: 10px;
    border-color: inherit;
    border-width: 3px;
    border-radius: 50%;
    border-style: solid;
    background: $off-white;
    box-shadow: 0 0 0 4px #f0f3f4;
}

.tl-wrap:hover:before {
    background: transparent;
    border-color: $white;
}

.tl-date {
    position: relative;
    top: 10px;
    float: left;
    margin-left: -7.5em;
    display: block;
    width: 4.5em;
    text-align: right;
}

.tl-content {
    display: inline-block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tl-content.block {
    display: block;
    width: 100%;
}

.tl-content.panel {
    margin-bottom: 0;
}

.tl-header {
    display: block;
    width: 12em;
    text-align: center;
    margin-left: 2px;
}

.i-switch {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    border-radius: 30px;
    background-color: $green2;
    margin: 0;
}

.i-switch input {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0); /* csslint allow: known-properties */
}

.i-switch input:checked + i:before {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 5px;
    border-width: 0;
    border-radius: 5px;
}

.i-switch input:checked + i:after {
    margin-left: 16px;
}

.i-switch i:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-color: $white;
    border: 1px solid $black4;
    border-radius: 30px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.i-switch i:after {
    content: "";
    position: absolute;
    background-color: $white;
    width: 18px;
    top: 1px;
    bottom: 1px;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
    -webkit-transition: margin-left 0.3s;
    transition: margin-left 0.3s;
}

.i-switch-md {
    width: 40px;
    height: 24px;
}

.i-switch-md input:checked + i:after {
    margin-left: 17px;
}

.i-switch-md i:after {
    width: 22px;
}

.i-switch-lg {
    width: 50px;
    height: 30px;
}

.i-switch-lg input:checked + i:after {
    margin-left: 21px;
}

.i-switch-lg i:after {
    width: 28px;
}

.i-checks {
    padding-left: 20px;
    cursor: pointer;
}

.i-checks input {
    opacity: 0;
    position: absolute;
    margin-left: -20px;
}

.i-checks input:checked + i {
    border-color: $red1;
}

.i-checks input:checked + i:before {
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: $red1;
}

.i-checks input:checked + span .active {
    display: inherit;
}

.i-checks input[type="radio"] + i,
.i-checks input[type="radio"] + i:before {
    border-radius: 50%;
}

.i-checks input[disabled] + i,
fieldset[disabled] .i-checks input + i {
    border-color: $black4;
}

.i-checks input[disabled] + i:before,
fieldset[disabled] .i-checks input + i:before {
    background-color: $black4;
}

.i-checks > i {
    width: 20px;
    height: 20px;
    line-height: 1;
    border: 1px solid $black3;
    background-color: $white;
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    position: relative;
    box-sizing: border-box;
}

.i-checks > i:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 0;
    height: 0;
    background-color: transparent;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.i-checks > span {
    margin-left: -20px;
}

.i-checks > span .active {
    display: none;
}


.pos-rlt {
    position: relative;
}

.pos-stc {
    position: static;
}

.pos-abt {
    position: absolute;
}

.line {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    font-size: 0;
    overflow: hidden;
}

.line-xs {
    margin: 0;
}

.line-lg {
    margin-top: 15px;
    margin-bottom: 15px;
}

.line-dashed {
    border-style: dashed !important;
    background-color: transparent;
    border-width: 0;
}

.no-line {
    border-width: 0;
}

.no-borders {
    border-color: transparent;
    border-width: 0;
}

.no-radius {
    border-radius: 0;
}

.block {
    display: block;
}

.block.hide {
    display: none;
}

.inline {
    display: inline-block !important;
}

.none {
    display: none;
}

.pull-none {
    float: none;
}

.rounded {
    border-radius: 500px;
}

.clear {
    display: block;
    overflow: hidden;
}

.no-bg {
    background-color: transparent;
    color: inherit;
}

.l-h {
    line-height: 1.42857143;
}

.l-h-1x {
    line-height: 1.2;
}

.l-h-2x {
    line-height: 2em;
}


.box-shadow {
    box-shadow: 0 2px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05);
}

.box-shadow-lg {
    box-shadow: 5px 5px 10px rgba(0,0,0,0.05);
}

.no-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.wrapper-xs {
    padding: 5px;
}

.wrapper-sm {
    padding: 10px;
}

.wrapper {
    padding: 15px;
}

.wrapper-md {
    padding: 10px 20px 10px 20px;
}

.wrapper-lg {
    padding: 30px;
}

.wrapper-xl {
    padding: 50px;
}

.padder-lg {
    padding-left: 30px;
    padding-right: 30px;
}

.padder-md {
    padding-left: 20px;
    padding-right: 20px;
}

.padder {
    padding-left: 15px;
    padding-right: 15px;
}

.padder-sm {
    padding-left: 5px;
    padding-right: 5px;
}

.padder-v {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padder-v-sm {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-bottom-sm {
    padding-bottom:10px;
}
.no-padder {
    padding: 0 !important;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-out {
    margin: -10px -15px;
}

.b {
    border: 1px solid rgba(0,0,0,0.05);
}

.b-a {
    border: 1px solid $black4;
}

.b-t {
    border-top: 1px solid $black4;
}

.b-r {
    border-right: 1px solid $black4;
}

.b-b {
    border-bottom: 1px solid $black4;
}

.b-l {
    border-left: 1px solid $black4;
}

.b-light {
    border-color: $off-white;
}

.b-dark {
    border-color: $black;
}

.b-black {
    border-color: $black;
}

.b-primary {
    border-color: $black;
}

.b-success {
    border-color: $green2;
}

.b-info {
    border-color: $red1;
}

.b-warning {
    border-color: $yellow1;
}

.b-danger {
    border-color: $red1;
}

.b-white {
    border-color: $white;
}

.b-dashed {
    border-style: dashed !important;
}


.b-2x {
    border-width: 2px;
}

.b-3x {
    border-width: 3px;
}

.b-4x {
    border-width: 4px;
}

.b-5x {
    border-width: 5px;
}

.r {
    border-radius: 2px 2px 2px 2px;
}

.r-2x {
    border-radius: 4px;
}

.r-3x {
    border-radius: 6px;
}

.r-l {
    border-radius: 2px 0 0 2px;
}

.r-r {
    border-radius: 0 2px 2px 0;
}

.r-t {
    border-radius: 2px 2px 0 0;
}

.r-b {
    border-radius: 0 0 2px 2px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-xxl {
    margin-left: 50px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-l-n-xxl {
    margin-left: -50px;
}

.m-t-none {
    margin-top: 0;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 50px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-t-n-xxl {
    margin-top: -50px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-xxl {
    margin-right: 50px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-r-n-xxl {
    margin-right: -50px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-xxl {
    margin-bottom: 50px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.m-b-n-xxl {
    margin-bottom: -50px;
}

.avatar {
    position: relative;
    display: block;
    border-radius: 500px;
    white-space: nowrap;
}

.avatar img {
    border-radius: 500px;
    width: 100%;
}

.avatar i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 2px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;
}

.avatar i.right {
    left: auto;
    right: 0;
}

.avatar i.bottom {
    left: auto;
    top: auto;
    bottom: 0;
    right: 0;
}

.avatar i.left {
    top: auto;
    bottom: 0;
}

.avatar .on {
    background-color: $green2;
}

.avatar .off {
    background-color: $black3;
}

.avatar .busy {
    background-color: $red1;
}

.avatar .away {
    background-color: $yellow1;
}

.avatar.thumb-md i {
    width: 12px;
    height: 12px;
    margin: 3px;
}

.avatar.thumb-sm i {
    margin: 1px;
}

.avatar.thumb-xs i {
    margin: 0;
}

.w-xs {
    width: 90px;
}

.w-sm {
    width: 150px;
}

.w {
    width: 200px;
}

.w-md {
    width: 240px;
}

.w-lg {
    width: 280px;
}

.w-xl {
    width: 320px;
}

.w-xxl {
    width: 360px;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-auto {
    width: auto;
}

.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}

.align-items-center {
    align-items: center;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.always-scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow: visible;
    overflow-y: auto;
}

.scroll-x,
.scroll-y {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.no-touch .scroll-x,
.no-touch .scroll-y {
    overflow: hidden;
}

.no-touch .scroll-x:hover,
.no-touch .scroll-x:focus,
.no-touch .scroll-x:active {
    overflow-x: auto;
}

.no-touch .scroll-y:hover,
.no-touch .scroll-y:focus,
.no-touch .scroll-y:active {
    overflow-y: auto;
}

.hover-action {
    display: none;
}

.hover-rotate {
    -webkit-transition: all .2s ease-in-out .1s;
    transition: all .2s ease-in-out .1s;
}

.hover:hover .hover:focus .hover-action,
.hover:active .hover-action {
    display: inherit;
}

.hover:hover .hover:focus .hover-rotate,
.hover:active .hover-rotate {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
}

.backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0); /* csslint allow: known-properties */
}

.backdrop.in {
    opacity: 0.8;
    filter: alpha(opacity=80); /* csslint allow: known-properties */
}

@media screen and (min-width: 992px) {
    .col-lg-2-4 {
        width: 20.000%;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm.show {
        display: inherit !important;
    }
}

@media (max-width: 767px) {
    .w-auto-xs {
        width: auto;
    }

    .shift {
        display: none !important;
    }

    .shift.in {
        display: block !important;
    }

    .row-2 [class*="col"] { /* csslint allow: regex-selectors */
        width: 50%;
        float: left;
    }

    .row-2 .col-0 {
        clear: none;
    }

    .row-2 li:nth-child(odd) {
        clear: left;
        margin-left: 0;
    }

    .text-center-xs {
        text-align: center;
    }

    .text-left-xs {
        text-align: left;
    }

    .text-right-xs {
        text-align: right;
    }

    .no-border-xs {
        border-width: 0;
    }

    .pull-none-xs {
        float: none !important;
    }

    .pull-right-xs {
        float: right !important;
    }

    .pull-left-xs {
        float: left !important;
    }

    .dropdown-menu.pull-none-xs {
        left: 0;
    }

    .hidden-xs.show {
        display: inherit !important;
    }

    .wrapper-lg,
    .wrapper-md {
        padding: 15px;
    }

    .padder-lg,
    .padder-md {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.list-icon i {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    -webkit-transition: font-size .2s;
    transition: font-size .2s;
}

.list-icon div {
    line-height: 40px;
    white-space: nowrap;
}

.list-icon div:hover i {
    font-size: 26px;
}

.settings {
    z-index: 1000;
    position: fixed;
    top: 95px;
    right: -240px;
    width: 240px;
    -webkit-transition: right 0.2s;
    transition: right 0.2s;
}

.settings.active {
    right: -1px;
}

.settings > .btn {
    background: $off-white !important;
    border-right-width: 0;
    border-color: $black4;
    position: absolute;
    left: -42px;
    top: -1px;
    padding: 10px 15px;
}

.settings .i-checks span b {
    width: 50%;
    height: 20px;
    display: inline-block;
}

.settings .i-checks span .header {
    height: 10px;
}


.sortable-placeholder {
    list-style: none;
    border: 1px dashed $grey2;
    min-height: 50px;
    margin-bottom: 5px;
}

.item {
    position: relative;
}

.item .top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.item .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.item .center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}

.item-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.item-overlay.active,
.item:hover .item-overlay {
    display: block;
}


#logo { /* csslint allow: ids */
    margin-left: 0;
    width: 178px;
    height: 50px;
}


.bg-gd {
    background-image: -webkit-gradient(linear,left 0,left 100%,from(rgba(40,50,60,0)),to(rgba(40,50,60,0.075)));
    background-image: -webkit-linear-gradient(top,rgba(40,50,60,0) 0,rgba(40,50,60,0.075) 100%);
    background-image: -moz-linear-gradient(top,rgba(40,50,60,0) 0,rgba(40,50,60,0.075) 100%);
    background-image: linear-gradient(to bottom,rgba(40,50,60,0) 0,rgba(40,50,60,0.075) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#1328323c', GradientType=0); /* csslint allow: known-properties */
    filter: none;
}

.bg-gd-dk {
    background-image: -webkit-gradient(linear,left 10%,left 100%,from(rgba(40,50,60,0)),to(rgba(40,50,60,0.5)));
    background-image: -webkit-linear-gradient(top,rgba(40,50,60,0) 10%,rgba(40,50,60,0.5) 100%);
    background-image: -moz-linear-gradient(top,rgba(40,50,60,0) 10%,rgba(40,50,60,0.5) 100%);
    background-image: linear-gradient(to bottom,rgba(40,50,60,0) 10%,rgba(40,50,60,0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#7f28323c', GradientType=0); /* csslint allow: known-properties */
    filter: none;
}

.bg-white-opacity {
    background-color: rgba(255,255,255,0.5);
}

.bg-black-opacity {
    background-color: rgba(32,43,54,0.5);
}

.bg-light {
    background-color: $header-bg-color;
    color: #333333;
}

.bg-light.lt,
.bg-light .lt {
    background-color: #f3f5f6;
}

.bg-light.lter,
.bg-light .lter {
    background-color: $header-bg-color;
}

.bg-light.dk,
.bg-light .dk {
    background-color: $header-bg-color;
}

.bg-light.dker,
.bg-light .dker {
    background-color: $black4;
}

.bg-light.bg,
.bg-light .bg {
    background-color: $header-bg-color;
}

.bg-dark {
    background-color: $black;
    color: $white;
}

.bg-dark.lt,
.bg-dark .lt {
    background-color: $black2;
}

.bg-dark.lter,
.bg-dark .lter {
    background-color: $black3;
}

.bg-dark.dk,
.bg-dark .dk {
    background-color: $black;
}

.bg-dark.dker,
.bg-dark .dker {
    background-color: $black;
}

.bg-dark.bg,
.bg-dark .bg {
    background-color: $black2;
}

.bg-dark a {
    color: $blue3;
}

.bg-dark a:hover {
    color: $white;
}

.bg-dark a.list-group-item:hover,
.bg-dark a.list-group-item:focus {
    background-color: inherit;
}

.bg-dark .nav > li:hover > a,
.bg-dark .nav > li:focus > a,
.bg-dark .nav > li.active > a {
    color: $white;
    background-color: #2e3344;
}

.bg-dark .nav > li > a {
    color: $blue3;
}

.bg-dark .nav > li > a:hover,
.bg-dark .nav > li > a:focus {
    background-color: #32374a;
}

.bg-dark .nav .open > a {
    background-color: #2e3344;
}

.bg-dark .caret {
    border-top-color: #9ea4b9;
    border-bottom-color: #9ea4b9;
}

.bg-dark.navbar .nav > li.active > a {
    color: $white;
    background-color: #2e3344;
}

.bg-dark .open > a,
.bg-dark .open > a:hover,
.bg-dark .open > a:focus {
    color: $white;
}

.bg-dark .text-muted {
    color: #8088a4 !important;
}

.bg-dark .text-lt {
    color: #e8e9ef !important;
}

.bg-dark.auto .list-group-item,
.bg-dark .auto .list-group-item {
    border-color: #2f3342 !important;
    background-color: transparent;
}

.bg-dark.auto .list-group-item:hover,
.bg-dark.auto .list-group-item:focus,
.bg-dark.auto .list-group-item:active,
.bg-dark.auto .list-group-item.active,
.bg-dark .auto .list-group-item:hover,
.bg-dark .auto .list-group-item:focus,
.bg-dark .auto .list-group-item:active,
.bg-dark .auto .list-group-item.active {
    background-color: #2e3344 !important;
}

.bg-black {
    color: $white;
    background-color: $black;
}

.bg-black.lt,
.bg-black .lt {
    background-color: $black2;
}

.bg-black.lter,
.bg-black .lter {
    background-color: $black3;
}

.bg-black.dk,
.bg-black .dk {
    background-color: $off-white;
}

.bg-black.dker,
.bg-black .dker {
    background-color: $black;
}

.bg-black.bg,
.bg-black .bg {
    background-color: $black2;
}

.bg-black .navbar-toggle,
.bg-black .navbar-toggle:hover,
.bg-black .navbar-toggle:focus {
    border-color: $black;
    background-color: transparent;
}

.bg-black button.navbar-toggle,
.bg-black button.navbar-toggle:hover,
.bg-black button.navbar-toggle:focus {
    border-color: $black;
    background-color: $grey2;
}

.bg-black a {
    color: $grey2;
}

.bg-black a:hover {
    color: $white;
}

.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
    background-color: inherit;
}

.bg-black .nav > li:hover > a,
.bg-black .nav > li:focus > a,
.bg-black .nav > li.active > a {
    color: $white;
    background-color: $black;
}

.bg-black .nav > li > a {
    color: $grey2;
    background-color: transparent;
}

.bg-black .nav > li > ul > li > a {
    color: $grey2;
}

.bg-black .nav > li > a:hover,
.bg-black .nav > li > a:focus {
    background-color: transparent;
    color: $off-white;
}

.bg-black .nav .open > a {
    background-color: transparent;
}

.bg-black .caret {
    border-top-color: $blue2;
    border-bottom-color: $blue2;
}

.bg-black.navbar .nav > li.active > a {
    color: $foreground-color;
    background-color: $primary-color1;
}

.bg-black .open > a,
.bg-black .open > a:hover,
.bg-black .open > a:focus {
    color: $white;
}

.bg-black .text-muted {
    color: $grey2 !important;
}

.bg-black .text-lt {
    color: $blue3 !important;
}

.bg-black.auto .list-group-item,
.bg-black .auto .list-group-item {
    border-color: #131e25 !important;
    background-color: transparent;
}

.bg-black.auto .list-group-item:hover,
.bg-black.auto .list-group-item:focus,
.bg-black.auto .list-group-item:active,
.bg-black.auto .list-group-item.active,
.bg-black .auto .list-group-item:hover,
.bg-black .auto .list-group-item:focus,
.bg-black .auto .list-group-item:active,
.bg-black .auto .list-group-item.active {
    background-color: $black2 !important;
}

.bg-white {
    background-color: $white;
    color: $black2;
}

.bg-white a {
    color: #363f44;
}

.bg-white a:hover {
    color: #1f2427;
}

.bg-white .text-muted {
    color: #999999 !important;
}

.bg-white-only {
    background: $black;
}
/*#region Common*/
.deactive {
    color: $black3;
}

.list-wrapper .list-heading {
    height: 35px;
}

.list-wrapper .list-heading .list-action {
    float: right;
}

.list-wrapper .list-content {
    overflow: auto;
    overflow-y: scroll;
    max-height: 150px;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 10px;
}

.list-wrapper a {
    position: relative;
    top: 8px;
}

.list-wrapper .list-content .item-content .item-main {
    color: rgb(123, 123, 123);
    font-size: 13px;
}

.list-wrapper .list-content .item-content .item-normal {
    color: rgb(123, 123, 123);
    font-size: 13px;
}

.list-wrapper .list-content .item-content .item-info {
    color: $black2;
    font-size: 12px;
    margin-bottom: 10px;
}

.list-wrapper .list-content .item-content .item-info .text-info,
.list-wrapper .list-content .item-content .item-info .text-date {
    color: $black2;
    text-decoration: underline;
    margin-right: 10px;
    line-height: 24px;
}


form .toolbar-control {
    margin-top: -30px;
}

table.table tbody .odd {
    background-color: #fafbfc;
}

table.table tbody td {
    line-height: 33px !important;
}

form .one-column .col-md-5 {
    width: 20.8333333%;
}

form .one-column .col-md-7 {
    width: 79.1666667%;
}

form .one-column .col-md-7 .form-control-static .form-control {
    width: 34.74%;
    float: left;
    margin-right: 5px;
}
/*#endregion Common*/
/*exportBuilderSettings*/
.exportBuilderSettings .full-width .col-md-5 {
    width: 20.8333333%;
}

.exportBuilderSettings .full-width .col-md-7 {
    width: 79.1666667%;
}

.exportBuilderSettings .one-column .col-md-5 {
    width: 20.8333333%;
}

.exportBuilderSettings .one-column .col-md-7 {
    width: 79.1666667%;
}
/*=================================
    CSS Report
==================================*/
.report-content {
    background-color: $white;
    border: 1px solid $grey2;
    min-height: 350px;
    padding: 0 5px;
}

.notification-report-content #oReportDiv { /* csslint allow: ids */
    overflow-y: hidden !important;
}

.notification-report-content #oReportDiv td { /* csslint allow: ids */
    word-wrap: break-word;
    word-break: break-all;
}

.has-no-data-wrapper {
    padding: 0 !important;
}

.has-no-data-wrapper .has-no-data {
    display: block;
    margin: 0;
    padding: 12px 46px;
    text-align: center;
    font-size: 1em;
    line-height: 1em;
}

.report-toolbar {
    margin-top: 15px;
    margin-bottom: 15px;
}

/*No data*/
.no-data {
    color: $grey;
    font-size: 12px;
}
/*Filter*/
.filter-section {
    margin-bottom: 20px;
}

/*endregion*/
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-md-6, .col-md-12, .mg-col {
    padding-left: 5px;
    padding-right: 5px;
}

.has-label {
    width: 80%;
    float: left;
    margin-right: 3px;
}

.row .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .text-left-sm {
        text-align: left;
    }

    .text-center-sm {
        text-align: center;
    }

    .text-right-sm {
        text-align: right;
    }

    .text-justify-sm {
        text-align: justify;
    }
}

@media (min-width: 992px) {
    .text-left-md {
        text-align: left;
    }

    .text-center-md {
        text-align: center;
    }

    .text-right-md {
        text-align: right;
    }

    .text-justify-md {
        text-align: justify;
    }
}

@media (min-width: 1200px) {
    .text-left-lg {
        text-align: left;
    }

    .text-center-lg {
        text-align: center;
    }

    .text-right-lg {
        text-align: right;
    }

    .text-justify-lg {
        text-align: justify;
    }
}

footer {
    position: fixed;
    bottom: 25px;
    left: 0;
    right: 0
}


.fleet-details .actions-dropdown {
    left: -310px;
}

.default-padding {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.columns-apply-a-rate-of-zero {
    display: -webkit-box;
}

.radio-inline, .checkbox-inline {
    line-height: 20px; /* Without this the text doesn't look vertically centered compared to the radio button. */
}

.tooltip-information {
    margin: 20px 0 0 0;
    padding: 20px;
    background-color: $black4;
    border-radius: 5px;
}

.k-grid-header th.k-header, .k-filter-row th {
    white-space: normal;
    vertical-align: middle;
    text-align: center;
}

.top-buffer {
    margin-top: 25px;
}
/* amt framework puts big grey drop shadow around the container ?? */
.container {
    box-shadow: none;
    border-left: none;
    border-right: none;
}

oc-data-sync-module {
    cursor: pointer;
}

oc-data-sync-module .transparent {
    cursor: default;
}

a.disabled {
    color: $black2;
    text-decoration: none;
    cursor: default;
}



.divider {
    margin: 20px 0 20px 0;
    border-top: 1px solid $black4;
    width: 100%;
    display: block;
}


.well,
pre {
    background-color: white;
    border-color: $black4;
}


.center-box {
    display: table;
    margin: 5px;
    min-height: 65px;
    width: 100%;
}

.center-box > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 5px;
}

.left-box {
    display: table;
    margin: 5px;
    min-height: 65px;
    height: 100%;
    width: 100%;
}

.left-box > div {
    display: table-cell;
    vertical-align: middle;
    text-align: left; /* Backup */
    text-align: start; /* Better than "left" as it supports RTL mode. */
    padding: 5px;
}

.alert-info-area {
    margin: 0px 5px 5px 5px;
}

.alert-info-area > div {
    text-align: left;
    padding: 0 5px 0 12px;
    overflow-y: auto;
    max-height: calc(100vh - 140px);
}

.align-middle {
    vertical-align: middle;
}

.notification-list {
    list-style: inside;
    text-align: left;
}

.flex-container,
.flex-container-row {
    display: flex;
    flex-direction: row;
}


.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex {
    //Dont use auto for flex as certain browsers (chrome, safari) ignore min
    //height of flex items which causes scrolling issues
    flex: 1 0 0%;
    -ms-flex: 1 0 auto;
}

.no-flex, .flex-none {
    flex: none;
}

.overflow-auto {
    overflow: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-only-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.flex-cell {
    border: 0px solid red;
}

.flex-grid {
    flex: 1 0 0px;
}

.flex-grid .k-grid-norecords {
    top: 0px;
    left: 0px;
    position: absolute !important;
}

.flex-container > amt-grid, .flex-grid > .amt-grid > .k-grid-content {
    display: flex;
    flex: 1 0 0px;
}

.flex-grid .amt-grid {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
}

.flex-container > ng-form {
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
}


.inline-flex {
    display: inline-flex;
}

.justify-center {
    justify-content: center;
}

.justify-right {
    justify-content: flex-end;
}

.input-label-valign {
    display: table;
}

.input-label-valign .row {
    display: table-row;
}

.input-label-valign .cell-middle {
    display: table-cell;
    vertical-align: middle;
}

.input-label-valign .cell-top {
    display: table-cell;
    vertical-align: top;
}

.input-label-valign input[type="checkbox"] {
    margin-top: 0px !important;
}

.wizard-success-panel {
    border: 1px solid;
    background-color: $wizard-step-bg-color-success;
    color: $wizard-step-color-success;
}

.align-self-center {
    align-self: center;
}


.otracom-logo {
    margin-left: 5px;
    height: 42px !important;
}

.otracom-logo-container {
    align-items: center;
    width: 250px;
}

.header-logo {
    margin-left: auto;
    margin-right: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
    height: 42px !important;
}

.header-logo-container {
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    height: 50px;
}

.row-no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.padding-l-xs {
    padding-left: 5px;
}

.padding-l-sm {
    padding-left: 10px;
}

.padding-l-m {
    padding-left: 15px;
}

.padding-l-lg {
    padding-left: 30px;
}

.margin-auto {
    margin: auto;
}

.padding-r-xs {
    padding-right: 5px;
}

.padding-r-sm {
    padding-right: 10px;
}

.padding-r-m {
    padding-right: 15px;
}

.padding-r-lg {
    padding-right: 30px;
}

.padding-t-xs {
    padding-top: 5px;
}

.padding-t-sm {
    padding-top: 10px;
}

.padding-t-m {
    padding-top: 15px;
}

.padding-t-lg {
    padding-top: 30px;
}

.menu-search {
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px !important;
}

.menu-search i {
    padding-top: 10px;
    padding-right: 15px;
}

.menu-search input {
    text-transform: uppercase;
}

.menu-search .glyphicon {
    font-size: 1.2em;
}

.menu-search .glyphicon-remove {
    pointer-events: auto;
}

.menu-search-no-results {
    padding-top: 10px;
    text-align: center;
}

.menu-search-no-results hr {
    margin-top: 10px;
}

.menu-group-break {
    width: 80%;
    height: 1px;
    background-color: grey;
}

.div-divider-left {
    border-left: 1px solid lightgray;
}

a:hover .triangle-right {
    color: $white;
}

.triangle-right {
    color: $black;
}

.triangle-bottom {
    color: $white;
}

.copy-icon {
    font-size: 1.2em;
}

.center-all {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%
}

/* separate (with whitespace) picker list from picker on mobile */
html.mobile .multi-field-picker-separator {
    margin-top: 10px !important;
}

.block-full-width {
    width: 100%;
    display: inline-block;
}

.wrap {
    word-wrap: break-word;
    white-space: normal;
}

.amt-wizard-steps {
    max-width: 300px;
}