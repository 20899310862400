@import "../base/variables";

.survey-grid-row {
    min-height: 43px;
}

//TODO: desktop window hack
.child-window-body.maintenance {
    overflow-x: hidden !important;
}

.maintenance-right-column {
    width: 300px;
    flex: none;
    display: flex;
    flex-direction: row;
    padding: 0px;
    float: right; //not sure this is needed
}

html.mobile .maintenance-right-column {
    width: auto;
}

maintenance .actions-menu {
    width: 14rem;
    flex: none;
}

maintenance-checkin .maintenance-right-column,
maintenance-checkout .maintenance-right-column {
    flex-direction: column;
    float: none;
}

maintenance-checkin .checkin-fields > oc-property-row > .row {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}

.remove-individual-header {
    padding-bottom: 0px;
}

@media (orientation: portrait) {
    //TODO: these selectors suck (overly specific / fragile), replace them
    html.mobile maintenance-checkout > ng-form > div,
    html.mobile maintenance-checkin > ng-form > div {
        flex-direction: column;
    }

    html.mobile maintenance-checkin .maintenance-right-column {
        margin: auto;
        width: auto;
        padding-top: 18px;
    }

    html.mobile maintenance vehicle-stats {
        display: grid;
        grid-template-columns: 45vw 45vw;
        column-gap: 16px;
    }

    html.mobile maintenance-checkout .maintenance-right-column {
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
    }

    html.mobile .multi-field-picker-separator {
        font-size: 1.125rem;
    }
}

.maintenance-padding {
    padding: 5px;
}

.maintenance-header {
    margin-right: 10px;
    margin-bottom: 10px;
}

.maintenance-header-selected-vehicle {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    font-weight: bold;
    font-size: $large-font-size;
}

.maintenance-header-picker {
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
}

.maintenance-header-actions {
    flex: 1 0 0%;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
}

html.desktop .maintenance-header-actions .actions-menu {
    margin-left: 10px;
}

.maintenance-header-actions .actions-menu {
    width: 200px;
    position: relative;
}

.maintenance-header-actions .actions-menu .btn {
    width: 100%;
}

.fs-content-pane {
    height: inherit;
}

html.desktop .fs-content-pane {
    padding-top: 10px;
    padding-bottom: 10px;
}

field-surveys error-panel,
.maintenance-error-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
}

.survey-grid-header {
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0px;
}

.survey-grid-row {
    border-top: 1px solid #eaeff0;
    margin: 0px;
}


.survey-grid-cell {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 30px;
}

.schematic-display {
    border: 0 dotted $schematic-grey;
    margin: auto;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

/* overrides for isteven multi select */
.multiSelect > button {
    width: 100% !important;
}

.multiSelect .line {
    height: auto !important;
    margin: 0px !important;
}

.view-mobile-page .picker-sized .fixed-table-container {
    /* the size of the open multiFieldPicker vehicle drop down on a desktop field survey 
        Mainly fills the drop dialog.
    */
    height: 620px;
    width: 954px;
}

.view-mobile-page .picker-unsized .fixed-table-container {
    position: fixed;
    top: 45px;
    right: 15px;
    left: 15px;
    bottom: 15px;
    height: auto;
}

.view-mobile-page .picker-unsized .fixed-table-container td {
    padding: 15px;
}

.display-background-dk {
    background-color: lightgray;
}

.events-grid {
    width: 100%;
    height: 320px;
}

.vehicle-stats {
    border: 1px rgb(204, 204, 204) solid;
    height: 100%;
    margin: 5px 5px 5px 25px;
}

.vehicle-stats-divider {
    width: 60%;
    margin-top: 5px;
    margin-bottom: 12px; // Seems to be bigger because of the "extra" line break? Doesn't really matter I guess...
}



.row-disabled {
    background: none;
    color: $black3;
}


.operational-targets {
    width: 90px;
}


.field-menu .btn-default-menu-left {
    border: 1px solid $grey;
}


.grid-loading {
    position: absolute;
    z-index: 1;
    top: 92px;
    left: 30px;
    background: #EEE;
    right: 30px;
    border: 5px solid #EEE;
}

.fixed-scrolling-grid {
    border: 1px solid #ddd;
    position: fixed;
    bottom: 15px;
    left: 27px;
    width: 71%;
    padding-right: 15px;
    overflow-y: auto;
}

.fixed-scrolling-grid-desktop {
    border: 1px solid #ddd;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    max-height: 350px;
}

.fixed-scrolling-grid-desktop.schematic {
    height: 265px;
}

.fixed-height-desktop-field-survey {
    max-height: 645px;
    overflow: hidden;
}

.survey-validation.validation-error {
    background-color: $error-panel-red;
    color: $white;
}

.survey-validation.no-error {
    background-color: $error-panel-green;
    //color: $white; // changed for OR-8414 - not sure if this is correct
}

.survey-validation {
    background-color: #def2f2;
}


/* item left behind */
.drag-list .drag {
    background-color: #aaa;
    color: #000;
    opacity: 1;
}

.drag {
    background-color: $grey;
    opacity: 0.5;
}

.drag > .dragSource {
    background-color: $black3;
}

.dragSource {
    background-color: #ddd;
    width: 80%;
    padding: 10px;
    border-bottom: 3px solid $white;
    box-sizing: border-box;
}

.details-header-specification {
    font-size: large;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.details-header-status {
    font-size: large;
    margin-right: 5px;
    font-weight: bold;
    margin-bottom: 10px;
}

.delay-grid-header > div {
    font-weight: bold;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
}

.delay-grid-footer > div {
    border-bottom: 1px solid $black;
    margin-top: 5px;
    margin-bottom: 10px;
}

.delay-grid-row {
    height: 42px;
}



.oc-comment {
    width: 100%;
    margin-top: 1px;
    border-radius: 4px;
    border: 1px solid $grey2;
}

.oc-comment .ng-invalid {
    border: 1px solid #a94442;
}

.action-list {
}
/* custom styling when the action list item is disabled (this is in maintenance sessions, and doesnt apply to the amt-actions buttons) */
.action-list tr[disabled=disabled] {
    color: $grey2;
}

.operational-toolbar {
    width: 100%;
    margin: 0px;
}

//no idea why this is called what it is?
.factory-window-container {
    padding-left: 15px;
    padding-right: 15px;
}

.fs-header-selected-vehicle {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    font-weight: bold;
    font-size: $large-font-size;
}

.fs-header {
    margin-right: 10px;
}

.fs-header-picker {
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
}

.fs-header-actions {
    flex: 1 0 0%;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
}

.fs-header-actions .actions-menu {
    width: 200px;
    position: relative;
}

html.desktop .fs-header-actions .actions-menu {
    margin-left: 10px;
}

.fs-header-actions .actions-menu .btn {
    width: 100%;
}

html.mobile {

    .maintenance-header {
        min-height: $large-control-height;
        margin-top: 10px;
    }

    .fs-header {
        min-height: $large-control-height;
        margin-top: 10px;
    }

    .fs-content-pane {
        overflow: hidden;
        padding-top: 7px;
    }

    .readings-tab-content,
    .fs-content {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .view-mobile-page .picker-unsized .fixed-table-container {
        top: 60px;
    }

    .survey-grid-header {
        font-size: $large-font-size;
        padding-top: 0px;
        padding-bottom: 8px;
        font-weight: normal;
    }

    .survey-grid-cell {
        min-height: 30px;
        font-size: $large-font-size;
    }

    .reading-readonly {
        font-size: $large-font-size;
    }

    .view-mobile-page .picker-unsized .fixed-table-container td {
        font-size: 1.375rem;
        padding: 10px;
        right: 10px;
        left: 10px;
    }

    .view-mobile-page .picker-unsized .fixed-table-container {
        right: 10px;
        left: 10px;
    }

    maintenance.fixed-table-container .th-inner-label {
        height: auto; //override amt setting a div to a fixed px height that may be too small for its content
    }

    .fixed-table-container .th-inner-label > label {
        font-size: 1.375rem;
        margin-top: 4px;
        margin-top: 4px;
    }

    .position-cell {
        width: 65px;
    }

    .vi-image {
        height: 32px;
        padding: 0 4px;
    }

    maintenance .amt-form-control .amt-form-control-button {
        height: $large-control-height !important;
    }

    field-surveys .amt-form-control .item-display,
    maintenance .amt-form-control .item-display {
        vertical-align: middle;
        height: 100%;
        display: inline-block;
        line-height: 48px;
        text-overflow: ellipsis;
        width: calc(100% - 48px);
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
    }

    .vehicle-picker {
        margin-left: 10px;
        height: 30px;
        margin-right: 10px;
        width: 0px;
    }
}

html.mobile maintenance {
    reading-grid {
        padding-top: 12px;
    }

    .amt-form-control .amt-form-control-button {
        border-radius: 2px;
    }

    .amt-form-control .dropdown-menu {
        width: unset;
        top: $large-control-height !important;
    }

    .amt-form-control .amt-form-control-button {
        height: $large-control-height;
        padding: 0px;
    }

    .amt-form-control .closebutton {
        font-size: 2.5rem;
        line-height: 47px !important;
        padding: 0px 5px 0px 5px;
    }

    .droptarget-text {
        font-size: $large-font-size;
    }

    .k-grid tr td {
        font-size: $large-font-size;
        padding: 5px;
    }

    .nav-tabs > li {
        font-size: $large-font-size;
    }

    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $large-font-size;
    }

    .amt-form-control .btn-group.open .dropdown-toggle {
        font-size: $large-font-size;
    }

    .k-filter-row th, .k-grid-header th.k-header {
        padding: 10px 0px 10px 0px !important;
    }

    .combobox .options {
        max-height: none !important;
    }

    amt-display .form-horizontal label {
        font-size: $large-font-size-root;
        margin-bottom: 0px;
    }

    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $large-font-size;
    }

    .k-grid tr td {
        font-size: $large-font-size;
    }

    .row-vertical-space {
        margin-bottom: 5px;
    }
}

html.mobile {
    .maintenance-error-panel {
        margin-top: 0px;
    }

    .schematicAxleRect > text {
        font-size: $large-font-size;
    }

    .maintenance .droptarget-caption-rect {
        font-size: $large-font-size;
    }

    .maintenance .droptarget-caption {
        font-size: $large-font-size;
    }

    .reading-readonly {
        line-height: $reading-grid-line-height-mobile;
    }

    .bootstrap-timepicker-widget table td input {
        min-width: 50px;
        height: 50px;
        font-size: 1.5em;
    }

    .bootstrap-timepicker-widget table td span {
        font-size: 1.5em;
    }

    .k-calendar-container {
        font-size: 1.5em !important;
    }

    .maintenance-padding {
        padding: 0px;
    }

    .fieldsurvey-picker th.date-col {
        width: 15ch;
    }
}

html.mobile .lubricated-studs-control {
    height: $large-control-height;

    input[type="radio"] {
        height: 30px;
        width: 30px;
        margin-left: -35px;
    }

    .radio-inline {
        padding-left: 35px;
        margin-right: 30px;
        line-height: 35px;
    }
}

html.mobile .large-delays {
    font-size: $large-font-size !important;
}

field-surveys .displayKeyPadLeft {
    flex-direction: row-reverse;
}

maintenance .maintenance-right-column {
    margin-right: 10px;
}

html.mobile maintenance .status-dropdown {
    margin-left: 0px;
}

maintenance .btn-maintenance {
    margin-left: 0px;
}

maintenance .btn-maintenance.btn-default {
    margin: 0px;
}

field-surveys oc-vehicle-schematic,
maintenance oc-vehicle-schematic {
    min-height: 210px;
    display: block;
}

maintenance oc-vehicle-schematic {
    padding-bottom: 8px;
}

maintenance .form-container {
    margin-left: 10px;
}

maintenance .reading-tab {
    flex-direction: row;
    display: flex;
    height: 100%;
}

maintenance oc-vehicle-schematic .justify-center {
    justify-content: normal;
}

html.desktop maintenance .flex-container-column > .tab-content > .active.tab-pane-readings {
    flex-direction: row;
}

maintenance .readings-tab-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

html.desktop maintenance .readings-tab-content {
    overflow-y: auto;
}

html.desktop maintenance .field-survey-column {
    padding-left: 6px;
}

field-survey-delete-all {
    padding-bottom: 15px;
}

html.mobile .maintenance-tabs.flex-tabs > .nav-tabs > li > a {
    padding: 5px 0;
}

html.mobile .maintenance-tabs.readings .tab-pane {
    flex-direction: row;
}

html.mobile .maintenance-tabs.readings.displayKeyPadLeft .reading-tab {
    flex-direction: row-reverse;
}

html.mobile .maintenance-tabs.readings.displayKeyPadLeft oc-keypad {
    margin-left: 0;
}

html.mobile .maintenance-tabs.readings.displayKeyPadLeft oc-panel {
    margin-left: 0;
}

html.mobile maintenance .view-mobile-page {
    overflow: hidden;
}

html.mobile maintenance .tab-content {
    overflow: auto;
}

html.mobile .mobile-actionsmenu {
    left: unset !important; //important needed to override the inline styles Angular UI will add
    right: 20px !important;
    top: calc(75% - 10rem) !important;
    width: 12rem;
    font-size: 1.63rem;
    z-index: 5;
}

.mobile-actionsmenu-overlay {
    display: block;
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
}

vehicle-readings {
    html.mobile &,
    html.mobile & input[type=text] {
        font-size: 1.375rem;
    }

    input {
        text-align: right;
    }

    hr {
        margin: 16px 4px 20px 4px;
    }

    .current-readings-row,
    .previous-readings-row {
        padding-left: 4px;
    }

    .heading-row {
        padding-top: 8px;
        text-align: center;
        font-weight: bold;
    }

    .row-heading {
        padding-left: 0px;
        font-weight: bold;
    }

    .new-meter {
        text-align: center;
    }

    .reading {
        text-align: right;

        span {
            margin-right: 13px; // magic number - align to input which has 12px padding, 1px border (13px)
        }
    }

    .reading-deltas {
        text-align: right;

        span {
            margin-right: calc(13px - .4em); //half an em for the brace
        }

        span:before {
            content: '(';
        }

        span:after {
            content: ')';
        }

        span:empty:before,
        span:empty:after, {
            content: none;
        }
    }
}

.pressure-reading-type-panel {
    margin-left: 10px;
    margin-right: 10px;
    flex: none;
    width: 200px; // same as keypad and error panel
}

html.mobile .pressure-reading-type-panel {
    margin-left: 7px;
    margin-right: 0px;
}

@media (orientation: landscape) {
    html.mobile .pressure-reading-type-panel .panel-heading {
        font-size: 1em;
    }

    html.mobile .pressure-reading-type-panel .panel-body {
        font-size: 1em;
    }
}

@media (orientation: portrait) {
    html.mobile .pressure-reading-type-panel {
        display: inline-flex;
        align-items: center;
        width: auto;
        margin-right: 7px;
    }
}

html.mobile .pressue-reading-type-control {
    height: $large-control-height;

    label {
        display: inline-flex;
        align-items: center;
    }

    input[type="radio"] {
        min-width: 30px;
        min-height: 30px;
    }

    .amt-filter-radio span {
        margin-left: 5px;
        bottom: unset;
    }
}
