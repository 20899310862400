@import "../base/variables";

.btn { /* csslint allow: outline-none */
    border-radius: 2px;
    outline: 0 !important;
}

.btn-full {
    width:100%;
}

.btn-bold {
    font-weight: bold;
}

.modal-footer > .btn {
    min-width: 100px;
}

.btn-link {
    color: $black2;
}

.k-state-selected .btn-link {
    color: $white;
}

.btn-link.active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-default {
    color: $button-light-color !important;
    border-color: $button-border-color;
    background-color: $button-light-bg;
    -webkit-box-shadow: 0 1px 1px rgba(90,90,90,0.1);
    box-shadow: 0 1px 1px rgba(90,90,90,0.1);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    color: $button-light-color !important;
    background-color: $button-light-hover-bg;
}

.btn-default-menu-left {
    z-index: 1;
    position: absolute;
    top: 44px;
    right: 0;
    color: $button-light-color !important;
    background-color: $button-light-bg;
    border: 0;
}

.btn-default-menu-left tr:hover {
    background-color: $button-light-hover-bg;
}

.btn-default-menu-left td.disabled {
    color: $black3 !important;
    background-color: $white;
}

.btn-default-menu-left tr {
    border: 1px solid $black4;
}

.btn-default-menu-left td {
    padding: 5px;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
    background-color: #fcfdfd;
    border-color: #E0E0E0;
}

.btn-default.btn-bg {
    border-color: rgba(0,0,0,0.1);
    background-clip: padding-box;
}

.btn-primary {
    color: $white !important;
    background-color: $button-dark-bg;
    border-color: $button-border-color;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    color: $white !important;
    background-color: $button-dark-hover-bg;
    border-color: $button-border-color;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
    background-color: #231F20;
    border-color: #231F20;
}

.btn-success {
    color: $white !important;
    background-color: $green2;
    border-color: $button-border-color;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    color: $white !important;
    background-color: $green1;
    border-color: $button-border-color;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    background-image: none;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
    background-color: $green4;
    border-color: $button-border-color;
}

.btn-info {
    color: $white !important;
    background-color: $red2;
    border-color: $button-border-color;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    color: $white !important;
    background-color: $red1;
    border-color: $button-border-color;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    background-image: none;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
    background-color: $red4;
    border-color: $button-border-color;
}

.btn-warning {
    color: $white !important;
    background-color: $yellow2;
    border-color: $button-border-color;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    color: $white !important;
    background-color: $yellow1;
    border-color: $button-border-color;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    background-image: none;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
    background-color: $yellow4;
    border-color: $button-border-color;
}

.btn-danger {
    color: $white !important;
    background-color: $red2;
    border-color: $button-border-color;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    color: $white !important;
    background-color: $red1;
    border-color: $button-border-color;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
    background-color: $red4;
    border-color: $button-border-color;
}


.btn > i.pull-left,
.btn > i.pull-right {
    line-height: 1.42857143;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: 2px;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-bottom-left-radius: 2px;
}

.btn-addon i {
    margin: -7px -12px;
    margin-right: 12px;
    background-color: rgba(0,0,0,0.1);
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    float: left;
    position: relative;
    border-radius: 2px 0 0 2px;
}

.btn-addon i.pull-right {
    margin-right: -12px;
    margin-left: 12px;
    border-radius: 0 2px 2px 0;
}

.btn-addon.btn-sm i {
    margin: -6px -10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.btn-addon.btn-sm i.pull-right {
    margin-right: -10px;
    margin-left: 10px;
}

.btn-addon.btn-lg i {
    margin: -11px -16px;
    margin-right: 16px;
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.btn-addon.btn-lg i.pull-right {
    margin-right: -16px;
    margin-left: 16px;
}

.btn-addon.btn-default i {
    background-color: transparent;
    border-right: 1px solid $black4;
}


.btn-fixed-width {
    width: 150px;
}

.btn-fixed-width-wide {
    width: 250px;
}

.btn-min-width {
    min-width: 150px;
}

.btn-fixed-width-xwide {
    max-width: 500px;
    width: 100%;
}


.btn-ok {
    min-width: 110px;
}


.margin-button-title button {
    margin-right: 5px;
}

.margin-button-title button:last-child {
    margin-right: 0;
}


.btn-group > .btn {
    margin-left: -1px;
}

/* Spinner "placeholder" for a button. See ComponentReceive. */
.button-processing {
    background-image: url("/Content/common/spinner.gif");
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: center;
    width: 100%;
    height: 50px;
}

.btn-option:hover:enabled {
    background-color: black;
    color: white;        
}

.wizard-button {
    background: $grey5;
    min-height: 60px;
    white-space: normal;
}

.selected {
    background-color: black;
    color: white !important;
}

.btn-required {
    border-color: $validation-red !important;
}

html.mobile {
    .btn-maintenance {
        font-size: $large-font-size !important;
        line-height: 48px;
        padding: 0px;
    }

    field-surveys .btn-maintenance {
        margin: 0; //TODO: remove this (don't want to mess with field surveys right now)
    }

    .btn-default-menu-left {
        top: 60px;
    }

    .btn {
        font-size: $large-font-size;
        height: $large-control-height;
    }

    .btn-home {
        font-size: 21px;
        margin-right: 0px;
        margin-left: 10px;
    }

    .btn-home span {
        top: 6px;
    }

    .btn-fixed-width-wide {
        width: 400px;
    }


    .btn-receive {
        height: unset;
        width: 550px;
    }
}