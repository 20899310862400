.bootstrap-timepicker-widget table td input {
    min-width: 50px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
    min-width: 130px;
}


/* Override bootstrap.css and list-group.less to give rounding client wants */
.list-group-item:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

/* Override bootstrap.css and list-group.less to give rounding client wants */
.list-group-item:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.form-horizontal .radio-inline {
    padding-top: 0; /* Not sure why bootstrap sets this to 7px, looks odd. */
    width: auto; /* "amt-display .form-horizontal label" in amtframework sets this to 100% need it auto or it will never inline. */
    vertical-align: -webkit-baseline-middle;
}

/* navbar fixes: tell the nav-bar to not break */
.collapse {
    display: block;
}

.navbar-header {
    float: left;
}

.navbar-toggle {
    display: none;
}

.navbar-nav.navbar-left {
    float: left;
    margin: 0;
}

.navbar-nav.navbar-right {
    float: right;
    margin: 0;
}

.navbar-nav > li {
    float: left;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}
/* end of navbar fixes*/

html.mobile .dropdown-menu {
    font-size: 1.25rem
}