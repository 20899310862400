@import "../base/variables";

.panel-heading {
    padding: 10px !important;
    border-radius: 2px 2px 0 0;
}

.panel {
    border-radius: 2px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    box-shadow:none;
}

.panel-no-box-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.panel .accordion-toggle {
    font-size: 14px;
    display: block;
    cursor: pointer;
}

.panel .list-group-item {
    border-color: $off-white;
}

oc-panel.no-borders {
    border-width: 0;
}

oc-panel.no-borders .panel-heading,
oc-panel.no-borders .panel-footer {
    border-width: 0;
}


.panel-default .panel-heading {
    background-color: $off-white;
}

.panel-heading.no-borders {
    margin: -1px -1px 0 -1px;
    border: none;
}

.panel-heading .nav {
    margin: -10px -15px;
}

.panel-heading .list-group {
    background: transparent;
}

.panel-footer {
    border-color: $off-white;
    border-radius: 0 0 2px 2px;
    background-color: #fff;
}

.panel-default {
    border-color: $black4;
}

.panel-default > .panel-heading,
.panel-default > .panel-footer {
    border-color: $off-white;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #eaedef;
}


html.mobile oc-panel > .panel-heading {
    font-size: $large-font-size;
}

html.mobile oc-panel > .panel-body {
    font-size: $large-font-size;
    padding: 0px 5px 0px 5px;
}

html.mobile oc-panel > .panel-body > button {
    font-size: $large-font-size-root;
    padding: 5px;
}

error-panel {
    flex-direction: column;
}

.error-panel {
    border: none;
    position: relative;
}

.error-panel .btn {
    margin-top: 0px;
    width: 100%;
}
/* csslint ignore:start */ /* outline none rule can't be ignored in-line' */
.error-panel .header {
    width: 100%;
    color: #333333;
    border-radius: 2px;
    outline: 0;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4285;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.error-panel .details {
    color: #333333;
    border-radius: 2px;
    outline: 0;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4285;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    overflow-x: hidden;
    width: 100%;
}

.error-panel-details-scroll {
    overflow-y: auto;
}

/* csslint ignore:end */
.error-panel .lower-block {
    position: absolute;
    bottom: 0;
    height: 141px;
    padding: 0;
    padding-right: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
}

.error-panel > oc-panel .btn {
    background-color: $grey2;
    margin-top: 0px;
}

.error-panel > oc-panel .btn-highlight {
    background-color: $black !important;
    color: $white;
}

.error-panel > oc-panel textarea {
    width: 100%;
    border-width: 0px;
    overflow: auto;
    padding: 0px;
}


error-panel oc-panel {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
}

error-panel oc-panel.error-message-panel > .panel-body {
    display: flex;
    flex: auto;
}

oc-panel {
    display: block;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: none;

    border: 1px solid $grey2;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px !important;
}

oc-panel > .panel-heading {
    background: $grey2;
}

oc-panel > .panel-body {
    line-height: 30px;
}