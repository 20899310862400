@import "../base/variables";

reading-grid {
    text-align: center;

    html.mobile & {
        font-size: 1.375rem;
    }

    input,
    input:read-only {
        width: 100%;
        text-align: center;
        border: none !important;
        border-radius: 0px;
        background: transparent;
    }

    html.desktop & thead {
        font-weight: bold;
    }

    tr:nth-child(even) {
        background: #F2F2F2;
    }

    table {
        border-collapse: collapse;
    }

    tr,
    input {
        height: 40px !important;
    }

    td {
        border: 1px solid #eaeff0;
        border-width: 1px 0px;
        white-space: nowrap;
    }

    input:disabled {
        background: $black4;
        color: #888;
    }

    input.keypad-focus:not(:disabled) {
        color: #FFF;
        background-color: $schematic-fs-selected !important;
    }

    html.mobile & {
        .vi-cell.highlighted {
            background-color: $yellow2;
        }

        .vi-cell.highlighted * {
            opacity: .6;
        }
    }
}