.combobox .filter {
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.combobox .filter.placeholder {
    color: #aaa;
}

.combobox .options {
    min-height: 50px;
    max-height: 180px;
    overflow-y: auto;
    padding: 3px 10px;
    background-color: white;
    border: 1px rgb(207, 218, 221) solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -1px;
}

.combobox .option {
    line-height: 20px;
}

.combobox .option:hover {
    color: white;
    background-color: #428bca;
    cursor: pointer;
}

.combobox .message {
    line-height: 20px;
    background-color: rgb(207, 218, 221);
    font-weight: bold;
}
