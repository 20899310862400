@import "../base/variables";

.tab-container {
    margin-bottom: 15px;
}

.tab-container .tab-content {
    padding: 15px;
    background-color: $white;
    border: 1px solid $black4;
    border-top-width: 0;
    border-radius: 0 0 2px 2px;
}

.tab-no-padding > .tab-content {
    padding:0px 0px 0px 0px;
}

.nav-tabs {
    text-align: center;
}

html.mobile .flex-tabs > .nav-tabs > li {
    flex: 1 0 0px;
    font-size: $large-font-size;
}

html.mobile .flex-tabs-fs > .nav-tabs {
    display: flex;
}

html.mobile .flex-tabs-fs > .nav-tabs > li {
    flex: 1 0 0px;
    font-size: $large-font-size;
}

html.mobile .flex-tabs .nav-tabs > li > a {
    padding: 5px 15px;
}

html.mobile .nav-tabs > li {
    font-size: $large-font-size;
}

.flex-tabs > .nav-tabs {
    display: flex;
}

.flex-tabs > .nav-tabs > li {
    flex: 1 0 0px;
}

.flex-tabs-fs {
    margin: 10px 0 0 10px;
}

.flex-tabs-fs > .nav-tabs {
    display: flex;
}

.flex-tabs-fs > .nav-tabs > li {
    flex: 1 0 0px;
}