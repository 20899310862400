@import '../../../styles/base/variables';


.amt-wizard {
    margin-bottom: 20px;
    @include flex-properties(row);
}

.amt-wizard > div > div > a.active,
.amt-wizard > div > ul > li.active {
    background-color: $wizard-step-bg-color-active;
}

.amt-wizard > div > div > a.list-group-item.active,
.amt-wizard > div > ul > li.list-group-item.active,
.amt-wizard > div > div > a.list-group-item.active:hover,
.amt-wizard > div > ul > li.list-group-item.active:hover {
    color: $wizard-step-color-active;
    background-color: $wizard-step-bg-color-active;
}

.amt-wizard > div > div > a.success,
.amt-wizard > div > ul > li.success,
.amt-wizard > div > div > a.success:hover,
.amt-wizard > div > ul > li.success:hover,
.amt-wizard > div > div > a.success.active,
.amt-wizard > div > ul > li.success.active,
.amt-wizard > div > div > a.success.active:hover,
.amt-wizard > div > ul > li.success.active:hover {
    background-color: $wizard-step-bg-color-success;
    color: $wizard-step-color-success;
}

.amt-wizard > div > div > a.invalid,
.amt-wizard > div > ul > li.invalid,
.amt-wizard > div > div > a.invalid.active,
.amt-wizard > div > ul > li.invalid.active,
.amt-wizard > div > div > a.invalid.active:hover,
.amt-wizard > div > ul > li.invalid.active:hover {
    background-color: $wizard-step-bg-color-error;
    color: $wizard-step-color-error;
}

.amt-wizard > div > div > a.list-group-item.disabled,
.amt-wizard > div > ul > li.list-group-item.disabled,
.amt-wizard > div > div > a.list-group-item.disabled:hover,
.amt-wizard > div > ul > li.list-group-item.disabled:hover {
    color: $wizard-step-color-disabled;
    background-color: $wizard-step-bg-color-disabled;
}

.amt-wizard-step-wrapper {
    overflow-y: auto;

    .amt-wizard-step-content {
        width: 98%;
    }
}

.wizard-step-title {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 10px;
}

.wizard-step-icon {
    min-width: 25px;
    flex: none;
}


amt-wizard {
    @include flex-properties(column);
}

.amt-wizard-body-container {
    flex: 1 0 300px;
    overflow: auto;
}

amt-wizard-body {
    @include flex-properties(column);
    padding: 10px;
}

amt-wizard-step {
    flex:none;
}

.amt-wizard .additional-buttons-transclude-container {
    display: inline-block;
}
