@import "../base/variables";

.schematic-table {
    width: 100%;
}

.schematic-chain {
    stroke: none;
    fill: $schematic-grey;
}

.schematic-tyre-line {
    fill: $schematic-tyre-fill;
}

.schematic-tyre-line {
    stroke: $schematic-tyre-stroke;
    stroke-width: 2;
}

.drag-drop-overlay {
    stroke: none;
    fill: $white;
    opacity: 0.01;
}

.fadeToLeftStart {
    stop-opacity: 0;
    stroke: none;
    stop-color: $white;
}

.fadeToLeftEnd {
    stop-opacity: 0.95;
    stroke: none;
    stop-color: $white;
}

.schematic-container {
    position: relative;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: $schematic-bg;
}

.maintenance {
}

.maintenance .droptarget {
    opacity: 1; // don't let anything bleed through OR-6538
    stroke: $schematic-grey;
    stroke-width: 1;
    fill: $white;
    text-anchor: middle;
    text-align: center;
}

.maintenance .droptarget-margin {
    stroke: none;
    fill: url(#fadeToLeft);
}

.maintenance .droptarget-background {
    fill: $white;
}

.maintenance .drag-in-progress .droptarget-container {
    fill: $schematic-green;
    fill-opacity: 0.3;
}

.maintenance .hover {
    stroke-width: 2;
}

.maintenance .dragging {
    opacity: 0.6;
    /* 
            removed this because the left and top are also being affected
            see: http://jira.snowdenonline.com.au:8080/browse/OR-3456

            transform: scale(1.1);
        */
}

.maintenance .dragging > rect {
    stroke: $schematic-red !important;
    stroke-width: 2 !important;
}

.maintenance .can-drag {
    cursor: move !important;
}

.maintenance .cannot-drag {
    cursor: not-allowed !important;
}

.maintenance .droptarget-caption-rect {
    stroke-width: 1;
    fill: $schematic-grey;
}

.maintenance .droptarget-caption {
    stroke-width: 1;
    stroke: $black;
    fill: $black;
}

.maintenance .droptarget-text {
    stroke-width: 1;
    text-transform: uppercase;
    stroke: $black;
    fill: $black;
    opacity: 0.2;
    word-wrap: break-word;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}

.schematicAxleRect {
    stroke: $schematic-grey;
    stroke-width: 1;
    fill: $white;
    text-anchor: middle;
   
}

.schematicAxleRect > text {
    fill: $schematic-axle-text;
    font-weight: bold;
    stroke-width: 0;
    text-anchor: middle;
    font-size: 12px;
}

.schematicShaft {
    stroke: $schematic-grey;
    stroke-width: 1;
    fill: $white;
    text-anchor: middle;
}

.schematicShaft > text {
    fill: $black;
    text-anchor: middle;
}

.chevron-left {
    margin: auto;
    width: 24px;
    height: 24px;
    float: left;
}

.chevron-right {
    margin: auto;
    width: 24px;
    height: 24px;
    float: right;
}

.zoom-rect {
    fill: none;
    stroke: $schematic-red;
    stroke-width: 1;
    fill: $schematic-red;
    fill-opacity: 0.2;
}

.snap-rect {
    fill: none;
    stroke: $schematic-red;
    stroke-width: 1;
    stroke-dasharray: 5 5 1 5;
    transition: all 0.5s;
}

.touchPoint {
    stroke: none;
    fill: $schematic-green;
    fill-opacity: 0.2;
}

.schematicWheelPositionRect {
    stroke: $schematic-wheel-position-stroke;
    stroke-width: 1;
    fill: none;
}

.schematicWheelPositionRect.empty {
    stroke: $schematic-yellow;
    fill: $schematic-green;
    opacity: 0.2;
}

.schematicWheelPositionRect.empty.hover {
    stroke-width: 2;
}

.schematicWheelPositionRectFieldSurvey {
    stroke: $schematic-fs-stroke;
    stroke-width: 2;
    fill: $schematic-fs-fill;
}

.schematicWheelPositionRectFieldSurvey.noTyre {
    stroke-dasharray: 15, 10;
    fill: none;
}

.schematicWheelPositionRectFieldSurvey.noChain {
    stroke-dasharray: 15,10;
    fill: none;
}

.schematicWheelPositionRectFieldSurvey.center {
}

.schematicWheelPositionRectFieldSurvey > text {
    stroke: none;
    fill: $schematic-fs-stroke-center;
    font-size: 30px;
    text-anchor: middle;
    cursor: pointer;
}

.schematicWheelPositionLabel > rect {
    opacity: 0;
}

.schematicWheelPositionLabel > text {
    stroke: $schematic-fs-text-stroke;
    fill: $schematic-fs-text;
    font-size: $schematic-fs-text-size;
    text-anchor: middle;
    cursor: pointer;
}

.text-length-1 {
    font-size: $schematic-fs-text-size !important;
}

.text-length-2 {
    font-size: $schematic-fs-text-size2 !important;
}

.text-length-3 {
    font-size: $schematic-fs-text-size3 !important;
}

.text-length-4 {
    font-size: $schematic-fs-text-size4 !important;
}

.text-length-5 {
    font-size: $schematic-fs-text-size5 !important;
}

.schematicWheelPositionRect.selected > rect {
    stroke: $schematic-red !important;
    stroke-width: 2;
    stroke-opacity: 1 !important;
}

.schematicWheelPositionLabel.setlabel > rect,
.schematicWheelPositionLabel.viewlabel > text {
    stroke-width: 1px;
    stroke: $schematic-setlabel-stroke;
    fill: $schematic-setlabel-fill;
    opacity: 1;
    cursor: pointer;
}

.schematicWheelPositionLabel.setlabel > text,
.schematicWheelPositionLabel.viewlabel > text {
    stroke-width: 1px;
    stroke: $schematic-setlabel-stroke;
    fill: $schematic-setlabel-stroke;
    font-size: 20px;
}

.schematicWheelPositionLabel.setlabel > text {
    text-decoration: underline;
}

.schematicWheelPositionRectFieldSurvey.selected > rect {
    fill: $schematic-fs-selected;
}

.schematicWheelPositionRect > text {
    stroke: none;
    fill: $schematic-fs-text;
    font-size: 30px;
    text-anchor: middle;
    cursor: pointer;
}

.schematicWheelPositionRect.fittedTyre.pos-label-only > text {
    stroke: $schematic-fs-text-stroke;
    fill: $schematic-fs-text;
    font-size: $schematic-fs-text-size;
    text-anchor: middle;
    cursor: pointer;
}

.schematicWheelPositionRect.noTyre.pos-label-only > text {
    stroke: none;
    fill: $schematic-fs-text;
    font-size: 30px;
    text-anchor: middle;
    cursor: pointer;
}

.schematicWheelPositionRect.fittedTyre > text {
    font-size: small;
    fill: $schematic-fitted-text;
    stroke: none;
}

.schematicWheelPositionRect.fittedTyre > rect {
    fill-opacity: 1;
    opacity: 1;
    stroke: $schematic-fitted-border;
    stroke-width: 2px;
    fill: $black;
}

.schematicWheelPositionRect.noTyre > rect {
    fill: $schematic-wheel-position-empty-fill;
    opacity: 1;
    stroke: $black;
    stroke-width: 1px;
}

.schematicWheelPositionRect.noChain > rect {
    fill: $schematic-wheel-position-empty-fill;
    opacity: 1;
    stroke: $black;
    stroke-width: 1px;
}

.schematicWheelPositionRect.fittedRim > rect {
    fill: $schematic-fitted-fill;
    opacity: 1;
    stroke: $schematic-fitted-border;
    stroke-width: 2px;
}

.schematicWheelPositionRect.fittedRim > text {
    font-size: small;
    fill: $schematic-fitted-text;
    stroke: none;
}

.schematicWheelPositionRect.noRim > rect {
    fill: $schematic-fitted-fill;
    opacity: .2;
    stroke: $black;
    stroke-width: 1px;
}

.schematicWheelPositionRect.fittedChain > text {
    font-size: small;
    fill: $schematic-fitted-fill;
    stroke: none;
}

.schematicWheelPositionRect.fittedChain > rect {
    opacity: 1;
    stroke: $schematic-fitted-border;
    stroke-width: 2px;
}

.schematicWheelPositionRect.noTyre > rect {
    opacity: .2;
    stroke-dasharray: 15,10;
    stroke-width: 2px;
}

.schematicWheelPositionRect.noRim > rect {
    opacity: .2;
    stroke-dasharray: 15,10;
    stroke-width: 2px;
}

.schematicWheelPositionRect.noChain > rect {
    opacity: .2;
    //stroke-dasharray: 5,5;
}
