@import "../base/variables";

.stock-spacer {
    height: 50px;
}

.stock-row-text {
    padding-left: 40px;
}

drop-body .stock-row-text {
    padding-left: 60px;
}

drop-body drop-body .stock-row-text {
    padding-left: 80px;
}

drop-body drop-body drop-body .stock-row-text {
    padding-left: 100px;
}

.stock-row-active .stock-row-text {
    font-weight: bold;
    padding-left: 55px;
}

drop-body .stock-row-active .stock-row-text {
    font-weight: bold;
    padding-left: 75px;
}

drop-body drop-body .stock-row-active .stock-row-text {
    font-weight: bold;
    padding-left: 95px;
}

drop-body drop-body drop-body .stock-row-active .stock-row-text {
    font-weight: bold;
    padding-left: 115px;
}

drop-body stock-row.alt .stock-item {
    __border: 2px dashed $blue1;
}

drop-body drop-body stock-row .stock-item {
    background: #E0E0E0;
}

drop-body drop-body stock-row.alt .stock-item {
    background: #E7E7E7;
}

drop-body drop-body stock-row.alt .row {
    background: #E7E7E7;
}

drop-body drop-body stock-row .row {
    background: #E0E0E0;
}

drop-body drop-body drop-body stock-row .stock-item {
    background: $background-color;
}

drop-body drop-body drop-body stock-row .row {
    background: $background-color;
}

drop-body drop-body drop-body stock-row.alt .stock-item {
    background: #f0f0f0;
}

drop-body drop-body drop-body stock-row.alt .row {
    background: #f0f0f0;
}

drop-body drop-body drop-body .stock-row-deleted div {
    text-decoration: line-through;
    color: $blue1;
}

drop-body drop-body drop-body .stock-row-extra div {
    color: $green1;
}

.drop-indicator {
    position: absolute;
    left: 15px;
    top: 11px;
    z-index: 1
}

drop-body .drop-indicator {
    left: 35px;
}

drop-body drop-body .drop-indicator {
    left: 55px;
}


.stocktake > .stocktake-vehicle-serial {
    margin: 0px 10px 0px 0px !important;
}

.stock-item {
    padding-top: 11px; /* need to pick these with your finger */
    padding-bottom: 11px; /* need to pick these with your finger */
}

.stock-divide {
    border-bottom: 1px solid #666666;
    line-height: 1px;
}

.stock-row-active {
    border: 1px solid $grey2;
}

.stock-grid-desktop {
   padding:0px;
   margin:0px;
   
}

.stock-grid-desktop > div {
    border: 1px solid #f0f0f0;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 686px;
}

.stock-grid-mobile {
    position: static;
    width: unset;
    margin-bottom: 10px;
    overflow-y: auto;
}

.stock-grid-mobile > div {
    border: 1px solid #f0f0f0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    height: 100%;
}

/* really can't see white on that blue below (#d9edf7), so blue also changed to darker */
.stock-row-active .keypad-focus-point {
    color: white;
    background-color: $blue1 !important;
}

.stocktake-header {
    font-weight: bold;
}

stocktake error-panel {
    padding-top: 10px;
    height: 570px;
    width: 100%;

    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
}

html.mobile stocktake error-panel {
    display: none;
}

.stocktake-header-row {
    padding: 0px 20px 5px 0px;
}

.stocktake-header-row-mobile {
    padding: 0px 0px 10px 0px;
}

html.mobile .stocktake-component-change {

    td, p, label, textarea {
        font-size: $medium-font-size;
    }

    oc-comment > div, .remaining-char-label {
        font-size: $medium-font-size;
    }

    input[type=checkbox] {
        min-width: 30px;
        max-width: 30px;
    }

    hr {
        border-top-width: 3px !important;
    }
}

//just used by stocktakeComments I think
html.mobile .medium-grid {

    .k-grid tr td {
        font-size: $medium-font-size;
        padding: 5px;
    }

    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $medium-font-size;
    }

    .k-filter-row th, .k-grid-header th.k-header {
        padding: 10px 0px 10px 0px !important;
    }

    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $medium-font-size;
    }

    .row-vertical-space {
        margin-bottom: 5px;
    }

    .k-pager-info {
        font-size: $medium-font-size;
        margin-top: 5px;
    }

    .k-pager-sizes {
        font-size: $medium-font-size;
    }

    .k-dropdown-wrap.k-state-default, .k-pager-wrap .k-link {
        height: 35px;
        font-size: $medium-font-size;
    }

    .k-pager-sizes .k-dropdown-wrap .k-input {
        padding: 0px;
    }

    .k-widget.k-grid .k-pager-numbers {
        left: 5.9em;
    }

    .k-grid .k-state-selected {
        font-size: $medium-font-size;
    }

    .k-listbox .k-item, .k-popup .k-list .k-item {
        font-size: $medium-font-size !important;
    }
}

html.mobile {
    .stock-item {
        padding-top: 22px !important; /* need to pick these with your finger */
        padding-bottom: 22px !important; /* need to pick these with your finger */
        font-size: $large-font-size;
    }

    .k-autocomplete.k-state-default .k-input {
        height: unset;
    }

    .k-autocomplete.k-state-default {
        height: unset;
    }

    .stock-row-text {
        font-size: $large-font-size-root;
    }

    .stocktake-header {
        font-size: $large-font-size;
    }

    .stocktake-actions-button {
        top: 49px;
    }

    .stocktake-actions-bar {
        margin-top: 10px;
    }

}