﻿// --- Base Colours ---
$white: #FFFFFF;
$black: #000000;

.uat {
    --green1: #F3AB00; // $uatGreen
}

.prod {
    --green1: #97D700; // $prodGreen
}

$green: #449D44;
$green1: var(--green1);
$greenETLError: #008000;
$green2: #C1E766;
$green3: #D5EF99;
$green4: #EAF7CC;
$green5: #109945;
$greenETLError: #008000;

$blue: #548BD4;
$blue1: #55C1E9;
$blue2: #98DAF2;
$blue3: #BBE6F6;
$blue4: #DDE6F6;

$yellow: #C1E766;
$yellow1: #EEDC00;
$yellow2: #F5EA66;
$yellow3: #F8F199;
$yellow4: #FCF8CC;

$dirtyYellow: $yellow1;

$red: #AC0000;
$red1: #C63527;
$red2: #E0877C;
$red3: #E8AEA9;
$red4: #F4D7D4;


$black1: #333333;
$black2: #63656A;
$black3: #C1C1C3;
$black4: #E0E0E1;

$grey:#F2F2F2;
$darkgrey: #292B2B;

$orange1: #f0ad4e;
$orange2: #ec971f;
// --- Non standard colours --- 
$validation-red: #A94442;
$off-white: #F3F3F3;
$orange: orange;
$purple: purple;
$brown: brown;
$badge-color: #CFDADD;
$grey2: #CCCCCC;
$grey3: #777777;
$grey4: #EEEEEE;
$grey5: #DDDDDD;

$photoAddImage: "../../../content/images/photoAdd.png";

// ---- Standard Colour Variables ---
$primary-color1: var(--green1);
$primary-color2: $green2;
$primary-color3: $green3;
$primary-color4: $green4;

$background-color: $white;
$foreground-color: $black;

$error-bg: #F4D7D4;
$error-border: $red1;
$saved-bg: $green4;
$saved-border: #AEDF5E;
$header-bg-color: $grey;
$actions-bg-color: $grey;

//--- Button Colors ---
$button-light-color: #3F3F3F;
$button-light-bg: $white;
$button-light-hover-bg: $grey;

$button-dark-color: $white;
$button-dark-bg: $darkgrey;
$button-dark-hover-bg: $black;
$button-border-color: #BFBFBF;

$progress-button-color: $black1;
$progress-button-border: #A7A7A7;
$progress-button-bg: #D7D7D7;
$progress-button-green: #94D600;
$progress-button-red: $red1;
$progress-button-blue: $blue1;
$progress-button-yellow: $yellow1;

// --- Grid Colors ---
$grid-header-bg: #D8D8D8;
$grid-border: #BFBFBF;
$grid-alt-row-bg: $grey;
$grid-row-bg: $white;
$grid-footer-bg: $grey;
$grid-hover-row-bg: #E8E8E8;
$grid-selected-row-bg: $black;
$grid-selected-hover-row-bg: $darkgrey;
$grid-text: #3F3F3F;
$grid-header-text: $darkgrey;
$grid-pagination-border-color: #D8D8D8;
$grid-pagination-selected-bg: #595959;
$grid-pagination-selected-border: $darkgrey;
$grid-pagination-label-color: #7F7F7F;
$grid-pagination-button-fg: #595959;
$grid-pagination-button-selected-fg: $white;


//--- Schematic Colors --- 

$schematic-bg: #F6F9FF;
$schematic-border: #BFBFBF;
$schematic-green: green;
$schematic-blue: blue;
$schematic-red: red;
$schematic-grey: $grey2;
$schematic-yellow: #EEDC00;
$schematic-tyre-fill: $grey4;
$schematic-tyre-stroke: #DDDDDD;
$schematic-axle-text: #666666;
$schematic-wheel-position-stroke: $grey4;

$schematic-setlabel-stroke: black;
$schematic-setlabel-fill: white;

$schematic-fitted-text: #337AB7;
$schematic-fitted-fill: $schematic-yellow; // RIM color
$schematic-fitted-border: #BFBFBF; // RIM color 
$schematic-wheel-position-empty-fill: none;

$schematic-fs-stroke: $schematic-border;
$schematic-fs-fill: $black;
$schematic-fs-stroke-center: $schematic-border;
$schematic-fs-text: $white;
$schematic-fs-text-stroke: $schematic-border;

$schematic-fs-text-size: 50px;
$schematic-fs-text-size2: 50px;
$schematic-fs-text-size3: 50px;
$schematic-fs-text-size4: 46px;
$schematic-fs-text-size5: 42px;

$schematic-fs-selected: #55C1E9;
$schematic-fs-tyre: $black;

//--- Reading Type Colors ---
$reading-hours-color: $schematic-blue;
$reading-distance-color: $schematic-green;
$reading-speed-color: $brown;
$reading-treaddepth-color: $schematic-red;
$reading-pressure-color: $schematic-yellow;
$reading-temp-color:$orange;
$reading-ndt-color:$orange;
$reading-torque-color:$purple;

$reading-stroke-width: 7;

//--- Status Type Colors --- 
$status-fitted-color: $schematic-green;
$status-production-color: $schematic-green;
$status-maintenance-color: $orange;
$status-new-color: $grey3;
$status-spare-color: $grey3;
$status-inspection-color: $orange;
$status-scrapped-color: $schematic-red;
$status-disposed-color: $schematic-red;

//--- Wizard Colors ---
$wizard-step-color: $darkgrey;
$wizard-step-bg-color: $white;
$wizard-step-color-active: $darkgrey;
$wizard-step-bg-color-active: $grey;
$wizard-step-color-success: #5CB85C;
$wizard-step-bg-color-success: #DFF0D8;
$wizard-step-color-error: #AB343E;
$wizard-step-bg-color-error: #EEC4C6;
$wizard-step-color-disabled: $grey3;
$wizard-step-bg-color-disabled: $grey4;

$error-panel-green: var(--green1);
$error-panel-red: $red1;

$notification-success-color: $green4;

$link-color: $blue;


//--- Tab Colors ---
$tab-border: #D8D8D8;
$tab-selected-color: #333333;
$tab-color: #548BD4;
$tab-background-hover: $grey;

//--- Sizes ---
$input-number-height: 30px;
$reading-grid-line-height: 40px;
$reading-grid-line-height-mobile: 40px;
$large-font-size: 1.5em;
$large-font-size-root: 1.375rem;
$large-control-height:50px;
$medium-font-size: 1rem;
$medium-control-height: 40px;

$status-change-header-height: 70px;
$status-change-details-footer-height: 60px;

//--- Mixins ---
@mixin flex-properties($property) {
    display: flex;
    flex-direction: $property;
    flex: 1 0 0px;
}