@import "../base/glyphicon_mixins";
@import "../base/variables";


amt-wizard .wizard-step-title {
    margin: 0 14px 0 20px;
}

amt-wizard .list-group-item.active::after {
    @include glyphicon-record;
    position: absolute;
    top: calc(50% - .5em);
}

html.desktop .list-group-item.active::after {
    left: 20px;
}

amt-wizard .list-group-item.success::after {
    @include glyphicon-ok;
    position: absolute;
    top: calc(50% - .5em);
    right: 13px;
}

amt-wizard .list-group-item.invalid:not(.disabled)::after {
    @include glyphicon-remove;
    position: absolute;
    top: calc(50% - .5em);
    right: 13px;
}

@media (orientation: portrait) {
    html.mobile {
        .amt-wizard {
            flex-direction: column;
        }

        .amt-wizard-steps {
            width: 100%;
        }

        .amt-wizard-steps ul.list-group {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        amt-wizard .wizard-step-title {
            margin: 0 22px;
        }

        .amt-wizard-steps .list-group-item {
            border-radius: 0;
            height: 100%;
            padding: 8px;
            min-width: 0;
            text-overflow: ellipsis;
            overflow-x: hidden;
            flex: 1 1 0px
        }

        .amt-wizard-steps .list-group-item:first-child {
            border-radius: 12px 0 0 12px;
            margin: 0;
        }
        /* Override bootstrap.css and list-group.less to give rounding client wants */
        .amt-wizard-steps .list-group-item:last-child {
            border-radius: 0 12px 12px 0;
        }

        component-receive,
        vehicle-receive {
            text-overflow: ellipsis;
        }

        .receive-wiz-step-2 button {
            width: 100%;
        }

        .list-group-item.active::after {
            left: 16px;
        }
    }
}

amt-wizard .list-group {
    margin: 0;
}

html.desktop amt-wizard {
    margin-right: 10px;
}

html.mobile amt-wizard .list-group-item.active .wizard-step-title {
    margin-left: 28px;
    margin-right: 0;
}

//previous button to the left...
html.mobile amt-wizard .amt-footer .btn-default {
    float: left;
}

.receive-wiz-step-1 button,
.receive-wiz-step-2 button {
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

html.desktop /*component-receive*/ .amt-wizard-steps .list-group-item {
    padding: 20px 30px;
}

html.desktop component-receive {
    overflow: hidden;
}

component-receive amt-wizard amt-wizard-additional-buttons .btn-default,
vehicle-receive amt-wizard amt-wizard-additional-buttons .btn-default {
    float: right !important; //hack
    margin-left: 10px;
}

component-receive .component-grid {
    margin-top: 10px;
}

.shared-attachments .attachmentControl {
    margin-bottom: 5px;
}

html.mobile .shared-attachments span {
    font-size: $large-font-size-root;
}
