@import "variables";

html.desktop {
    overflow: hidden
}

html.mobile {
    font-size: 16px;

    @media only screen and (max-width : 992px) {
        font-size: 14px;
    }

    @media only screen and (max-width : 768px) {
        font-size: 12px;
    }

    @media only screen and (max-width : 480px) {
        font-size: 8px;
    }
}

a {
    color: $link-color;
}

cursor-default {
    cursor: default;
}

.client-logo {
    margin: 0 auto;
    width: 200px;
    display: block;
    margin-bottom: 15px;
}

.transparent {
    opacity: 0.3 !important;
    cursor: default;
    display: block;
    pointer-events: none; /* no clicking allowed */
}

.bar {
    width: 100%;
    height: 4px;
    margin-bottom: 3px;
    border-bottom: 1px solid $black2;
}

/* 
    this fixes the delay with the lagginess with the butterBar (uiButterbar.js)
    http://stackoverflow.com/questions/26938021/angular-ng-if-or-ng-show-responds-slow-2second-delay 

*/
.ng-hide.ng-hide-animate {
    display: none !important;
}

.bg-highlight {
    background-color: $blue4
}

.k-alt.bg-danger {
    background-color: $red3
}

.bg-current-danger {
    background-color: $red2
}

/* Override progress button */
.btn-default.progress-button {
    background-image: none;
    padding: 5px 15px 5px 15px;
    font-size: 20px;
}

/* Transparent Overlay */
.processing,
.soft-processing {
    content: '';
    display: block;
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

.processing {
    z-index: 1500;
    box-sizing: border-box;
}

/* Transparent Overlay */
.soft-processing {
    content: '';
    display: block;
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
}

/*  the processing spinner */
.soft-processing div,
.processing div {
    position: absolute;
    top: 30%;
    left: 50%;
    height: 60px;
    width: 60px;
    margin-left: -30px;
    padding: 5px;
    color: $black;
    background: transparent;
    background-image: url("/Content/common/spinner.gif");
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: center;
    border: none;
    box-sizing: border-box;
}


.over {
    border: 1px solid $red1;
}


*:focus { /* csslint allow: universal-selector, outline-none */
    outline: 0 !important;
}

[ng-click],
[data-ng-click],
[x-ng-click],
a {
    text-decoration: none;
    cursor: pointer;
}

[ng-click].no-click,
[data-ng-click].no-click,
[x-ng-click].no-click,
a.no-click {
    cursor: default;
}

.oc-property-row-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}


a:hover,
a:focus {
    color: $black;
}

small,
.small {
    font-size: 13px;
}

.badge,
.label {
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(0,0,0,0.2);
}

.badge.bg-light,
.label.bg-light {
    text-shadow: none;
}

.badge {
    background-color: $badge-color;
}

.badge.up {
    position: relative;
    top: -10px;
    padding: 3px 6px;
    margin-left: -10px;
}

.badge-sm {
    font-size: 85%;
    padding: 2px 5px !important;
}

.label-sm {
    padding-top: 0;
    padding-bottom: 1px;
}

blockquote {
    border-color: $black4;
}

.caret-white {
    border-top-color: $white;
}

a:hover .caret-white {
    border-top-color: $white;
}

.thumbnail {
    border-color: $black4;
}

.progress {
    background-color: $off-white;
}

.progress-xs {
    height: 6px;
}

.progress-sm {
    height: 12px;
}

.progress-sm .progress-bar {
    font-size: 10px;
    line-height: 1em;
}

.progress,
.progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-bar-primary {
    background-color: #231F20;
}

.progress-bar-success {
    background-color: #9BBB59;
}

.progress-bar-warning {
    background-color: #fad733;
}

.progress-bar-danger {
    background-color: #E91D25;
}

.receive_summary_row_even {
    background-color: #FFFFFF;
}

.receive_summary_row_odd {
    background-color: #F2F2F2;
}

.accordion-group,
.accordion-inner {
    border-color: $black4;
    border-radius: 2px;
}

.alert {
    font-size: 13px;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
}

.alert .close i {
    font-size: 12px;
    font-weight: normal;
    display: block;
}

.tooltip {
    z-index: 999999;
}

.attachment-list {
    padding-left: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

oc-attachments.ng-invalid > .attachment-list {
    border: 1px $error-border solid !important;
    background: $error-bg;
    border-radius: 5px;
}

.attachment-grid-add-button {
    padding-bottom: 10px;
    margin-left: 0px;
}

.attachment-grid-footer {
    border-bottom: 1px solid #ddd;
    padding-top: 5px;
    margin-left: 0px;
}

.attachment-grid-header {
    font-weight: bold;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0px;
    margin-right: 4px !important;
}

.attachment-grid-row {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0px;
    padding-right: 2px;
}

.attachment-grid-row.even {
    background-color: #f3f3f3;
}

input.hidden-attachment {
    visibility: hidden;
}

.filtered-menus > ul {
    height: auto !important;
    opacity: 1;
}

.force-uppercase {
    text-transform: uppercase;
}

.no-wrap {
    white-space: nowrap;
}

.no-cost-access {
    display: none;
}

hr {
    margin-top: 0.5em; /* bootstrap overrides this to hard-coded 20px, reset to default. */
    margin-bottom: 0.5em; /* bootstrap overrides this to hard-coded 20px, reset to default. */
}

.dynamic-grid-header {
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    margin: 0px !important;
}

.dynamic-grid-row {
    width: 100%;
    margin: 0px !important;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
}

.dynamic-grid-row.even {
    background-color: $grid-alt-row-bg;
}

.dynamic-grid-row.odd {
    background-color: $grid-row-bg;
}

.dynamic-grid-col {
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
}

.no-border-top {
    border-top: 0px !important;
}

.row-even {
    background-color: $grid-row-bg;
}

.row-odd {
    background: $grey;
}

html.mobile .drop-indicator {
    top: 22px;
}

html.mobile .not-implemented {
    color: #ddd;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

html.mobile .bg-black a {
    color: #FFF;
}

html.mobile .bg-black .nav > li > a {
    color: #FFF;
}

html.mobile .navbar .navbar-toggle .icon-bar {
    background-color: white;
}

html.mobile .navbar {
    font-size: 20px;
}

html.mobile .app-content {
    margin-left: 0 !important;
}

html.mobile .view-mobile-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

html.mobile .full-screen .child-window-container {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    right: 3px;
    bottom: 3px;
    top: 3px; /* with room for thead butterbar */
    left: 3px;
    background-color: #fcfcfc;
    border-radius: 0px;
    box-shadow: 0px;
}

html.mobile .mobile-menu {
    position: absolute;
    top: 50px;
    right: 25px;
    z-index: 100;
    background-color: black;
    color: white;
}

html.mobile .mobile-menu-item {
    border: 1px solid gray;

    .mobile-menu-item-icon {
        width: 24px;
        text-align: center;
    }

    td {
        padding: 10px;
    }
}

html.mobile .mobile-status-dropdown {
    width: 170px !important;
    display: inline;
    float: left;
    margin-right: 0px;
}

.module-chooser-button {
    height: 70px;
    background-color: #0D0C0C;
    color: #FFF !important;
    line-height: 60px;
}

html.mobile .navbar-collapse.collapse {
    display: block !important;
}

html.mobile .remove-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 550px;
}

/* Set overflow on modal dialog bg for mobile - this is because on Android devices
    the soft keyboard resizes the height of the page to make room . If there is a window displayed, 
    it tries to shrink the window which causes issues.
    Adding overflow to the modal bg  keeps the window height fixed and allows the user
    to still see the form and scroll whilst the soft keyboard is open. 
    Note. IOS doesnt have this issue because it overlays the keyboard
*/
html.mobile .modalDialog {
    overflow-y: auto;
}

html.mobile .horizontal-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

html.mobile .spacer {
    flex: auto;
    width: 50px;
}

html.mobile .fixed-size {
    flex: none;
    width: 6cm;
    text-align: center;
    margin: 10px;
}

html.mobile .well {
    background-color: $off-white;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border: 3px;
    border-radius: 6px;
}

html.mobile input[type=checkbox] {
    min-width: 40px;
    min-height: 40px;
}

html.mobile .checkbox-inline,
html.mobile .checkbox {
    line-height: 40px;
}


html.mobile input[type=checkbox][disabled] {
    box-sizing: border-box;
    border: 1px solid rgb(51, 51, 51);
    outline: 1px solid transparent; //Added otherwise checkbox border doesnt display properly on IOS
}

html.mobile input[type=text],
html.mobile input[type=number],
html.mobile input[type=password],
html.mobile input[type=email] {
    height: $large-font-size-root * 2;
    font-size: $large-font-size-root;
}

html.mobile {
    .text,
    .text-label,
    .large-text,
    .checkbox-label,
    .k-notification-wrap,
    .k-icon {
        font-size: $large-font-size;
    }
}

html.mobile .form-group .control-label {
    font-size: $large-font-size-root;
}

html.mobile .form-control-static {
    font-size: $large-font-size;
}

html.mobile .text {
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

html.mobile .subtext {
    font-size: xx-small;
}

html.mobile .support-text {
    font-size: $large-font-size;
    max-height: 550px;
}

html.mobile .support-highlight {
    font-size: $large-font-size-root;
}


html.mobile .multiSelect > button {
    height: 50px;
}

html.mobile .combobox .message {
    font-size: $large-font-size;
}

html.mobile textarea {
    font-size: $large-font-size-root;
}

.glyphicon-green {
    color: $green;
}

.glyphicon-red {
    color: $red;
}

// iPad specific
@supports (-webkit-overflow-scrolling: touch) {

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
        margin: 1px;
        background-color: $grey2;
        border: 1px solid $grey3;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $grey;
        border: 1px solid $grey3;
    }
}