/*MultiFieldPicker*/
.multi-field-picker-box {
    /*position: absolute;*/
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1000;
    min-width: 100%;
}

.multi-field-picker-box > li {
    height: 100%;
    width: 100%;
}

.multi-field-picker-box .has-no-data {
    line-height: 50px;
}

.multi-field-picker-box .modal-footer {
    padding: 10px 0 0 0;
}

.amt-form-control .multi-field-picker-box {
    width: auto;
}

.held-open > .dropdown-menu {
    display: block;
}

.fixed-table-container {
    height: 265px;
    border: 1px rgb(204, 204, 204) solid;
    border-radius: 4px;
    background-color: white;
    /* above is decorative or flexible */
    position: relative; /* could be absolute or relative */
    box-sizing: border-box;
}

    .fixed-table-container .fixed-table-container-inner {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
    }

    .fixed-table-container .header-background {
        background-color: #F3F3F3;
        height: 60px; /* height of header */
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    .fixed-table-container table {
        background-color: white;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
    }

    .fixed-table-container .th-inner-label {
        background-color: #F3F3F3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
    }

    .fixed-table-container th {
        background-color: #F3F3F3;
    }

    .fixed-table-container .th-inner-label > label {
        background-color: #F3F3F3;
    }

    .fixed-table-container th:first-of-type .th-inner-label,
    .fixed-table-container th:first-of-type .th-inner-input {
        border: 0;
    }

    .fixed-table-container .th-inner-input {
        background-color: #F3F3F3;
    }

.fixed-table-container .th-inner-input .checkbox {
    background-color: #F3F3F3;
    margin: 0 0 9px 0;
    border: 1px solid #ccc;
    height: 25px;
}

        .fixed-table-container .th-inner-input input {
            box-shadow: none;
            outline: none;
        }

    .fixed-table-container td {
        border-bottom: 1px rgb(204, 204, 204) solid;
        padding: 5px;
        text-align: left;
    }

        .fixed-table-container td + td {
            border-left: 1px rgb(204, 204, 204) solid;
        }

    .fixed-table-container th {
        text-align: left;
    }

    .fixed-table-container tbody tr:hover {
        color: white;
        background-color: #428bca;
        cursor: pointer;
    }

.multi-field-picker .busy-indicator {
    position: absolute;
    top: 75px;
    left: 5px;
    right: 5px;
    height: 190px;
}

.multi-field-picker-box [cg-busy] > .cg-busy {
    top: 60px;
}

.multi-field-picker-checkbox {
    width: 25px;
    margin-left: auto !important;
    margin-right: auto !important;
}

amt-multi-field-picker tr.highlightedRow {
    background-color: #F5EA66;
}

amt-multi-field-picker .multi-field-picker-tablelist {
    word-break: break-word;
}
