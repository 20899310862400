@import "../base/variables";

/*offset the textbox text to match the other controls*/
input[type="text"] {
    padding-left: 10px;
}

textarea {
    border: 1px solid $grey2;
    padding-left: 10px;
    padding-top: 6px;
}

/* added border radius to make the standard input textboxes look like the amt input controls. 
    might need to move this into the framework css file, to prevent it overriding other more targeted css */
input {
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.checkbox-label {
    font-weight: normal;
}

input.error-highlight {
    border: 1px solid $validation-red;
}


[type="checkbox"] {
    margin-top: 10px !important;
}

[type="text"], [type="password"] {
    height: 34px;
    border: 1px solid $grey2;
    box-sizing: border-box;
}

[type="number"] {
    min-height: $input-number-height;
    padding: 2px 0 2px 10px;
    border: 1px solid $grey2;
    text-align: right;
    box-sizing: border-box;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* to stop the above type=number showing a box.*/
amt-grid-column {
    display: none;
}

.time-picker {
    padding: 0px;
    height: 30px;
}

.time-picker-input {
    border: none !important;
    text-align: right;
    width: 40px;
    height: 27px;
    padding: 5px;
}

oc-thumbnail canvas {
    vertical-align: middle;
}

oc-property-row [type="checkbox"] {
    margin-top: 5px !important;
}

oc-date-time-picker.ng-invalid > table > tbody > tr > td > div > .date-time-picker,
oc-time-picker.ng-invalid > table > tbody > tr > td > .time-picker {
    border: 1px #a94442 solid !important;
}

.date-time-picker #amtdatepickerdiv > input,
.date-time-picker #amttimepickerdiv > input {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.date-time-picker #amtdatepickerdiv > span,
.date-time-picker #amttimepickerdiv > span {
    border: none;
}

.date-time-picker-disabled {
    background-color: #eaeaea;
}

.date-time-picker-input {
    border: none !important;
    text-align: right;
    width: 34px;
    height: 27px;
    padding: 5px;
}

.datepicker {
    margin: 0;
}

.datepicker .btn-default {
    border-width: 0;
    border-width: 1px;
    box-shadow: none;
}

.datepicker .btn[disabled] {
    opacity: 0.4;
}

.datepicker .btn-info .text-info {
    color: $white !important;
}

.datepicker input, .k-widget.k-datepicker.k-header input, .k-datetimepicker input {
    text-align: left;
}


.multiSelect {
    margin-right: 1.9em;
}

.multiSelect .k-dropdown-wrap {
    width: 100%;
}

.multiSelect .btn-group {
    width: 100%;
}

.multiSelect .k-widget.k-combobox.k-header {
    width: 100%;
}

.multiSelect .k-widget.k-combobox.k-header input {
    text-overflow: ellipsis;
}

textarea {
    resize: none;
}

.form-radio-group label {
    margin-right: 10px !important;
}

.form-radio-group input[type=radio],
.form-radio-group input[type=checkbox] {
    margin-right: 5px;
}

input[type=checkbox] {
    margin-right: 5px;
}

.textarea-no-resize {
    resize: none;
}

.radio-label {
    width: 90%;
    vertical-align: top;
    display: inline-block;
    line-height: 20px;
}

input[type="radio"] {
    margin: 4px 4px 0 0;
}


oc-date-time-picker .form-control {
    padding: 0;
    height: 38px;
}

oc-date-time-picker .btn {
    width: 100%;
    height: 38px;
}

.readonly-field[disabled], .readonly-field[readonly] {
    background-color: #eee;
    opacity: 1;
}

html.mobile .time-picker {
    height: $large-control-height;
}

oc-time-picker input {
    background: transparent;
}

html.mobile oc-time-picker {
    font-size: $large-font-size;
    height: $large-control-height;

    .time-picker {
        height: $large-control-height;
    }

    amt-date-picker {
        font-size: $large-font-size;
        height: unset;
    }

    [type="text"] {
        height: 100% !important;
    }

    .time-picker > table {
        height: 100%;
    }

    .time-picker-input {
        width: 50px !important;
        font-size: $large-font-size;
        height: 46px;
    }
}


html.mobile .amt-datepicker-large {
    height: $large-control-height;

    .k-datepicker {
        height: 48px;
    }

    .k-datepicker .k-picker-wrap {
        height: 46px;
    }

    .k-datepicker .k-picker-wrap .k-input {
        height: 46px;
    }

    .k-datepicker .k-select .k-icon {
        margin-top: 15px;
    }
}

html.mobile .timepicker .form-control {
    height: $large-control-height;
}

html.mobile .oc-date-time-picker {
    .form-control {
        padding: 0;
        height: $large-control-height;
    }

    .btn {
        width: 100%;
        height: $large-control-height;
        font-size: $large-font-size-root;
        line-height: 34px;
    }


    amt-time-picker,
    amt-time-picker div,
    amt-time-picker div input,
    .timepicker,
    .date-time-picker .k-picker-wrap.k-state-hover .k-input,
    .date-time-picker .k-picker-wrap.k-state-default,
    .date-time-picker input {
        height: 100%;
    }

    .date-time-picker input {
        font-size: $large-font-size;
    }

    .date-time-picker .k-picker-wrap.k-state-hover .k-input,
    .k-picker-wrap.k-state-default .k-input {
        height: 46px !important;
        border: none;
        box-shadow: none;
        font-size: $large-font-size;
    }

    .date-time-picker .k-picker-wrap .k-select {
        height: 46px;
        width: $large-control-height;
        display: flex;
        justify-content: center;
        border: none;
    }

    .date-time-picker .k-picker-wrap .k-select .k-icon {
        font-size: $large-font-size;
        margin: auto;
    }

    amt-time-picker div span i {
        font-size: $large-font-size;
        width: unset !important;
        height: unset !important;
    }

    amt-time-picker div span.k-picker-wrap {
        box-shadow: none !important;
        border: none !important;
    }
}

html.mobile oc-comment {
    font-size: $large-font-size;
}

html.mobile oc-comment > div {
    font-size: $large-font-size-root;
}

html.mobile .remaining-char-label {
    font-size: $large-font-size-root;
}

input.ocFilePickerShowAsIcon,
input.ocFilePickerShowAsIcon:hover,
input.ocFilePickerShowAsIcon:focus,
input.ocFilePickerShowAsIcon:active {
    background: url($photoAddImage) no-repeat center;
    cursor: pointer;
    width: 60px;
    height: 44px;
    border: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-indent: -9999px;
}

.reading-input {
    display: inline-flex;
    align-items: center;
}

.reading-input input {
    width: auto;
}

.reading-input span {
    font-weight: bold;
}

.reading-input-unit span {
    margin-left: 10px;
}

.reading-input-unit amt-combobox {
    margin-left: 10px;
}

html.mobile .reading-input-unit span {
    font-size: $large-font-size-root !important;
}

.reading-input-new-meter [type="checkbox"] {
    margin: 0px 0px 0px 10px !important;
}

.reading-input-new-meter span {
    font-size: 1rem !important;
    margin-left: 5px;
}

html.mobile .reading-input-new-meter input {
    width: calc(100% / 3);
}

@media (orientation: portrait) {
    html.mobile .reading-input-new-meter span {
        font-size: $large-font-size-root !important;
    }
}

.performance-batch-warranty-input {
    width: 40%;
}