bulk-move amt-wizard,
bulk-move amt-grid,
bulk-move .flex-grid .amt-grid,
bulk-move .k-grid-content {
    flex: 1 0 0px;
    display: flex;
}

bulk-move .flex-grid .amt-grid {
    flex-direction: column;
}

bulk-move .row:not(.attachment-grid-header) {
    margin: 0px !important;
}

bulk-move {
    height: 100%;
}

bulk-move > ng-form {
    height: inherit;
}