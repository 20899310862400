amt-multi-select .dropdown-menu {
    min-width: 10%; /* This might need tweaking. "AUTO" is not a valid value. */
    width: 100%;
    font-size: 1rem;
}

.multi-select-box {
    min-width: 503px !important;
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1000;
    margin-bottom: 15px;
    width: auto !important;
}

.multi-select-box > li > div {
    display: block;
    overflow: auto;
}

.multi-select-box .optionsContainer, .multi-select-box .selectedContainer {
    float: left;
    width: 43%;
    text-align: center;
    margin-top: 0;
}

.multi-select-box .optionsContainer select, .multi-select-box .selectedContainer select {
    margin-top: -1px;
	padding-top: 2px;
	min-width: 215px;
}

.multi-select-box .buttonsContainer {
    float: left;
    width: 14%;
    text-align: center;
    height: 30px;
    overflow: hidden;
}

.multi-select-box .buttons-inner-container {
    width: 73px;
    position: absolute;
    height: 135px;
    padding-top: 15px;
    box-sizing: border-box;
}
.multi-select-box label {
    margin-top: 0;
}

.multi-select-box .btn {
    margin: 3px;
}

.multi-select-box select {
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
	overflow: hidden;
	border: none;
}

.multi-select-box .filter {
    width: 100%;
    height: 31px;
    box-sizing: border-box;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
	border-bottom: none;
}

.multi-select-box .filter.placeholder {
    color: #aaa;
}

    
.multi-select-box .modal-footer {
    padding: 10px 0 0 0;
}

.amt-filter-multiselect-add, .amt-filter-multiselect-remove {
    margin: 5px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}

.amt-filter-multiselect-close {
    box-shadow: none !important;
    position: absolute;
    right: 10px;
}

.amtfiltermultiselectselected {
    background-color: black;
    color: white;
}
.amtfiltermultiselectnotselected {
    background-color: none;
    color: black;
}
