/* Customisations to amt-theme-bootstrap */

.navbar form {
    margin-bottom: 0 !important;
}

.btn-primary {
    -moz-min-width: 110px;
    -ms-min-width: 110px;
    -o-min-width: 110px;
    -webkit-min-width: 110px;
    min-width: 110px;
}

.modal-footer .btn-primary {
    -moz-min-width: 44px;
    -ms-min-width: 44px;
    -o-min-width: 44px;
    -webkit-min-width: 44px;
    min-width: 44px;
}

.btn.btn-amt {
    min-width: 110px;
}

.form-horizontal .form-control-static {
    word-wrap: break-word;
}

/*Panel: removes a lot of clutter */
.panel {
    border: 0;
}

.panel-body {
    padding: 0;
}

/* Tooltip */
.tooltip-inner {
    word-wrap: break-word;
    white-space: normal;
}

label.radio{
    line-height:20px;
}
