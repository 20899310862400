.k-filter-menu .k-button {
    float: right;
}

    .k-filter-menu .k-button.k-primary {
        margin-right: 0;
        margin-left: 4%;
        color: #333;
        border-color: #ccc;
        background-color: #fff;
    }

        .k-filter-menu .k-button.k-primary:hover {
            color: #333;
            border-color: #adadad;
            background-color: #ebebeb;
        }

.k-numeric-wrap {
    padding-right: 35px;
}

    .k-numeric-wrap.k-state-hover {
        background: #ffffff;
    }

    .k-numeric-wrap .k-input {
        text-align: right;
    }

.k-numeric-col {
    text-align: right;
}

.k-command-col {
    text-align: center;
}

    .k-command-col .k-grid-edit,
    .k-command-col .k-grid-update,
    .k-command-col .k-grid-remove,
    .k-command-col .k-grid-cancel {
        position: relative;
    }

    .k-command-col .k-grid-update {
        left: 38%;
    }

    .k-command-col .k-grid-cancel {
        right: 38%;
    }

    .k-command-col .k-button .k-icon {
        width: 0;
        height: 0;
    }


/*selected row on grid*/
.k-state-selected, .k-state-selected:link, .k-state-selected:visited, .k-list > .k-state-selected, .k-list > .k-state-highlight, .k-panel > .k-state-selected, .k-ghost-splitbar-vertical, .k-ghost-splitbar-horizontal, .k-draghandle.k-state-selected:hover, .k-scheduler .k-scheduler-toolbar .k-state-selected, .k-scheduler .k-today.k-state-selected, .k-marquee-color {
    background-color: #454344;
}

td.k-today.k-state-selected {
    background-color: #000 !important;
}

/* OR-10242 - don't hightlight kendo dropwdowns when focused */
.k-dropdown-wrap.k-state-default.k-state-focused {
    border-color: #ddd !important;
    box-shadow: none;
}

td.k-state-focused.k-state-selected {
    color: #fff !important;
    background-color: #000;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.k-pager-wrap {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.k-header{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
