@import "variables";
@import "_variables.scss";

/* Most of these styles override the padding and margin to be 0.
    This is due to issues with text in controls being cut off on
    ios devices
*/


.k-card-header .k-card-title {
    font-weight: 600;
    font-size: 15px;
}
.auto-complete-control {
    width: 100%;
    margin: 0px !important; 
    padding: 0px !important; 
}

.k-pager-numbers .k-state-selected {
    padding: 4px;
}
.kendo-combo-box-control {
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
}


.k-layout-item-hint {
    background-color: $green1;
    opacity: 0.5;
}

.k-cursor-grab {
    cursor: grab;
}


.k-touch-action-none {
    touch-action: none !important;
}

.k-touch-action-auto {
    touch-action: auto !important;
}

.k-cursor-grab {
    cursor: grab;
}

.k-cursor-grabbing {
    cursor: grabbing;
}


/* Fixes style issues with safari and chrome on ios*/
.k-autocomplete.k-state-default .k-input {
    height: 30px;
}

.k-state-default > .k-select {
    min-height:0;
    line-height: 2.0em;
}

.k-numeric-wrap.k-state-default .k-input {
    padding: 0px !important;
    margin: 0px !important;
}

.k-picker-wrap.k-state-default .k-input {
    height: 30px;
    margin: 0px !important;
    padding: 0px !important;
}

.k-icon.k-i-sort-asc-sm {
    color: $orange2;
}

.k-icon.k-i-sort-desc-sm {
    color: $orange2;
}

.k-icon.k-i-chevron-left {
    color: $orange2;
}
.k-icon.k-i-chevron-right {
    color: $orange2;
}

.k-state-hover > .k-select {
    min-height: 30px;
}

.k-dropdown-wrap .k-select, .k-picker-wrap .k-select, .k-numeric-wrap .k-select, .k-datetimepicker .k-select + .k-select, .k-list-container.k-state-border-right {
    min-height: 30px;
}

.k-dialog-titlebar {
    background-color: $notification-success-color;
    color: black;
}
//TODO: why aren't these Otracom colours?
.k-notification.k-notification-info {
    background-color: #e5f5fa;
    color: #0487c4;
    border-color: #b7e1f0;
}

.k-notification.k-notification-success {
    background-color: $notification-success-color;
    color: $black;
    border-color: $notification-success-color;
}

.k-icon {
    margin-right:5px;
}

html.mobile .k-grid td {
    white-space: normal; //Allow grid cell wrapping on mobile
}

//Kendo auto complete control
html.mobile {
    .k-autocomplete .k-input {
        height: 48px;
    }

    .k-widget.k-autocomplete {
        height: $large-control-height;
    }

    .k-autocomplete.k-state-default .k-input {
        height: 48px;
    }
}

//Kendo auto complete control item
html.mobile .k-listbox .k-item,
html.mobile .k-popup .k-list .k-item {
    font-size: 1.5rem;
    padding-bottom: 15px;
}

html.mobile .large-grid {

    .k-grid tr td {
        font-size: $large-font-size;
        padding: 5px;
    }


    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $large-font-size;
    }

    .k-filter-row th, .k-grid-header th.k-header {
        padding: 10px 0px 10px 0px !important;
    }

    .k-grid-header th.k-header, .k-filter-row th {
        font-size: $large-font-size;
    }

    .row-vertical-space {
        margin-bottom: 5px;
    }

    .k-pager-info {
        font-size: $large-font-size;
        margin-top: 5px;
    }

    .k-pager-sizes {
        font-size: $large-font-size;
    }

    .k-dropdown-wrap.k-state-default, .k-pager-wrap .k-link {
        height: 40px;
        font-size: $large-font-size-root;
    }

    .k-pager-sizes .k-dropdown-wrap .k-input {
        padding: 0px;
    }

    .k-widget.k-grid .k-pager-numbers {
        left: 7.6em;
    }

    .k-icon:before {
        height: 40px;
    }

    .k-grid .k-state-selected {
        font-size: $large-font-size;
    }

    .k-listbox .k-item, .k-popup .k-list .k-item {
        font-size: $large-font-size-root !important;
    }
}
.k-i-arrow-s::before {
    content: "\e006";
}

html.mobile .kendo-combo-box-control {
    margin-right: 10px !important;

    .k-dropdown-wrap {
        border: 0px;
        height: 48px;
    }

    .k-dropdown-wrap .k-input {
        height: 48px;
    }

    .k-dropdown-wrap .k-select {
        height: 48px;
    }

    .k-dropdown-wrap .k-select .k-icon {
        font-size: $large-font-size;
        height: 100%;
    }
}

a {
    color: #548BD4;
    text-decoration: none;
}

.k-grid a {
    color: #548BD4;
    text-decoration: none;
}

.k-icon.k-i-loading::before,
.k-icon.k-i-loading::after {
    content: "";
}

.k-icon {
    width: 1em;
    height: 1em;
    outline: 0;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    text-decoration: none;
    flex-shrink: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.k-icon:hover, .k-icon:focus {
    text-decoration: none;
}

.k-svg-icon {
    width: 1em;
    height: 1em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}

.k-svg-icon > svg {
    fill: currentColor;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}
.k-icon-with-modifier {
    position: relative;
    margin: .25em;
}

.k-header {
    justify-content: space-evenly;


}
.k-widget.k-calendar .k-nav-fast {
    width: 70%;
}
.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next {
    position: relative;
    top: unset;
    height: unset;
}

.k-icon-modifier {
    position: absolute;
    font-size: .5em;
    bottom: 0;
    right: 0;
    margin: 0 -.5em -.5em 0;
}

.k-i-none::before {
    content: "";
    display: none;
}

.k-widget .k-icon.k-i-expand, .k-widget .k-icon.k-i-collapse {
    cursor: pointer;
}

.k-icon-xs {
    font-size: 8px;
}

.k-icon-sm {
    font-size: 12px;
}

.k-icon-md {
    font-size: 32px;
}

.k-icon-lg {
    font-size: 48px;
}

.k-icon-xl {
    font-size: 64px;
}

.k-i-caret-tr::before {
    content: "\e000";
}

.k-i-arrow-45-up-right::before {
    content: "\e000";
}

.k-i-collapse-ne::before {
    content: "\e000";
}

.k-i-resize-ne::before {
    content: "\e000";
}

.k-i-caret-br::before {
    content: "\e001";
}

.k-i-arrow-45-down-right::before {
    content: "\e001";
}

.k-i-collapse-se::before {
    content: "\e001";
}

.k-i-resize-se::before {
    content: "\e001";
}

.k-i-caret-bl::before {
    content: "\e002";
}

.k-i-arrow-45-down-left::before {
    content: "\e002";
}

.k-i-collapse-sw::before {
    content: "\e002";
}

.k-i-resize-sw::before {
    content: "\e002";
}

.k-i-caret-tl::before {
    content: "\e003";
}

.k-i-arrow-45-up-left::before {
    content: "\e003";
}

.k-i-collapse-nw::before {
    content: "\e003";
}

.k-i-resize-nw::before {
    content: "\e003";
}

.k-i-caret-alt-up::before {
    content: "\e004";
}

.k-i-arrow-60-up::before {
    content: "\e004";
}

.k-i-arrow-n::before {
    content: "\e004";
}

.k-i-kpi-trend-increase::before {
    content: "\e004";
}

.k-i-expand-n::before {
    content: "\e004";
}

.k-i-sarrow-n::before {
    content: "\e004";
}

.k-i-caret-alt-right::before {
    content: "\e005";
}

.k-i-arrow-60-right::before {
    content: "\e005";
}

.k-i-arrow-e::before {
    content: "\e005";
}

.k-i-expand::before {
    content: "\e005";
}

.k-i-expand-e::before {
    content: "\e005";
}

.k-i-sarrow-e::before {
    content: "\e005";
}

.k-i-caret-alt-down::before {
    content: "\e006";
}

.k-i-arrow-60-down::before {
    content: "\e006";
}

.k-i-arrow-s::before {
    content: "\e006";
}

.k-i-kpi-trend-decrease::before {
    content: "\e006";
}

.k-i-expand-s::before {
    content: "\e006";
}

.k-i-collapse::before {
    content: "\e006";
}

.k-i-sarrow-s::before {
    content: "\e006";
}

.k-i-caret-alt-left::before {
    content: "\e007";
}

.k-i-arrow-60-left::before {
    content: "\e007";
}

.k-i-arrow-w::before {
    content: "\e007";
}

.k-i-expand-w::before {
    content: "\e007";
}

.k-i-sarrow-w::before {
    content: "\e007";
}

.k-i-caret-alt-to-top::before {
    content: "\e008";
}

.k-i-arrow-end-up::before {
    content: "\e008";
}

.k-i-seek-n::before {
    content: "\e008";
}

.k-i-caret-alt-to-right::before {
    content: "\e009";
}

.k-i-arrow-end-right::before {
    content: "\e009";
}

.k-i-seek-e::before {
    content: "\e009";
}

.k-i-caret-alt-to-bottom::before {
    content: "\e00a";
}

.k-i-arrow-end-down::before {
    content: "\e00a";
}

.k-i-seek-s::before {
    content: "\e00a";
}

.k-i-caret-alt-to-left::before {
    content: "\e00b";
}

.k-i-arrow-end-left::before {
    content: "\e00b";
}

.k-i-seek-w::before {
    content: "\e00b";
}

.k-i-caret-double-alt-up::before {
    content: "\e00c";
}

.k-i-arrow-double-60-up::before {
    content: "\e00c";
}

.k-i-arrow-seek-up::before {
    content: "\e00c";
}

.k-i-caret-double-alt-right::before {
    content: "\e00d";
}

.k-i-arrow-double-60-right::before {
    content: "\e00d";
}

.k-i-arrow-seek-right::before {
    content: "\e00d";
}

.k-i-forward-sm::before {
    content: "\e00d";
}

.k-i-caret-double-alt-down::before {
    content: "\e00e";
}

.k-i-arrow-double-60-down::before {
    content: "\e00e";
}

.k-i-arrow-seek-down::before {
    content: "\e00e";
}

.k-i-caret-double-alt-left::before {
    content: "\e00f";
}

.k-i-arrow-double-60-left::before {
    content: "\e00f";
}

.k-i-arrow-seek-left::before {
    content: "\e00f";
}

.k-i-rewind-sm::before {
    content: "\e00f";
}

.k-i-caret-alt-expand::before {
    content: "\e010";
}

.k-i-arrows-kpi::before {
    content: "\e010";
}

.k-i-kpi::before {
    content: "\e010";
}

.k-i-caret-alt-sort::before {
    content: "\e010";
}

.k-i-arrows-no-change::before {
    content: "\e011";
}

.k-i-arrow-overflow-down::before {
    content: "\e012";
}

.k-i-chevron-up::before {
    content: "\e013";
}

.k-i-chevron-double-up::before {
    content: "\e94a";
}

.k-i-arrow-chevron-up::before {
    content: "\e013";
}

.k-i-arrowhead-n::before {
    content: "\e013";
}

.k-i-chevron-right::before {
    content: "\e014";
}

.k-i-chevron-double-right::before {
    content: "\e94b";
}

.k-i-arrow-chevron-right::before {
    content: "\e014";
}

.k-i-arrowhead-e::before {
    content: "\e014";
}

.k-i-chevron-down::before {
    content: "\e015";
}

.k-i-chevron-double-down::before {
    content: "\e94c";
}

.k-i-arrow-chevron-down::before {
    content: "\e015";
}

.k-i-arrowhead-s::before {
    content: "\e015";
}

.k-i-chevron-left::before {
    content: "\e016";
}

.k-i-chevron-double-left::before {
    content: "\e94d";
}

.k-i-arrow-chevron-left::before {
    content: "\e016";
}

.k-i-arrowhead-w::before {
    content: "\e016";
}

.k-i-arrow-up::before {
    content: "\e017";
}

.k-i-arrow-right::before {
    content: "\e018";
}

.k-i-arrow-down::before {
    content: "\e019";
}

.k-i-arrow-left::before {
    content: "\e01a";
}

.k-i-level-down::before {
    content: "\e01b";
}

.k-i-arrow-drill::before {
    content: "\e01b";
}

.k-i-level-up::before {
    content: "\e01c";
}

.k-i-arrow-parent::before {
    content: "\e01c";
}

.k-i-level-to-top::before {
    content: "\e01d";
}

.k-i-arrow-root::before {
    content: "\e01d";
}

.k-i-level-root::before {
    content: "\e01d";
}

.k-i-col-resize::before {
    content: "\e01e";
}

.k-i-arrows-resizing::before {
    content: "\e01e";
}

.k-i-arrows-axes::before {
    content: "\e01f";
}

//Kendo-Dropdown List Item
.k-marquee-color {
    color: #ffffff;
    background-color: #000000;
    border-color: rgba(0, 0, 0, 0.08);
    opacity: .6;
}

//Kendo-Dropdown List Item
.k-marquee-text {
    color: #ffffff;
}

//Kendo-Dropdown List Item
.k-list-item:hover, .k-list-optionlabel:hover, .k-list-item.k-hover, .k-hover.k-list-optionlabel {
    color: #ffffff;
    background-color: #000000;
}

//Kendo-Dropdown List Item
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    color: black;
    background-color: $green1;
    font-weight: 500;
}

//Kendo-Dropdown List Item
.k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
    color: #ffffff;
    background-color: black;
}

.k-picker-wrap, .k-state-default {
    border: 1px rgb(204, 204, 204) solid;
}

//Kendo Listbox - specific to CM - see condition-monitoring-base.component.html
.cm-wrapper {

    .k-listbox .k-list-scroller {
        overflow: initial;
    }

    .k-list-scroller {
        overflow: initial;
    }
}

// This amt-link-column needs to overrides from kendo-overrides
.amt-link-column a {
    text-decoration: underline;
}