@import "../base/variables";

.new-toast {
    position: absolute;
    top: 51px;
    right: 250px;
    width: 500px;
    height: 30px;
    z-index: 140;
    border-radius: 5px;
}

.new-toast.alert-type {
    background-color: #e6ad4e;
    border: 2px solid #e6972a;
}

.new-toast.many-type {
    background-color: #e9e993;
    border: 2px solid #d5d56a;
}

.new-toast.notification-type {
    background-color: #d3dfee;
    border: 2px solid #4f81bd;
}

.new-toast > img {
    position: absolute;
    left: 6px;
    top: 3px;
    width: 20px;
}

.new-toast > div {
    position: absolute;
    right: 10px;
    top: 5px;
    font-weight: bold;
}

.new-toast > a {
    font-weight: bold;
    color: $black;
    position: absolute;
    left: 28px;
    top: 0;
    width: 430px;
    height: 28px;
    padding: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    box-sizing: border-box;
}

.new-toast.alert-type > a {
    border-right: 1px solid #e6972a;
}

.new-toast.notification-type > a {
    border-right: 1px solid #4f81bd;
}

.new-toast.many-type > a {
    border-right: 1px solid #d5d56a;
}


.notification-icon {
    opacity: 0.35;
    width: 20px;
}

.notification-icon-new {
    opacity: 1;
}

.alert-property-table td {
    padding-top: 0;
    padding-bottom: 0;
    font-size: small;
    text-align: left;
    line-height: 1.7 !important;
    border: 0;
    white-space: normal !important;
}

.alert-property-table th {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    font-weight: bold;
    font-size: small;
    text-align: right;
    line-height: 1.7 !important;
    vertical-align: top;
    width: 40%;
}

.alert-type {
    font-weight: bold;
    font-size: medium;
}

.alert-type .ng-optional, .alert-type .ng-required {
    font-weight: bold;
}


.notification-preview-detail .inspection-preview-detail-title {
    float: left;
    padding-left: 15px;
    padding-right: 150px;
    width: 100%;
    box-sizing: border-box;
}

.notification-preview-detail .inspection-preview-detail-button {
    padding-right: 15px;
    float: right;
    position: absolute;
    right: 10px;
    width: 150px;
    box-sizing: border-box;
}

.notification-preview-detail .col-md-6, .notification-preview-detail .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
}

.notification-preview-detail .notification-preview-detail-title {
    float: left;
    padding-left: 15px;
    padding-right: 220px;
    width: 100%;
    box-sizing: border-box;
}

.notification-preview-detail .notification-preview-detail-btn-group {
    padding-right: 15px;
    float: right;
    position: absolute;
    right: 10px;
    width: 215px;
    box-sizing: border-box;
}

.notification-preview-detail .form-horizontal .control-label {
    text-align: left;
    color: $black2;
    font-size: 0.9em;
    font-weight: bold;
}

.comments .comment-text, .notification-preview-detail .email .email-text {
    color: $black2;
    font-weight: bold;
    font-size: 13px;
}

.comments .comment-list, .notification-preview-detail .mail-list {
    overflow: auto;
    overflow-y: scroll;
    max-height: 150px;
    width: 100%;
}

.comments .comment-item {
    margin-bottom: 5px;
}

.comments .comment-info, .notification-preview-detail .email .email-info {
    color: $black2;
    font-size: 12px;
}

.comments .comment-info .comment-user, .notification-preview-detail .email .email-info .email-user {
    text-decoration: underline;
    margin-right: 10px;
}

.notification-preview-detail .section-title.primary-title {
    font-size: 25px;
}

.notification-preview-detail .section-title.secondary-title {
    font-size: 18px;
}

.notification-preview-detail .action {
    padding-left: 0;
}


.notification-status-group {
    cursor: default;
}

.notification-edit .notification-status-group li:nth-child(1) {
    border-right: 1px solid $grey2;
}

.notification-edit .first-row {
    margin-top: 25px;
}

.notification-status-group .assigned-to-heading, .notification-status-group .amt-rating.rating1 {
    height: 35px;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
}

.notification-status-group li:nth-child(2) {
    border-left: 0;
}

.notification-status-group .amt-rating {
    font-weight: bolder;
}

.notification-status-group li span {
    line-height: 35px;
}

.notification-status-group li {
    padding: 0 20px;
    min-width: 120px;
    text-align: center;
    margin: 0 !important;
}

.notification-status-group .status {
    background-color: #e7e7e7;
    color: #969696;
}

.notification-status-group .assigned-to-heading, .notification-status-group .amt-rating.rating1 {
    height: 35px;
    border: 1px solid $grey2;
    box-sizing: border-box;
}

.notification-status-group li:nth-child(2) {
    border-left: 0;
}

.notification-edit .tab-content .panel {
    margin: -1px 0 15px 0;
}

.notification-edit .full-width .col-md-5 {
    width: 20.8333333%;
}

.notification-edit .full-width .col-md-7 {
    width: 79.1666667%;
}

.notification-edit .section-title.primary-title {
    font-size: 25px;
    line-height: 30px;
}

//TODO: looks unused but evil, "col-md-5" has a meaning, don't confuse that, use other class names if you want to have different widths?!
.notification-create .one-column .col-md-5 {
    width: 20.8333333%;
}

.notification-create .one-column .col-md-7 {
    width: 79.1666667%;
}

.notification-create textarea,
.notification-edit textarea,
.create-workorder .repair-instructions textarea {
    resize: none;
}

.notification-edit .audit-text {
    word-wrap: break-word;
    white-space: normal;
}

.notification-preview .ng-enter-active {
    display: none;
}

.notification-container {
    padding: 0;
    border: none;
    max-width: 50% !important;
    position: absolute;
    right: 50px;
    bottom: 10px;
    z-index: 10000;
    background-color: transparent;
}

.k-notification {
    border-radius: 2px;
    padding: 4px 8px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 12px;
    line-height: 1.4285714286;
    cursor: default;
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    right: 50px;
}

.notification-container .k-notification-wrap {
    white-space: normal !important;
}
.actionrequired {
    background-color: rgba(173, 25, 25, 0.70);
    color: #fff;
}


notifications-list amt-grid,
notifications-list .k-grid-content,
notifications-list .tab-content {
    display: flex;
    flex: 1 0 0px;
}

notifications-list .flex-grid .amt-grid {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
}