@import "../base/variables";

.modalDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 150;
}

.modalDialog > .child-window-container {
    margin: 0;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.modalDialog.modalComplete {
    background: $white; /* full white non transparent background */
}

.modal-dialog .modal-content .modal-body {
    line-height: 1.5;
}

.modal-dialog .modal-content .modal-body textarea {
    resize: none;
}

.modal-footer {
    margin-top: 0;
}

.modal-backdrop {
    background-color: #231F20;
}

.modal-backdrop.in {
    opacity: 0.8;
    filter: alpha(opacity=80); /* csslint allow: known-properties */
}

.modal-over {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
}
