@import "../base/variables";

.navbar {
    border: none !important;
    margin: 0;
    border-radius: 0px;
}

.navbar .navbar-form-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-md {
    min-height: 60px;
}

.navbar-md .navbar-btn {
    margin-top: 13px;
}

.navbar-md .navbar-form {
    margin-top: 15px;
}

.navbar-md .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.navbar-nav a {
    color: $off-white;
}

.navbar a:focus {
    color: $off-white;
}

.navbar a.navbar-btn:hover {
    color: $off-white;
}

.navbar-md .navbar-brand {
    line-height: 60px;
}

.navbar-default {
    background: $black;
}

.navbar-main {
    min-width: 1200px;
    height: 50px;
}

.navbar-header {
    -webkit-align-items: center;
    align-items: center;
    min-height: 50px;

}

.navbar-header > button {
    text-decoration: none;
    line-height: 30px;
    font-size: 16px;
    padding: 10px 17px;
    border: none;
    background-color: transparent;
}

.navbar-brand {
    float: none;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    line-height: 50px;
    display: inline-block;
    padding: 0 20px;
}

.navbar-brand:hover {
    text-decoration: none;
}

.navbar-brand img {
    max-height: 20px;
    margin-top: -4px;
    vertical-align: middle;
}

.navbar-right > li > a {
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 0px;
}

.nav.nav-md > li > a {
    padding: 3px 10px 3px 10px;
}

.nav.nav-sm > li > a {
    padding: 3px 10px 3px 10px;
}

.nav.nav-xs > li > a {
    padding: 3px 10px 3px 10px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #312F30;
}

.nav-heading {
    color: #E91D25;
    font-weight: 600;
}

.nav-search {
    width: 100%;
    margin: 0 auto;
    padding: 15px 0 0 0;
    box-sizing: border-box;
}

.nav-search > div {
    width: 200px;
    display: block !important;
    margin: 0 auto;
}

.nav-tabs {
    border-color: $black4;
}

.nav-tabs > li > a {
    border-radius: 2px 2px 0 0;
    color: $tab-color;
    height: 100%;
}

.nav-tabs > li.active > a:hover {
    border-color: $tab-border;
    background: $tab-background-hover;
}

.nav-tabs > li:not(.dirty-tab) > a:hover {
    background: $tab-background-hover !important;
    color: $tab-selected-color;
}

.nav-tabs > li.active > a {
    border-bottom-color: $white !important;
    border-color: $tab-border;
    color: $tab-selected-color;
}

.nav-tabs-alt .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
}

.nav-tabs-alt .nav-tabs > li > a {
    border-radius: 0;
    border-color: transparent !important;
    background: transparent !important;
    border-bottom-color: $black4 !important;
}

.dirty-tab > a {
    background-color: $dirtyYellow !important;
    color: $tab-selected-color !important;
    border-color: transparent !important;
}

.nav-sub {
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin-left: -20px;
    -webkit-transition: all .2s ease-in-out 0;
    transition: all .2s ease-in-out 0;
}

.active .nav-sub {
    opacity: 1;
    margin-left: 0;
    height: auto !important;
    overflow: auto;
}

.nav-sub-header {
    display: none !important;
}

.nav-sub-header a {
    padding: 5px 20px;
}

.navi ul.nav li {
    display: block;
    position: relative;
}

.navi ul.nav li li a {
    padding-left: 35px;
}

.navi ul.nav li a {
    font-weight: normal;
    text-transform: none;
    display: block;
    padding: 10px 20px;
    position: relative;
    -webkit-transition: background-color .2s ease-in-out 0;
    transition: background-color .2s ease-in-out 0;
}

.navi ul.nav li a .badge,
.navi ul.nav li a .label {
    font-size: 11px;
    padding: 2px 5px;
    margin-top: 2px;
}

.navi ul.nav li a > i {
    margin: -10px -10px;
    line-height: 40px;
    width: 40px;
    float: left;
    margin-right: 5px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.navi ul.nav li a > i:before {
    position: relative;
    z-index: 2;
}

.dropdown-menu {
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    border: 1px solid #E0E0E0;
    border: 1px solid rgba(0,0,0,0.1);
    left: auto;
}

.dropdown-menu li > a.disabled {
    color: #E5E4E4;
}

.dropdown-menu.pull-left {
    left: 100%;
}

.dropdown-menu > .panel {
    border: none;
    margin: -5px 0;
}

.dropdown-menu > li > a {
    padding: 5px 15px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-image: none;
    filter: none;
    background-color: $off-white !important;
    color: #141719;
}

.dropdown-menu > li > a {
    padding-right: 20px;
}

.dropdown-menu > li {
    margin-right: 10px;
}

.dropdown-header {
    padding: 5px 15px;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
    background-color: $off-white !important;
    color: #333333;
}

.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu:focus > .dropdown-menu {
    display: block;
}

.dropdown-submenu.pull-left {
    float: none !important;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px;
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
}

.dropdown-submenu > a:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    float: right;
    margin-left: 2px;
    margin-top: 7px;
    margin-right: -10px;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 4px solid;
}


.pagination > li > a {
    border-color: $black4;
}

.pagination > li > a:hover,
.pagination > li > a:focus {
    border-color: $black4;
    background-color: $off-white;
}

.menu-item {
    border: 1px solid $grey;
}

.menu {
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: $black;
    color: white;
    z-index: 1001;
}

.navbar-toggle > div > a {
    vertical-align: middle;
}

.navbar-toggle > div {
    height: 100%;
    display: inline-block;
    padding-left: 5px;
}


.navbar-collapse.collapse {
    display: inline-block !important;
}

.navbar-nav {
    margin: 0px !important;
}

.navbar-nav > li, .navbar-nav {
    float: left !important;
}

.navbar-nav.navbar-right:last-child {
    margin-right: -15px !important;
}

.navbar-right {
    float: right !important;
}

.navbar-right > li {
    margin-right: 5px;
}
