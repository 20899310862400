@import "../base/variables";

.login-content {
    background: #5f3020; //IE fallback
    background-image: linear-gradient(180deg, rgb(133,159,189) 0%, rgb(5,4,2) 53%, rgb(95,48,32) 100%); //gradient approximates image
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.webp .login-content {
    background: url("/Content/common/login-bg-dm.webp") no-repeat center center fixed, linear-gradient(180deg, rgb(133,159,189) 0%, rgb(5,4,2) 53%, rgb(95,48,32) 100%),;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.no-webp .login-content {
    background: #5f3020; //IE fallback
    background-image: url("/Content/common/login-bg-dm.jpg"); //another IE fallback
    background: url("/Content/common/login-bg-dm.jpg") no-repeat center center fixed, linear-gradient(180deg, rgb(133,159,189) 0%, rgb(5,4,2) 53%, rgb(95,48,32) 100%);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

a.login-link {
    color: $white;
    text-decoration: underline;
}

a.login-link:hover {
    color: $white;
    text-decoration: underline;
}

a.login-link-disabled {
    text-decoration: none !important;
    cursor: default;
}

.btn-login {
    border-radius: 4px;
    background: $green1;
    color: $black;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgba(0,0,0, 0.1);
}

.btn-loginETLError {
    border-radius: 4px;
    background: $greenETLError;
    color: $black;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgba(0,0,0, 0.1);
}

.login-form-control {
    border-radius: 0px;
    height: 40px;
}

.login-label {
    color: $white;
    font-size: 15px;
}

.login-container {
    max-width: 970px;
}

.login-seperator {
    margin: 0 15px 0 15px;
}

.login-form-margin {
    margin-top: 70px !important;
}

html.mobile {
    .login-form-control,
    a.login-link {
        font-size: $large-font-size;
    }

    .login-label {
        font-size: $large-font-size-root;
        margin-right: 10px;
    }

    .login-seperator {
        font-size: $large-font-size-root;
        margin: 0px 30px 0 30px;
    }

    .login-form > .form-group {
        margin-bottom: 15px;
    }

    .login-form-margin {
        margin-top: 20px !important;
    }
}
