
site-dashboard-configuration {

    .dashboard-config {
        display: inline-flex;
        width: 100%;
    }

    .dashboard-config-list-active {
        margin-bottom: 20px;
        margin-top: 20px;
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
        }
    }

    .dashboard-config-list-header {
        font-weight: bold;
        font-size: 1.17rem;
    }

    .dashboard-config-list-dashboards {
        flex: 1 0 0px;
        max-width: 40%;
        padding-right: 10px;
    }

    .dashboard-config-list-widgets {
        flex: 1 0 0px;
        padding-left: 10px;
    }

    .dashboard-config-column-delete {
        display: block;
        text-align: center;
        text-decoration: underline;
    }
}
