@import "../base/variables";

.forecast-summary-table > tbody > tr > td,
.forecast-summary-table > tfoot > tr > td {
    padding: 5px 20px 0 20px;
    width: 100%;
    box-sizing: border-box;
}

.forecast-month-value.ng-dirty.ng-valid {
    background-color: $blue3;
    border-color: $blue;
}

.vehicle-rotation-strategy .k-grid tbody tr {
    height: 41px;
}

.vehicle-rotation-strategy .k-grid td {
    overflow: inherit;
}

// Recalculate Forecast Control
recalculate-forecast {

    .recalculate-forecast-body {
        display: grid;
    }

    .recalculate-optional {
        margin-top: 15px;
        font-weight: bold;
    }

    .recalculate-options {
        margin-left: 2.5rem;        
    }

    .recalculate-option {

        margin-top: 10px;

        label {
            display: inline-flex;
        }

        div {
            display: grid;
            margin-left: 10px;
        }

        .description {
            font-weight: normal;
        }
    }

    .recalculate-progress-bar {
        margin-top: 10px;
    }
}
