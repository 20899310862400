component-details .tab-content,
component-details .tab-content > .active {
    flex: 1 0 0px !important;
    display: flex !important;
}

component-details .tab-content > .active {
    flex-direction: column !important;
}



oc-equipment-history .flex-container > amt-grid, .flex-grid .amt-grid {
    max-width: 100%
}


vehicle-details .tab-content,
vehicle-details .tab-content > .active {
    display: flex;
    flex: 1 0 0px;
}

vehicle-details .tab-content > .active {
    flex-direction: column;
}
