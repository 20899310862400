﻿//based on non-mixin declarations from the glyphicons-halflings npm package

// Catchall baseclass
@mixin glyphicon {
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Individual icons
@mixin glyphicon-glass {
    @include glyphicon;
    content: "\E001";
}

@mixin glyphicon-music {
    @include glyphicon;
    content: "\E002";
}

@mixin glyphicon-search {
    @include glyphicon;
    content: "\E003";
}

@mixin glyphicon-envelope {
    @include glyphicon;
    content: "\2709";
}

@mixin glyphicon-heart {
    @include glyphicon;
    content: "\E005";
}

@mixin glyphicon-star {
    @include glyphicon;
    content: "\E006";
}

@mixin glyphicon-star-empty {
    @include glyphicon;
    content: "\E007";
}

@mixin glyphicon-user {
    @include glyphicon;
    content: "\E008";
}

@mixin glyphicon-film {
    @include glyphicon;
    content: "\E009";
}

@mixin glyphicon-th-large {
    @include glyphicon;
    content: "\E010";
}

@mixin glyphicon-th {
    @include glyphicon;
    content: "\E011";
}

@mixin glyphicon-th-list {
    @include glyphicon;
    content: "\E012";
}

@mixin glyphicon-ok {
    @include glyphicon;
    content: "\E013";
}

@mixin glyphicon-remove {
    @include glyphicon;
    content: "\E014";
}

@mixin glyphicon-zoom-in {
    @include glyphicon;
    content: "\E015";
}

@mixin glyphicon-zoom-out {
    @include glyphicon;
    content: "\E016";
}

@mixin glyphicon-off {
    @include glyphicon;
    content: "\E017";
}

@mixin glyphicon-signal {
    @include glyphicon;
    content: "\E018";
}

@mixin glyphicon-cog {
    @include glyphicon;
    content: "\E019";
}

@mixin glyphicon-trash {
    @include glyphicon;
    content: "\E020";
}

@mixin glyphicon-home {
    @include glyphicon;
    content: "\E021";
}

@mixin glyphicon-file {
    @include glyphicon;
    content: "\E022";
}

@mixin glyphicon-time {
    @include glyphicon;
    content: "\E023";
}

@mixin glyphicon-road {
    @include glyphicon;
    content: "\E024";
}

@mixin glyphicon-download-alt {
    @include glyphicon;
    content: "\E025";
}

@mixin glyphicon-download {
    @include glyphicon;
    content: "\E026";
}

@mixin glyphicon-upload {
    @include glyphicon;
    content: "\E027";
}

@mixin glyphicon-inbox {
    @include glyphicon;
    content: "\E028";
}

@mixin glyphicon-play-circle {
    @include glyphicon;
    content: "\E029";
}

@mixin glyphicon-repeat {
    @include glyphicon;
    content: "\E030";
}

@mixin glyphicon-refresh {
    @include glyphicon;
    content: "\E031";
}

@mixin glyphicon-list-alt {
    @include glyphicon;
    content: "\E032";
}

@mixin glyphicon-glyph-lock {
    @include glyphicon;
    content: "\E033";
}

@mixin glyphicon-flag {
    @include glyphicon;
    content: "\E034";
}

@mixin glyphicon-headphones {
    @include glyphicon;
    content: "\E035";
}

@mixin glyphicon-volume-off {
    @include glyphicon;
    content: "\E036";
}

@mixin glyphicon-volume-down {
    @include glyphicon;
    content: "\E037";
}

@mixin glyphicon-volume-up {
    @include glyphicon;
    content: "\E038";
}

@mixin glyphicon-qrcode {
    @include glyphicon;
    content: "\E039";
}

@mixin glyphicon-barcode {
    @include glyphicon;
    content: "\E040";
}

@mixin glyphicon-tag {
    @include glyphicon;
    content: "\E041";
}

@mixin glyphicon-tags {
    @include glyphicon;
    content: "\E042";
}

@mixin glyphicon-book {
    @include glyphicon;
    content: "\E043";
}

@mixin glyphicon-glyph-bookmark {
    @include glyphicon;
    content: "\E044";
}

@mixin glyphicon-print {
    @include glyphicon;
    content: "\E045";
}

@mixin glyphicon-glyph-camera {
    @include glyphicon;
    content: "\E046";
}

@mixin glyphicon-font {
    @include glyphicon;
    content: "\E047";
}

@mixin glyphicon-bold {
    @include glyphicon;
    content: "\E048";
}

@mixin glyphicon-italic {
    @include glyphicon;
    content: "\E049";
}

@mixin glyphicon-text-height {
    @include glyphicon;
    content: "\E050";
}

@mixin glyphicon-text-width {
    @include glyphicon;
    content: "\E051";
}

@mixin glyphicon-align-left {
    @include glyphicon;
    content: "\E052";
}

@mixin glyphicon-align-center {
    @include glyphicon;
    content: "\E053";
}

@mixin glyphicon-align-right {
    @include glyphicon;
    content: "\E054";
}

@mixin glyphicon-align-justify {
    @include glyphicon;
    content: "\E055";
}

@mixin glyphicon-list {
    @include glyphicon;
    content: "\E056";
}

@mixin glyphicon-indent-left {
    @include glyphicon;
    content: "\E057";
}

@mixin glyphicon-indent-right {
    @include glyphicon;
    content: "\E058";
}

@mixin glyphicon-facetime-video {
    @include glyphicon;
    content: "\E059";
}

@mixin glyphicon-picture {
    @include glyphicon;
    content: "\E060";
}

@mixin glyphicon-pencil {
    @include glyphicon;
    content: "\270F";
}

@mixin glyphicon-map-marker {
    @include glyphicon;
    content: "\E062";
}

@mixin glyphicon-adjust {
    @include glyphicon;
    content: "\E063";
}

@mixin glyphicon-tint {
    @include glyphicon;
    content: "\E064";
}

@mixin glyphicon-edit {
    @include glyphicon;
    content: "\E065";
}

@mixin glyphicon-share {
    @include glyphicon;
    content: "\E066";
}

@mixin glyphicon-check {
    @include glyphicon;
    content: "\E067";
}

@mixin glyphicon-move {
    @include glyphicon;
    content: "\E068";
}

@mixin glyphicon-step-backward {
    @include glyphicon;
    content: "\E069";
}

@mixin glyphicon-fast-backward {
    @include glyphicon;
    content: "\E070";
}

@mixin glyphicon-backward {
    @include glyphicon;
    content: "\E071";
}

@mixin glyphicon-play {
    @include glyphicon;
    content: "\E072";
}

@mixin glyphicon-pause {
    @include glyphicon;
    content: "\E073";
}

@mixin glyphicon-stop {
    @include glyphicon;
    content: "\E074";
}

@mixin glyphicon-forward {
    @include glyphicon;
    content: "\E075";
}

@mixin glyphicon-fast-forward {
    @include glyphicon;
    content: "\E076";
}

@mixin glyphicon-step-forward {
    @include glyphicon;
    content: "\E077";
}

@mixin glyphicon-eject {
    @include glyphicon;
    content: "\E078";
}

@mixin glyphicon-chevron-left {
    @include glyphicon;
    content: "\E079";
}

@mixin glyphicon-chevron-right {
    @include glyphicon;
    content: "\E080";
}

@mixin glyphicon-plus-sign {
    @include glyphicon;
    content: "\E081";
}

@mixin glyphicon-minus-sign {
    @include glyphicon;
    content: "\E082";
}

@mixin glyphicon-remove-sign {
    @include glyphicon;
    content: "\E083";
}

@mixin glyphicon-ok-sign {
    @include glyphicon;
    content: "\E084";
}

@mixin glyphicon-question-sign {
    @include glyphicon;
    content: "\E085";
}

@mixin glyphicon-info-sign {
    @include glyphicon;
    content: "\E086";
}

@mixin glyphicon-screenshot {
    @include glyphicon;
    content: "\E087";
}

@mixin glyphicon-remove-circle {
    @include glyphicon;
    content: "\E088";
}

@mixin glyphicon-ok-circle {
    @include glyphicon;
    content: "\E089";
}

@mixin glyphicon-ban-circle {
    @include glyphicon;
    content: "\E090";
}

@mixin glyphicon-arrow-left {
    @include glyphicon;
    content: "\E091";
}

@mixin glyphicon-arrow-right {
    @include glyphicon;
    content: "\E092";
}

@mixin glyphicon-arrow-up {
    @include glyphicon;
    content: "\E093";
}

@mixin glyphicon-arrow-down {
    @include glyphicon;
    content: "\E094";
}

@mixin glyphicon-share-alt {
    @include glyphicon;
    content: "\E095";
}

@mixin glyphicon-resize-full {
    @include glyphicon;
    content: "\E096";
}

@mixin glyphicon-resize-small {
    @include glyphicon;
    content: "\E097";
}

@mixin glyphicon-plus {
    @include glyphicon;
    content: "\002B";
}

@mixin glyphicon-minus {
    @include glyphicon;
    content: "\2212";
}

@mixin glyphicon-asterisk {
    @include glyphicon;
    content: "\002A";
}

@mixin glyphicon-exclamation-sign {
    @include glyphicon;
    content: "\E101";
}

@mixin glyphicon-gift {
    @include glyphicon;
    content: "\E102";
}

@mixin glyphicon-leaf {
    @include glyphicon;
    content: "\E103";
}

@mixin glyphicon-glyph-fire {
    @include glyphicon;
    content: "\E104";
}

@mixin glyphicon-eye-open {
    @include glyphicon;
    content: "\E105";
}

@mixin glyphicon-eye-close {
    @include glyphicon;
    content: "\E106";
}

@mixin glyphicon-warning-sign {
    @include glyphicon;
    content: "\E107";
}

@mixin glyphicon-plane {
    @include glyphicon;
    content: "\E108";
}

@mixin glyphicon-glyph-calendar {
    @include glyphicon;
    content: "\E109";
}

@mixin glyphicon-random {
    @include glyphicon;
    content: "\E110";
}

@mixin glyphicon-comments {
    @include glyphicon;
    content: "\E111";
}

@mixin glyphicon-magnet {
    @include glyphicon;
    content: "\E112";
}

@mixin glyphicon-chevron-up {
    @include glyphicon;
    content: "\E113";
}

@mixin glyphicon-chevron-down {
    @include glyphicon;
    content: "\E114";
}

@mixin glyphicon-retweet {
    @include glyphicon;
    content: "\E115";
}

@mixin glyphicon-shopping-cart {
    @include glyphicon;
    content: "\E116";
}

@mixin glyphicon-folder-close {
    @include glyphicon;
    content: "\E117";
}

@mixin glyphicon-folder-open {
    @include glyphicon;
    content: "\E118";
}

@mixin glyphicon-resize-vertical {
    @include glyphicon;
    content: "\E119";
}

@mixin glyphicon-resize-horizontal {
    @include glyphicon;
    content: "\E120";
}

@mixin glyphicon-hdd {
    @include glyphicon;
    content: "\E121";
}

@mixin glyphicon-bullhorn {
    @include glyphicon;
    content: "\E122";
}

@mixin glyphicon-glyph-bell {
    @include glyphicon;
    content: "\E123";
}

@mixin glyphicon-certificate {
    @include glyphicon;
    content: "\E124";
}

@mixin glyphicon-thumbs-up {
    @include glyphicon;
    content: "\E125";
}

@mixin glyphicon-thumbs-down {
    @include glyphicon;
    content: "\E126";
}

@mixin glyphicon-hand-right {
    @include glyphicon;
    content: "\E127";
}

@mixin glyphicon-hand-left {
    @include glyphicon;
    content: "\E128";
}

@mixin glyphicon-hand-top {
    @include glyphicon;
    content: "\E129";
}

@mixin glyphicon-hand-down {
    @include glyphicon;
    content: "\E130";
}

@mixin glyphicon-circle-arrow-right {
    @include glyphicon;
    content: "\E131";
}

@mixin glyphicon-circle-arrow-left {
    @include glyphicon;
    content: "\E132";
}

@mixin glyphicon-circle-arrow-top {
    @include glyphicon;
    content: "\E133";
}

@mixin glyphicon-circle-arrow-down {
    @include glyphicon;
    content: "\E134";
}

@mixin glyphicon-globe {
    @include glyphicon;
    content: "\E135";
}

@mixin glyphicon-glyph-wrench {
    @include glyphicon;
    content: "\E136";
}

@mixin glyphicon-tasks {
    @include glyphicon;
    content: "\E137";
}

@mixin glyphicon-filter {
    @include glyphicon;
    content: "\E138";
}

@mixin glyphicon-glyph-briefcase {
    @include glyphicon;
    content: "\E139";
}

@mixin glyphicon-fullscreen {
    @include glyphicon;
    content: "\E140";
}

@mixin glyphicon-dashboard {
    @include glyphicon;
    content: "\E141";
}

@mixin glyphicon-glyph-paperclip {
    @include glyphicon;
    content: "\E142";
}

@mixin glyphicon-heart-empty {
    @include glyphicon;
    content: "\E143";
}

@mixin glyphicon-link {
    @include glyphicon;
    content: "\E144";
}

@mixin glyphicon-phone {
    @include glyphicon;
    content: "\E145";
}

@mixin glyphicon-glyph-pushpin {
    @include glyphicon;
    content: "\E146";
}

@mixin glyphicon-euro {
    @include glyphicon;
    content: "\20AC";
}

@mixin glyphicon-usd {
    @include glyphicon;
    content: "\E148";
}

@mixin glyphicon-gbp {
    @include glyphicon;
    content: "\E149";
}

@mixin glyphicon-sort {
    @include glyphicon;
    content: "\E150";
}

@mixin glyphicon-sort-by-alphabet {
    @include glyphicon;
    content: "\E151";
}

@mixin glyphicon-sort-by-alphabet-alt {
    @include glyphicon;
    content: "\E152";
}

@mixin glyphicon-sort-by-order {
    @include glyphicon;
    content: "\E153";
}

@mixin glyphicon-sort-by-order-alt {
    @include glyphicon;
    content: "\E154";
}

@mixin glyphicon-sort-by-attributes {
    @include glyphicon;
    content: "\E155";
}

@mixin glyphicon-sort-by-attributes-alt {
    @include glyphicon;
    content: "\E156";
}

@mixin glyphicon-unchecked {
    @include glyphicon;
    content: "\E157";
}

@mixin glyphicon-expand {
    @include glyphicon;
    content: "\E158";
}

@mixin glyphicon-collapse {
    @include glyphicon;
    content: "\E159";
}

@mixin glyphicon-collapse-top {
    @include glyphicon;
    content: "\E160";
}

@mixin glyphicon-log-in {
    @include glyphicon;
    content: "\E161";
}

@mixin glyphicon-flash {
    @include glyphicon;
    content: "\E162";
}

@mixin glyphicon-log-out {
    @include glyphicon;
    content: "\E163";
}

@mixin glyphicon-new-window {
    @include glyphicon;
    content: "\E164";
}

@mixin glyphicon-record {
    @include glyphicon;
    content: "\E165";
}

@mixin glyphicon-save {
    @include glyphicon;
    content: "\E166";
}

@mixin glyphicon-open {
    @include glyphicon;
    content: "\E167";
}

@mixin glyphicon-saved {
    @include glyphicon;
    content: "\E168";
}

@mixin glyphicon-import {
    @include glyphicon;
    content: "\E169";
}

@mixin glyphicon-export {
    @include glyphicon;
    content: "\E170";
}

@mixin glyphicon-send {
    @include glyphicon;
    content: "\E171";
}

@mixin glyphicon-floppy-disk {
    @include glyphicon;
    content: "\E172";
}

@mixin glyphicon-floppy-saved {
    @include glyphicon;
    content: "\E173";
}

@mixin glyphicon-floppy-remove {
    @include glyphicon;
    content: "\E174";
}

@mixin glyphicon-floppy-save {
    @include glyphicon;
    content: "\E175";
}

@mixin glyphicon-floppy-open {
    @include glyphicon;
    content: "\E176";
}

@mixin glyphicon-credit-card {
    @include glyphicon;
    content: "\E177";
}

@mixin glyphicon-transfer {
    @include glyphicon;
    content: "\E178";
}

@mixin glyphicon-cutlery {
    @include glyphicon;
    content: "\E179";
}

@mixin glyphicon-header {
    @include glyphicon;
    content: "\E180";
}

@mixin glyphicon-compressed {
    @include glyphicon;
    content: "\E181";
}

@mixin glyphicon-earphone {
    @include glyphicon;
    content: "\E182";
}

@mixin glyphicon-phone-alt {
    @include glyphicon;
    content: "\E183";
}

@mixin glyphicon-tower {
    @include glyphicon;
    content: "\E184";
}

@mixin glyphicon-stats {
    @include glyphicon;
    content: "\E185";
}

@mixin glyphicon-sd-video {
    @include glyphicon;
    content: "\E186";
}

@mixin glyphicon-hd-video {
    @include glyphicon;
    content: "\E187";
}

@mixin glyphicon-subtitles {
    @include glyphicon;
    content: "\E188";
}

@mixin glyphicon-sound-stereo {
    @include glyphicon;
    content: "\E189";
}

@mixin glyphicon-sound-dolby {
    @include glyphicon;
    content: "\E190";
}

@mixin glyphicon-sound-5-1 {
    @include glyphicon;
    content: "\E191";
}

@mixin glyphicon-sound-6-1 {
    @include glyphicon;
    content: "\E192";
}

@mixin glyphicon-sound-7-1 {
    @include glyphicon;
    content: "\E193";
}

@mixin glyphicon-copyright-mark {
    @include glyphicon;
    content: "\E194";
}

@mixin glyphicon-registration-mark {
    @include glyphicon;
    content: "\E195";
}

@mixin glyphicon-cloud {
    @include glyphicon;
    content: "\2601";
}

@mixin glyphicon-cloud-download {
    @include glyphicon;
    content: "\E197";
}

@mixin glyphicon-cloud-upload {
    @include glyphicon;
    content: "\E198";
}

@mixin glyphicon-tree-conifer {
    @include glyphicon;
    content: "\E199";
}

@mixin glyphicon-tree-deciduous {
    @include glyphicon;
    content: "\E200";
}

@mixin glyphicon-cd {
    @include glyphicon;
    content: "\E201";
}

@mixin glyphicon-save-file {
    @include glyphicon;
    content: "\E202";
}

@mixin glyphicon-open-file {
    @include glyphicon;
    content: "\E203";
}

@mixin glyphicon-level-up {
    @include glyphicon;
    content: "\E204";
}

@mixin glyphicon-copy {
    @include glyphicon;
    content: "\E205";
}

@mixin glyphicon-paste {
    @include glyphicon;
    content: "\E206";
}

@mixin glyphicon-door {
    @include glyphicon;
    content: "\1F6AA";
}

@mixin glyphicon-key {
    @include glyphicon;
    content: "\1F511";
}

@mixin glyphicon-alert {
    @include glyphicon;
    content: "\E209";
}

@mixin glyphicon-equalizer {
    @include glyphicon;
    content: "\E210";
}

@mixin glyphicon-king {
    @include glyphicon;
    content: "\E211";
}

@mixin glyphicon-queen {
    @include glyphicon;
    content: "\E212";
}

@mixin glyphicon-pawn {
    @include glyphicon;
    content: "\E213";
}

@mixin glyphicon-bishop {
    @include glyphicon;
    content: "\E214";
}

@mixin glyphicon-knight {
    @include glyphicon;
    content: "\E215";
}

@mixin glyphicon-baby-formula {
    @include glyphicon;
    content: "\E216";
}

@mixin glyphicon-tent {
    @include glyphicon;
    content: "\26FA";
}

@mixin glyphicon-blackboard {
    @include glyphicon;
    content: "\E218";
}

@mixin glyphicon-bed {
    @include glyphicon;
    content: "\E219";
}

@mixin glyphicon-apple {
    @include glyphicon;
    content: "\F8FF";
}

@mixin glyphicon-erase {
    @include glyphicon;
    content: "\E221";
}

@mixin glyphicon-hourglass {
    @include glyphicon;
    content: "\231B";
}

@mixin glyphicon-lamp {
    @include glyphicon;
    content: "\E223";
}

@mixin glyphicon-duplicate {
    @include glyphicon;
    content: "\E224";
}

@mixin glyphicon-piggy-bank {
    @include glyphicon;
    content: "\E225";
}

@mixin glyphicon-scissors {
    @include glyphicon;
    content: "\E226";
}

@mixin glyphicon-bitcoin {
    @include glyphicon;
    content: "\E227";
}

@mixin glyphicon-yen {
    @include glyphicon;
    content: "\00A5";
}

@mixin glyphicon-ruble {
    @include glyphicon;
    content: "\20BD";
}

@mixin glyphicon-scale {
    @include glyphicon;
    content: "\E230";
}

@mixin glyphicon-ice-lolly {
    @include glyphicon;
    content: "\E231";
}

@mixin glyphicon-ice-lolly-tasted {
    @include glyphicon;
    content: "\E232";
}

@mixin glyphicon-education {
    @include glyphicon;
    content: "\E233";
}

@mixin glyphicon-option-horizontal {
    @include glyphicon;
    content: "\E234";
}

@mixin glyphicon-option-vertical {
    @include glyphicon;
    content: "\E235";
}

@mixin glyphicon-menu-hamburger {
    @include glyphicon;
    content: "\E236";
}

@mixin glyphicon-modal-window {
    @include glyphicon;
    content: "\E237";
}

@mixin glyphicon-oil {
    @include glyphicon;
    content: "\E238";
}

@mixin glyphicon-grain {
    @include glyphicon;
    content: "\E239";
}

@mixin glyphicon-sunglasses {
    @include glyphicon;
    content: "\E240";
}

@mixin glyphicon-text-size {
    @include glyphicon;
    content: "\E241";
}

@mixin glyphicon-text-color {
    @include glyphicon;
    content: "\E242";
}

@mixin glyphicon-text-background {
    @include glyphicon;
    content: "\E243";
}

@mixin glyphicon-object-align-top {
    @include glyphicon;
    content: "\E244";
}

@mixin glyphicon-object-align-bottom {
    @include glyphicon;
    content: "\E245";
}

@mixin glyphicon-object-align-horizontal {
    @include glyphicon;
    content: "\E246";
}

@mixin glyphicon-object-align-left {
    @include glyphicon;
    content: "\E247";
}

@mixin glyphicon-object-align-vertical {
    @include glyphicon;
    content: "\E248";
}

@mixin glyphicon-object-align-right {
    @include glyphicon;
    content: "\E249";
}

@mixin glyphicon-triangle-right {
    @include glyphicon;
    content: "\E250";
}

@mixin glyphicon-triangle-left {
    @include glyphicon;
    content: "\E251";
}

@mixin glyphicon-triangle-bottom {
    @include glyphicon;
    content: "\E252";
}

@mixin glyphicon-triangle-top {
    @include glyphicon;
    content: "\E253";
}

@mixin glyphicon-terminal {
    @include glyphicon;
    content: "\E254";
}

@mixin glyphicon-superscript {
    @include glyphicon;
    content: "\E255";
}

@mixin glyphicon-subscript {
    @include glyphicon;
    content: "\E256";
}

@mixin glyphicon-menu-left {
    @include glyphicon;
    content: "\E257";
}

@mixin glyphicon-menu-right {
    @include glyphicon;
    content: "\E258";
}

@mixin glyphicon-menu-down {
    @include glyphicon;
    content: "\E259";
}

@mixin glyphicon-menu-up {
    @include glyphicon;
    content: "\E260";
}
