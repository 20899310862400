@import "../base/variables";

.equipment-details-grid {
    width: 750px;
}

.vi-thumbnail {
    max-width: 32px;
    max-height: 32px;
    display: inline;
    margin-bottom: 0px;
}

.vi-cell {
    min-width: 50px;
    padding-left: 5px;
}

.vi-image {
    height: 28px;
}

// for attachments

.attachmentControl {
    display: block;
    border-radius: 5px 5px 4px 4px;
    border: 1px solid #CCCCCC;
    overflow: hidden;    
}

.attachmentControl .attachmentFooter {
    width: 100%;
    background-color: #F2F2F2;
    border-top: 1px solid #CCC;
    height: 40px
}

html.mobile .attachmentControl .attachmentFooter {
    height: 50px;
}

.attachmentControl .attachmentAdd {
    margin-top: 5px;
    margin-bottom: 3px;
    margin-left: 5px;
    position: relative;
    width: 34px;
    height: 30px;
}

html.mobile .attachmentControl .attachmentAdd {
    width: 56px;
    height: 40px;
}

.attachmentControl .attachmentControlToggle {
    width: 34px;
    height: 34px;
    font-size: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 5px;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 3px;
    float: right;
}

html.mobile .attachmentControl .attachmentControlToggle {
    width: 44px;
    height: 44px;
}

.attachmentContainerContainer {
    width: 100%;
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    min-height: 40px;
    max-height: 220px;
}

html.mobile .attachmentContainerContainer {
    font-size: 1.25rem;
    height: calc(100% - 50px);
}

.attachmentContainerContainer.attachmentList .attachmentContent {
    width: 100%;
    margin-top: 4px;
}

.attachmentContainerContainer figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.attachmentContainerContainer figcaption {
    overflow: hidden;
}

.attachmentContainerContainer .attachmentDate {
    color: #666;
}

.attachmentContainerContainer .attachmentDownload {
    padding-right: 5px;
}

html.mobile .attachmentContainerContainer .attachmentDownload {
    padding-right: 0px;
}

.attachmentContainerContainer .attachmentDesc {
    color: #548BD4;
    text-decoration: underline;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachmentContainerContainer.attachmentList figure {
    display: grid;
    grid-template-columns: 60px auto fit-content(40%);
    line-height: 40px;
    padding-right: 10px;
}

html.mobile .attachmentContainerContainer.attachmentList figure {
    line-height: 50px;
}

.attachmentContainerContainer.attachmentList .attachment {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #CCC;
}

.attachmentContainerContainer.attachmentList .attachmentContent :last-child {
    border-bottom: none;
}

.attachmentContainerContainer.attachmentList .attachmentContent > :nth-child(2n) {
    background-color: #F5F5F5F5;
}

.attachmentContainerContainer.attachmentList .attachment .ocThumbnail > span > img {
    object-fit: contain;
    padding: 5px 8px 5px 8px;
    filter: drop-shadow(1px 1px 3px rgba(0,0,0,.5));
    max-width: 100%;
    height: auto;
}

html.mobile .attachmentContainerContainer.attachmentList .attachment .ocThumbnail > span > img {
    max-height: 2em;
}

.attachmentContainerContainer.attachmentList .attachment > figcaption {
    display: contents;
}

.attachmentContainerContainer.attachmentList .attachment > figcaption > span {
    display: inline-block;
    vertical-align: middle;
    padding-right: 12px;
    width: 100%;
}

.attachmentContainerContainer.attachmentList figcaption {
    display: table-cell;
    vertical-align: middle;
}

.attachmentContainerContainer.attachmentList .attachmentDate {
    text-align: right;
    padding-right: 10px;
}

.attachmentContainerContainer.attachmentList .attachmentButton {
    display: inline-block;
    line-height: unset;
    font-size: 1.5rem;    
    padding-top: 5px;
    vertical-align: middle;
}

.attachmentContainerContainer.attachmentIcons .attachmentContent {
    margin: auto;
    padding: 8px;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, 170px);
    grid-gap: 1rem;
}

.attachmentContainerContainer.attachmentIcons .attachment {
    display: inline-block;
    vertical-align: text-top;
    place-self: center;
    border: 8px solid #FFF;
    padding: 2px 3px 0 3px;
    max-width: 100%;
    align-self: flex-end;
    width: 100%;
}

.attachmentContainerContainer.attachmentIcons .attachment > figcaption > div {
    display: flex;
    margin-top: 5px;
    align-items: center;
}

.attachmentContainerContainer.attachmentIcons .attachment .ocThumbnail > span > img {
    max-height: 100%;    
    max-width: 100%;
    object-fit: contain;
    filter: drop-shadow(1px 1px 4px rgba(0,0,0,.5));
    padding-bottom: 5px;
}

.attachmentContainerContainer.attachmentIcons figcaption {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 4em;
    vertical-align: middle;
    line-height: 1.2em;
}

.attachmentContainerContainer.attachmentIcons.attachmentTime figcaption {
    height: 5em !important;
}

.attachmentContainerContainer.attachmentIcons .attachmentDesc {
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
}

.attachmentContainerContainer.attachmentIcons .attachmentDate {
    display: inline-block;
    padding-top: 3px;
    width: 100%;
}

.attachmentContainerContainer.attachmentIcons .attachmentButton {
    display: inline-flex;
    line-height: unset;
    font-size: 1.8rem;
}

html.mobile .attachmentContainerContainer.attachmentIcons .attachmentButton {
    display: block;
    text-align: right;
    font-size: 1.5rem;
}

html.mobile .attachmentContainerContainer.attachmentIcons.attachmentTime .attachmentButton {
    font-size: 1.4rem;
}

.attachmentContainerContainer .attachment:hover .ocThumbnail > span > img {
    transform: scale(1.033);
    filter: brightness(1.125) drop-shadow(1.5px 1.5px 5px rgba(0,0,0,.667));
}

.attachmentContainerContainer.attachmentIcons .attachment:hover {
    border-radius: 5px;
    border: 8px solid #ebebeb;
}

.attachmentContainerContainer .attachment:hover {
    background-color: #ebebeb;
}

.attachmentContainerContainer figcaption {
    color: #000;
}

oc-image-viewer {
    font-size: 1rem;
    left: 0;
    top: 0;
    text-align: center;
    white-space: nowrap;
    background: rgba(20,20,20,0.75);
    overflow: hidden;
    touch-action: none;

    .processing {
        z-index: 10000;
    }

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .zoomControl {
        z-index: 30000;
        background-color: #ccc;
        position: fixed;
        top: 60px;
        right: 10px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #999;
        filter: drop-shadow(1px 1px 3px rgba(0,0,0,.5));
        display: inline-grid;
    }

    .zoomBtn {
        color: #3F3F3F;
        background-color: #FFF;
        height: 40px;
        font-size: 2rem;
        border: 1px solid #BFBFBF;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        outline: 0 !important;
        text-decoration: none;
        cursor: pointer;
        display: block;
    }

    .zoomDesc {
        font-size: 14px;
        min-width: 42px;
        text-align: center;
        padding: 3px 0;
        margin-bottom: 10px;
    }

    .zoomIn {
        border-radius: 6px 6px 2px 2px;
        margin-bottom: 10px;
    }

    .zoomOut {
        border-radius: 2px 2px 6px 6px;
    }

    .zoomReset {
        margin-bottom: 20px;
    }

    .zoomBtn:focus,
    .zoomBtn:active:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .zoomBtn:active {
        outline: 0;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .zoomBtn:hover,
    .zoomBtn:focus {
        color: #333;
        text-decoration: none;
    }

    & > img {
        z-index: 20000;
        filter: drop-shadow(1px 1px 3px rgba(0,0,0,.5));
        position: fixed;
        top: 0px;
        left: 0px;
        image-orientation: from-image;
        transform: translate3d(var(--offset-x, 0px), var(--offset-y, 0px), 0) scale3d(var(--scale, 1), var(--scale, 1), 1);
    }

    & > label {
        display: inline-block;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 30000; //seems a bit large?!
        font-family: Arial, Helvetica, sans-serif;
        filter: drop-shadow(1px 1px 3px rgba(0,0,0,.5));
        border-radius: 0 0 6px 0;
        background-color: #C1E766;
        border-bottom: 1px solid #333;
        border-right: 1px solid #333;
        vertical-align: middle;
        opacity: 75%;
        padding: 8px 16px 8px 52px;
        /*
        background-image: url("img/otracom-black.png");
        background-repeat: no-repeat;
        background-position: 0 2px;*/
    }
}



.ocThumbnail {
    display: inline;    
}

.ocThumbnail > span > img {
    image-orientation: from-image;
}

.grid-thumbviewer {
    display: grid;
    text-align: center;
}

.grid-thumbviewer .ocThumbnail > span > img {
    max-height: 75px;
    max-width: 75px;
    width: auto;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.attachment-thumbnail {
    margin-right: 10px;
}

.attachment-thumbnail .ocThumbnail > span > img {
    max-height: 160px;
    width: auto;
    height: auto;
}
