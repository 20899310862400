@import "../base/variables";

.eom-row {
    padding: 5px;
}

.eom-row-header {
    font-weight: bold;
    text-align: center;
    background-color: $grey2;
    white-space: nowrap;
    padding: 10px;
}

.eom-operational-table td {
    border: 1px solid $grey2;
    padding: 6px;
}

.eom-operational-table th {
    background-color: $grey2;
    border: 1px solid $grey4;
    padding: 6px;
}

.eom-operational-table tr:nth-child(odd) {
    background: $grey4;
}

.eom-operational-table tr.selected {
    background: $grid-selected-row-bg;
}

th .eom-operational-table {
    background-color: $grey2;
    border-width: 1px;
    border: 1px solid $grey4;
}

td .eom-operational-table {
    background-color: $grey2;
    border-width: 1px;
    border: 1px solid $grey4;
}

.eom-row-detail {
    white-space: nowrap;
}

end-of-month-details .tab-content,
end-of-month-details .tab-content > .active {
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
}