@import "../base/variables";

.butterbar {
    position: relative;
    margin-bottom: -3px;
    height: 3px;
    z-index: 1;
}

.butterbar .bar {
    position: absolute;
    height: 0;
    width: 100%;
    direction: ltr;
    text-indent: -9999px;
    background-color: $primary-color1;
}

.butterbar .bar:before {
    content: "";
    height: 3px;
    position: absolute;
    left: 50%;
    right: 50%;
    background-color: inherit;
}

.butterbar.active {
    -webkit-animation: changebar 2.25s infinite 0.75s;
    -moz-animation: changebar 2.25s infinite 0.75s;
    animation: changebar 2.25s infinite 0.75s;
}

.butterbar.active .bar {
    -webkit-animation: changebar 2.25s infinite;
    -moz-animation: changebar 2.25s infinite;
    animation: changebar 2.25s infinite;
}

.butterbar.active .bar:before {
    -webkit-animation: movingbar 0.75s infinite;
    -moz-animation: movingbar 0.75s infinite;
    animation: movingbar 0.75s infinite;
}

@-webkit-keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }

    99.9% {
        left: 0;
        right: 0;
    }

    100% {
        left: 50%;
        right: 50%;
    }
}

@-moz-keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }

    99.9% {
        left: 0;
        right: 0;
    }

    100% {
        left: 50%;
        right: 50%;
    }
}

@keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }

    99.9% {
        left: 0;
        right: 0;
    }

    100% {
        left: 50%;
        right: 50%;
    }
}

@-webkit-keyframes changebar {
    0% {
        background-color: $primary-color1;
    }

    33.3% {
        background-color: $primary-color1;
    }

    33.33% {
        background-color: $yellow1;
    }

    66.6% {
        background-color: $yellow1;
    }

    66.66% {
        background-color: $black;
    }

    99.9% {
        background-color: $black;
    }
}

@-moz-keyframes changebar {
    0% {
        background-color: $primary-color1;
    }

    33.3% {
        background-color: $primary-color1;
    }

    33.33% {
        background-color: $yellow1;
    }

    66.6% {
        background-color: $yellow1;
    }

    66.66% {
        background-color: $black;
    }

    99.9% {
        background-color: $black;
    }
}

@keyframes changebar {
    0% {
        background-color: $primary-color1;
    }

    33.3% {
        background-color: $primary-color1;
    }

    33.33% {
        background-color: $yellow1;
    }

    66.6% {
        background-color: $yellow1;
    }

    66.66% {
        background-color: $black;
    }

    99.9% {
        background-color: $black;
    }
}
