@import '../styles/base/variables';

/* Responsive Text - Mobile */
/* TODO: This is specific to iPad, might require some tweaking or second @media query for Galaxy Tab */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    html {
        font-size: 14px; /* IMPORTANT: This is the base size that "rem" units use to calculate from. DO NOT CHANGE. */
    }

    h1 {
        font-size: 2rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h2 {
        font-size: 1.5rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h3 {
        font-size: 1.17rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h4 {
        font-size: 1rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h5 {
        font-size: 0.83rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h6 {
        font-size: 0.67rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }
}

/* Responsive Text - Desktop */
@media screen and (min-device-width: 1200px) {
    html {
        font-size: 14px; /* IMPORTANT: This is the base size that "rem" units use to calculate from. DO NOT CHANGE. */
    }

    h1 {
        font-size: 2rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h2 {
        font-size: 1.5rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h3 {
        font-size: 1.17rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h4 {
        font-size: 1rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h5 {
        font-size: 0.83rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }

    h6 {
        font-size: 0.67rem; /* Bootstrap hard-codes this to pixels, so we're re-fixing it here. */
    }
}

body {
    /* This is the default / base font-size for everything to use. */
    font-size: 1rem;
}

/* Custom dialog/modal headers */
.modal-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    bottom: 2px;
}

.modal-header .close {
    color: #333;
    text-shadow: 0 1px 0 #000;
    opacity: 1.0;
}

.modal-header .close:hover {
    opacity: 1.0;
    color: #ee2722;
}

.dialog-header-error {
    background-color: #d2322d;
}

.dialog-header-wait {
    background-color: #231f20;
}

.dialog-header-notify {
    background-color: #0D0C0C;
}

.dialog-header-confirm {
    background-color: #ffffff;
}

.dialog-header-error span, .dialog-header-error h4, .dialog-header-wait span, .dialog-header-wait h4, .dialog-header-confirm span, .dialog-header-confirm h4, .dialog-header-notify h4 {
    color: #333;
}

/* Ease Display */
.pad {
    padding: 25px;
}

.ui-hide {
    display: none;
}

.multiFieldPicker > li > div {
    display: block;
    overflow: auto;
    margin-bottom: 5px;
}

.multi-select-box .no-item-found {
    top: 75px;
    position: absolute;
    border: 1px solid #ccc;
    width: 194px;
    text-align: center;
    font-weight: 600;
}
/*MultiFieldPicker*/
.multiFieldPicker {
    height: 300px;
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1000;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.multiFieldPicker > li > div {
    display: block;
    /*overflow: auto;*/
}

.multiFieldPicker table {
    height: 100%;
}

.multiFieldPicker > li {
    height: 100%;
}

.multiFieldPicker .has-no-data {
    line-height: 50px;
}

.tableContainer {
    height: 268px;
    margin-bottom: 0 !important;
    display: table;
    table-layout: fixed;
    /*width: 620px;*/
}

.table-row, .table-header {
    display: table-row;
    border: #cfdadd solid;
    border-radius: 4px;
    border-width: 1px;
}

.table-header {
    background-color: lightgray;
}

.table-row:hover {
    background-color: lightgray;
}

.selected-row {
    background-color: lightgray;
}

.table-cell, .table-header-cell {
    display: table-cell;
    border: #cfdadd solid;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    overflow: hidden !important;
}

.table-header-cell {
    border-top-width: 1px;
}

.table-header-cell > div > label {
    padding-top: 5px;
    font-weight: bold;
}

.table-header-cell > div > input {
    padding-left: 0;
    border: 0;
}

.table-cell {
    border-top-width: 0;
}

.multiFieldPicker > li > div > .table-body {
    height: 100%;
    overflow: visible;
}

.multiFieldPicker > li > div > .table-body-scroll {
    height: 200px;
    overflow-y: scroll;
}

.table-footer {
    background-color: lightgray;
    padding: 5px;
}

/*Validation*/
input.ng-invalid, textarea.ng-invalid, .amt-form-control .ng-invalid, .multiSelect .ng-invalid, select.ng-invalid {
    border-color: $error-border !important;
    background-color: $error-bg;
}

/* the actual knedo number input has this border thing which messes with the error border*/
amt-number-input div.ng-invalid-required {
    border: 1px solid $error-border;
    background-color: $error-bg;
}

amt-number-input .k-numeric-wrap.k-state-focused {
    border-color: #CCC;
    box-shadow: none;
}


input.ng-invalid-required, textarea.ng-invalid-required, .multiSelect .ng-invalid-required, button.ng-invalid-required, select.ng-invalid-required, .amt-form-control.combobox .ng-invalid-required, .amt-form-control .ng-invalid-required, .amt-form-control button.ng-invalid-required {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    border-color: $error-border !important;
    background-color: $error-bg;
}

input[type=radio].ng-invalid-required {
    box-shadow: none;
}


.k-datepicker .k-picker-wrap {
    border-color: transparent; /* Equivalent to none. Use border-style: none to turn border off if necessary. */
    padding: 0;
}

.ng-invalid-date-val {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.ng-invalid-later-then {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.ng-invalid-earlier-then {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

label.ng-required:after {
    content: '\00a0*'; /* One non-breaking space and a *, to ensure alignment. */
    position: absolute; /* prevent the asterisk from wrapping for long label text (eg. "Subsequent Inspection Interval:" */
}

label.ng-optional {  
    margin-right: 12px; /* Apparently '*' is 1 pixel smaller than nbsp and we may as well be pixel-perfect after I've gone to the trouble of figuring that out. */
}

.amt-display-horizontal .amt-display-label {
    width: 100%;
}

/* amt-displya is used on user-setting with contents transcluded in. */
.detail-property-label .amt-display-label {
    width: 100%; /* the content is in a table and right justified - trigger details*/
}

.amt-display-label td {
    vertical-align: top;
}

.required-star {
    width: 12px;
    vertical-align: top;
}


.required-star div {
    display: none;
}

.ng-required .required-star div {
    font-weight: normal;
    display: block;
    height: 2px;
    text-align: right;
    padding-top: 6px;
}



/* /Validation*/
.amt-footer {
    margin: 20px 15px 0 15px;
    padding: 20px 0 0 0;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.amt-filter {
    margin-bottom: 10px;
}

.amt-footer.amt-filter-footer {
    padding-bottom: 10px;
}

.amt-filter-dropdown {
    background: #efeff2;
}

.amt-filter-dropdown .options {
    min-width: 165px;
}

.amt-filter-radio {
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    padding: 4px 8px 5px 8px;
    box-sizing: border-box;
}

.amt-filter-radio input[type="radio"] {
    margin-right: 5px;
    margin-bottom: 4px;
}

.amt-filter-radio span {
    margin-right: 10px;
    position: relative;
    bottom: 2px;
}

.amt-filter-radio.ng-invalid-required {
    border-color: $error-border !important;
    background-color: $error-bg;
}

.amt-filter-multiselect-add, .amt-filter-multiselect-remove {
    margin: 5px !important;
}

.amt-filter-multiselect-close {
    box-shadow: none !important;
    position: absolute;
    right: 10px;
}

.amt-filter-footer, .amt-wider-footer {
    margin: 20px 0 0 0;
}

.amt-filter-arrow {
    float: left;
    margin: 0 10px 0 0;
    cursor: pointer;
}

.amt-filter-arrow:hover {
    color: #ee2722;
}

div.datepicker {
    width: 100%;
}

.datepicker table {
    width: 230px;
    appearance: none;
}

.datepicker ul {
    background-color: #F5F5F5;
}

.datepicker table thead button {
    background-color: #F5F5F5;
}

.datepicker table button {
    border: none;
}

/* Error Page */
.notification {
    text-align: center;
    margin: 30px 15px;
}

.notification .error {
    padding-top: 70px;
    padding-bottom: 140px;
}

.notification .error-icon {
    font-size: 150px;
}

.notification h2 {
    margin-top: -24px;
}

/* Busy Loading */
.busy-loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: black;
    opacity: .6;
}

.busy-loading .spin {
    width: 30px;
    height: 30px;
    border: 4px solid transparent;
    border-radius: 15px;
    border-top-color: #fff;
    border-left-color: #ddd;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    animation: spin 0.3s infinite linear;
    box-sizing: border-box;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

/*#region File Upload*/
.file-upload-wrapper {
    border: 1px solid lightgray;
    border-radius: 4px;
}

.file-upload-wrapper .no-attachment, .single-file-upload-wrapper .no-attachment {
    text-align: left;
    padding: 10px;
}

.file-upload-wrapper .row, .single-file-upload-wrapper .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.file-upload-wrapper .file-upload-container, .single-file-upload-wrapper .file-upload-container {
    max-height: 290px !important;
    overflow-y: auto;
}

.file-upload-wrapper .file-upload-container .file-upload-item, .single-file-upload-wrapper .file-upload-container .file-upload-item {
    padding: 10px;
}


.file-upload-wrapper .file-upload-container .file-name, .single-file-upload-wrapper .file-upload-container .file-name {
    color: #333333;
    display: block;
    padding-left: 0;
    font-size: 75%;
    font-weight: bold;
    line-height: 10px;
    margin-top: 5px;
    margin-left: 5px;
}


.file-upload-wrapper .file-upload-container .file-name:hover, .single-file-upload-wrapper .file-upload-container .file-name:hover {
    color: #333333;
}


.file-upload-wrapper .file-upload-container .file-size, .single-file-upload-wrapper .file-upload-container .file-size {
    background-color: white;
    color: #333333;
    display: block;
    text-align: left;
    padding-left: 0;
}


.file-upload-wrapper .file-upload-container .file-source, .single-file-upload-wrapper .file-upload-container .file-source {
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0;
    height: 120px;
    width: 120px;
    text-align: center;
    margin-left: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}


.file-upload-wrapper .file-upload-container .file-source a, .single-file-upload-wrapper .file-upload-container .file-source a {
    line-height: 100px;
}

.file-upload-wrapper .file-upload-container .file-source img,
.file-upload-wrapper .file-upload-container .file-source video, .single-file-upload-wrapper .file-upload-container .file-source img,
.single-file-upload-wrapper .file-upload-container .file-source video {
    max-height: 100px;
    max-width: 100px;
}


.file-upload-wrapper .glyphicon-file, .single-file-upload-wrapper .glyphicon-file {
    font-size: 7.2em;
    color: #171515;
}
/*#endregion File Upload*/

.amt-view-header {
    width: 100%;
    padding: 15px 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.amt-view-header h1 {
    line-height: 1.5em;
}

.amt-well-header {
    margin: 10px 0 15px 0;
    height: 35px;
    display: none;
}

.amt-grid-dropdown {
    position: relative;
}

.k-content {
    padding: 10px;
}

.k-block {
    margin-bottom: 10px;
}

.k-block > div {
    padding: 10px;
}

.k-block > div > div {
    padding: 5px;
}

.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.login-screen-header {
    width: 110%;
}

.login-form .logo {
    text-align: center;
}

.login-page-title {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
}

.login-form .submit-login {
    text-align: center;
}
/* styles for inputs */
.dropdown-menu {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.amt-toolbar {
    margin-bottom: 10px;
}

.amt-field-error {
    border-color: $error-border;
    background-color: $error-bg;
}

.error .content {
    text-align: center;
}

.error .content .icon-unlink {
    color: #801611;
    display: block;
    font-size: 180px;
    text-shadow: 0 1px 0 #e75047;
}

.error .footer {
    background-color: #1b1b1b;
    border-bottom: 1px solid #ffffff;
    bottom: 0;
    display: block;
    height: 45px;
    left: 0;
    margin: 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    color: #333333;
    padding: 0;
    box-sizing: border-box;
}

span.float-validation {
    display: none;
}

.error .footer a {
    box-sizing: content-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    width: 50%;
}

.error .footer .info {
    border-right: 1px solid #363636;
    box-sizing: border-box;
}

.number-format-position {
    text-align: right;
}

.dropdown-item {
    left: -450%;
}

.ng-invalid-float + span.float-validation {
    display: block;
}

.button-primary-style.btn.btn-primary {
    float: right !important;
}

.k-grid td {
    padding: 0.2em .6em;
    line-height: 1.6em;
}

.k-header {
    border: $grid-border solid 1px;
    background: $grid-header-bg;
}

.tab-content .well {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
}

.amt-footer .btn.btn-primary:focus {
    color: #ffffff;
    background-color: #231F20; /*#e91d26;*/
    border-color: #231F20; /*#d43f3a;*/
    border: none;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.column-selector {
    width: 250px;
}

.k-combobox .input-multi-select {
    width: 99%;
}

.bootstrap-timepicker.amt-timepicker-wrapper {
    float: left;
    width: 100%;
}

.bootstrap-timepicker-widget.dropdown-menu {
    padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
    display: inline-block;

    //TODO: why?!
    //min-width: 0;
    //width: 3.5%;
}

.bootstrap-timepicker-hour, .bootstrap-timepicker-minute {
    width: 25px;
    margin: 0;
    text-align: center;
}

.bootstrap-timepicker-widget table td {
    text-align: center;
}

.k-filter-menu .k-animation-container .k-list-container.k-popup.k-group.k-reset {
    width: 100% !important;
}

.modal-xlg .modal-lg {
    width: 90%;
}

.amt-popup-select-wrapper {
    width: 100%;
}

.amt-popup-select-wrapper .amt-popup-select-content {
    width: 100%;
}

.bootstrap-timepicker.amt-timepicker-wrapper .amt-timepicker {
    width: 39%;
    float: left;
    margin-right: 10px;
}

.amt-popup-select-wrapper .amt-popup-select-arrow-wrapper {
    background-color: white;
}

.bootstrap-timepicker.amt-timepicker-wrapper i.icon-time {
    font-size: 30px;
}

.bootstrap-timepicker.amt-timepicker-wrapper .amt-timepicker {
    width: 39%;
    float: left;
    margin-right: 10px;
}

.bootstrap-timepicker.amt-timepicker-wrapper i.icon-time {
    font-size: 30px;
}

.k-filter-menu .k-animation-container .k-list-container.k-popup.k-group.k-reset {
    width: 100% !important;
}

/*Error messages*/
.earlier-then.error-message-tooltip, .later-then.error-message-tooltip {
    color: #a94442;
    position: absolute;
    right: -18px;
    top: 4px;
}

.form-control-datepicker.ng-invalid {
    border: 1px solid $error-border !important;
    background-color: $error-bg;
    border-radius: 6px;
}

.open > .dropdown-menu {
    float: left;
    width: auto;
}

.dropup .dropdown-menu {
    right: 0;
}

.spin-up, .spin-down {
    display: inline;
    overflow: hidden;
}

.spin-up .glyphicon {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    margin-left: -7px;
    margin-top: 1px;
}

.spin-up .glyphicon[disabled] {
    cursor: not-allowed;
}

.spin-down .glyphicon {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    margin-left: -7px;
    margin-top: 18px;
}

.spin-down .glyphicon[disabled] {
    cursor: not-allowed;
}

.loading {
    color: #666666;
    font-style: italic;
    font-size: .9em;
}

.radio-block {
    display: block;
}

/* AMT Display classes */
.detail-property-value {
    margin-top: 9px;
}

.detail-property-label,
.detail-property-label td {
    text-align: right;
    font-weight: bold;
}

.detail-property-label label.control-label {
    text-align: right;
}

/* Important, otherwise ng-optional/ng-required will override to normal weight */
.detail-property-label .ng-optional, .detail-property-label .ng-required {
    font-weight: bold;
}

.large-content {
    font-size: large;
    color: black;
    font-weight: bold;
}

/* Important, otherwise ng-optional/ng-required will override to normal weight */
.large-content .ng-optional, .large-content .ng-required {
    font-weight: bold;
}
/* End AMT Display classes */

.placeholder {
    color: #999;
}

.k-numerictextbox {
    width: 100%
}

.rounded-borders {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.alternating-row-white {
    padding: 15px; /* Padding left/right is already 15px, so this just adds top/bottom */
    background-color: $white;
}

.alternating-row-grey {
    padding: 15px; /* Padding left/right is already 15px, so this just adds top/bottom */
    background-color: $grey;
}


.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex {
    //Dont use auto for flex as certain browsers (chrome, safari) ignore min
    //height of flex items which causes scrolling issues
    flex: 1 0 0px;
}

.no-flex, .flex-none {
    flex: none;
}

.progress {
    margin-top: 10px; //not sure we want this, but it was a random css rule from _amtFileUpload.scss (which I deleted) which may effect other elements as this is a generically named class
}
