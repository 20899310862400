@import "../base/variables";

.quick-search-button {
    border-radius: 3px;
    padding-top: 2px;
    padding-left: 10px;
    width: 28px;
    height: 28px;
    text-align: center;
    background-color: $black;
    position: absolute;
    top: 17px;
    right: 4px;
    box-sizing: border-box;
}

.quick-search-button > span {
    margin-left: -5px;
    color: $white;
}

.quick-search-results-container {
    color: $black;
    border-radius: 2px;
    max-height: 300px;
    overflow-y: auto;
}

.quick-search-result-header-cell {
    border: 1px solid $black3;
}

.quickSearchResultCell {
    border: 1px solid $black3;
    padding: 5px !important;
}

.quickSearchResultLink {
    color: $blue1 !important;
    padding: 5px !important;
}

.quickSearchResultLink.hover {
    color: $black !important;
}





