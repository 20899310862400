@import "../base/variables";

.login-footer {
    display: none;
}

.location-indicator {
    display: none;
}

.cache-status-indicator {
    position: fixed;
    left: 14px;
    bottom: 5px;
    font-size: 1.5rem;
}

.cache-status-indicator .glyphicon-refresh {
    font-size: 1.5em; 
}

@media (min-height: 400px) {
    .login-footer {
        display: block;
        position: fixed;
        bottom: 25px;
        left: 0;
        right: 0;
    }

    .location-indicator {
        display: block;
        position: fixed;
        bottom: 2px;
        left: 2px;
        color: $white;
        font-size: .9rem;
    }

    .cache-status-indicator {
        left: 20px;
        bottom: 1.2rem;
        font-size: 1rem;
    }
}

.role-user-list {
    max-height: 150px;
    list-style: none;
    display: block;
    overflow: auto;
    padding-left: 0px;
    border: 1px solid $black3;
    border-radius: 3px;
    padding: 3px;
}

.permission-scroller {
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 15px;
}

.permission-break {
    margin-bottom: 10px;
    border-top: 1px solid $black3;
}

.admin-display-value {
    text-align: left;
}

.admin-display-value-right {
    text-align: right;
}
