@import "variables";

.k-block, .k-header, .k-grid-header, .k-toolbar, .k-grouping-header,  .k-button, .k-draghandle, html .km-pane-wrapper .k-header {
    background-color: $grid-header-bg;
}



.k-pager-wrap {
    background-color: $grid-footer-bg;
}

.k-pager-wrap .k-dropdown {
    width: 5.5em;
}

amt-display .display-background-dk {
    /* the in-built padding has a background so pad a little more */
    margin-bottom: 7px;
}

/*
div.ng-invalid-required {
    border: 1px solid #a94442;
}
*/
/*
    the time picker will use the z-index of it's closest parent with and index, but then attach to the body.
    In our case the child dialog is 120, which is below the modal dialog grey background at 150.   
    This is a really bad hack. But it's cost me too much time already.              
*/
amt-time-picker {
    position: relative;
    z-index: 141;
}

/* Need to override border because an amt-link-column with a type="text" attribute will trigger the [type="text"] style
   which adds a border and breaks the grid alignment. */
amt-link-column {
    border: 0 !important;
}

.amt-footer {
    padding: 20px 0 0 0;
    text-align: right;
    border-top: 1px solid $black4;
}

.input-lg {
    height: 45px;
}

.input-group-addon {
    border-color: $badge-color;
    background-color: $off-white;
}

.ng-animate .bg-auto:before {
    display: none;
}

[ui-view].ng-leave {
    display: none;
}

[ui-view].ng-leave.smooth {
    display: block;
}

.smooth.ng-animate {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.fade-in-right-big.ng-enter {
    -webkit-animation: fadeInRightBig 0.5s;
    animation: fadeInRightBig 0.5s;
}

.fade-in-right-big.ng-leave {
    -webkit-animation: fadeOutLeftBig 0.5s;
    animation: fadeOutLeftBig 0.5s;
}

.fade-in-left-big.ng-enter {
    -webkit-animation: fadeInLeftBig 0.5s;
    animation: fadeInLeftBig 0.5s;
}

.fade-in-left-big.ng-leave {
    -webkit-animation: fadeOutRightBig 0.5s;
    animation: fadeOutRightBig 0.5s;
}

.fade-in-up-big.ng-enter {
    -webkit-animation: fadeInUpBig 0.5s;
    animation: fadeInUpBig 0.5s;
}

.fade-in-up-big.ng-leave {
    -webkit-animation: fadeOutUpBig 0.5s;
    animation: fadeOutUpBig 0.5s;
}

.fade-in-down-big.ng-enter {
    -webkit-animation: fadeInDownBig 0.5s;
    animation: fadeInDownBig 0.5s;
}

.fade-in-down-big.ng-leave {
    -webkit-animation: fadeOutDownBig 0.5s;
    animation: fadeOutDownBig 0.5s;
}

.fade-in.ng-enter {
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

.fade-in.ng-leave {
    -webkit-animation: fadeOut 0.5s;
    animation: fadeOut 0.5s;
}

.fade-in-right.ng-enter {
    -webkit-animation: fadeInRight 0.5s;
    animation: fadeInRight 0.5s;
}

.fade-in-right.ng-leave {
    -webkit-animation: fadeOutLeft 0.5s;
    animation: fadeOutLeft 0.5s;
}

.fade-in-left.ng-enter {
    -webkit-animation: fadeInLeft 0.5s;
    animation: fadeInLeft 0.5s;
}

.fade-in-left.ng-leave {
    -webkit-animation: fadeOutRight 0.5s;
    animation: fadeOutRight 0.5s;
}

.fade-in-up.ng-enter {
    -webkit-animation: fadeInUp 0.5s;
    animation: fadeInUp 0.5s;
}

.fade-in-up.ng-leave {
    -webkit-animation: fadeOutUp 0.5s;
    animation: fadeOutUp 0.5s;
}

.fade-in-down.ng-enter {
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
}

.fade-in-down.ng-leave {
    -webkit-animation: fadeOutDown 0.5s;
    animation: fadeOutDown 0.5s;
}


/*Status*/
.amt-status {
    min-height: 25px;
    margin: -2px -8px;
    border-bottom: 1px solid #F9F5F5;
}

.k-loading-image {
    background-image: url("/Content/common/spinner.gif") !important;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: center;
}

.k-grid-content {
    min-height: 26px;
}

.k-grid td {
    white-space: nowrap; /*on IE, white-space must be nowrap to use text-overflow*/
    line-height: 28px;
}

.k-grid-content tr:last-child > td, .k-grid-content-locked tr:last-child > td {
    border-bottom-width: 1px;
}

.k-alt .k-state-selected {
    background-color: #f8d1ce;
}

.k-grid th input[type=checkbox] {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0px !important;
    margin-top: 0px !important;
}

.k-grid td input[type=checkbox] {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0px !important;
    margin-top: 0px !important;
}

.k-grid td .k-widget.k-tooltip.k-tooltip-validation.k-invalid-msg {
    position: fixed;
}

.amt-well-header-collapse {
    margin-right: 10px;
    cursor: pointer;
}

.amt-well-header.pointer {
    cursor: pointer;
}

.amt-position-label {
    float: left;
}

.amt-position-tooltip {
    padding-top: 10px;
    line-height: 35px;
}

.amt-position-tooltip amt-tooltip {
    margin-left: 5px;
}

.amt-position-tooltip span {
    line-height: 0;
}


.edit-budget-equipment .percentage {
    line-height: 30px;
    padding-left: 25px;
}

.amt-padding-bottom-0 {
    padding-bottom: 0;
}

.amt-margin-top-0 {
    margin-top: 0;
}


.k-grid-edit-row .k-command-col {
    text-align: center;
}

.k-textbox:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #adadad;
}

.k-primary {
    border-color: $grey2;
}

.k-primary:hover {
    background-color: $black;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: $grey2;
}

.k-animation-container input[type="radio"] {
    margin: 0px 4px 0px 8px;
}

.k-button:focus:not(.k-state-disabled):not([disabled]) {
    -webkit-box-shadow: 0 0 7px 0 #adadad;
    box-shadow: 0 0 7px 0 #adadad;
}

.k-state-selected .actionrequired {
    background-color: #454344;
}

.amt-title-text {
    display: block;
    line-height: 1.3em;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
}

html.mobile amt-display label {
    font-size: $large-font-size-root;
}

.list-group-item.active {
    color: $white;
    background-color: lightgray;
    border-color: lightgray !important;
}

.list-group-item {
    border-color: #e7ecee;
    padding-right: 15px;
}

.amt-invalid {
    border-color: #a94442 !important;
}

.amt-filter-multiselect-add {
    font-size: 14px !important;
}

.amt-filter-multiselect-remove {
    font-size: 14px !important;
}
  

.timepicker {
    height:36px;
}

.timepicker .form-control {
    height: 36px;
}

.date-time-picker .k-picker-wrap.k-state-default {
    height:34px;
}

.date-time-picker .k-picker-wrap.k-state-hover .k-input {
    height: 34px !important;
}


// hide this class - the bootstrap dropdown (amt-dropdrown) adds a div element witht his class that
// is not being hidden correctly in some cases and causing user to double click some buttons
.dropdown-backdrop {
    display: none !important;
}

amt-checkbox > [type="checkbox"] {
    margin-top: 0px !important;
}

.form-checkbox > [type="checkbox"] {
    margin-top: 7px !important;
}

html.mobile amt-checkbox {
    font-size: $large-font-size;

    input[type=checkbox] {
        height: 40px;
        width: 40px;
        margin-right: 0px !important;
        margin-top: 0px !important;
    }
}

// --- Wizard  ---
html.mobile .wizard-step-title {
    font-size: $large-font-size;
    white-space: normal;
}

html.mobile .btn.wizard-button {
    height: unset;
}

html.mobile .amt-wizard .list-group {
    width: 310px;
}

html.mobile .amt-wizard {
    margin-bottom: 0px;
}
// --- Wizard (END)  ---

html.mobile .multiselect-list-container {
    font-size: $large-font-size-root !important;
}

html.mobile .amt-form-control .amt-form-control-button {
    height: $large-control-height;
}

html.mobile amt-combobox > .amt-form-control .item-display {
    font-size: $large-font-size-root;
    vertical-align: middle;
    height: unset;
    line-height: 42px !important;
    padding: 2px 2px 2px 10px;
    margin-right: 30px;
}

html.mobile amt-multi-select > .amt-form-control .item-display {
    font-size: $large-font-size-root;
    vertical-align: middle;
    height: unset;
    line-height: 1.8 !important;
    padding: 2px 2px 2px 10px;
    margin-right: 30px;
}

html.mobile amt-combobox > .amt-form-control .closebutton {
    font-size: 2.5rem;
    line-height: 47px !important;
    padding: 0px 5px 0px 0px;
}

html.mobile .amt-form-control .caret {
    margin-top: 22px;
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

html.mobile amt-combobox .option {
    font-size: 1.5rem;
    line-height: 2.375rem;
    padding-left: 5px;
}

html.mobile .combobox .options {
    max-height: 220px;
}

html.mobile .amt-form-control .amt-form-control-button {
    height: $large-control-height;
    padding: 0px;
}

html.mobile amt-multi-select input.item-display {
    margin-right: 52px;
    //margin-top: 10px !important;
}


html.mobile amt-multi-select {
    .multiSelect button {
        font-size: $large-font-size-root;
    }

    .multiSelect .line {
        max-height: 70px;
    }
}

html.mobile .amt-form-control .item-display {
    font-size: $large-font-size-root;
}

amt-wizard-below-steps {
    padding-top: 16px;
}


.amt-template-column-link {
    color: #548BD4;
    text-decoration: underline;
    margin: 0;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

.dialog-grid {
    .k-grid-content, .k-auto-scrollable {
        max-height: 345px;
    }
    .k-grid-content{
        min-height: 250px;
    }
    .k-pager-numbers, .k-reset{
        display: flex;
    }

    .k-pager-refresh, .k-link {
        right: 0;
    }
}



.ng-scope {
    &.k-state-selected {
        .amt-template-column-link,
        .text-left {
            color: #fff;
            text-decoration: none;
        }
    }
}