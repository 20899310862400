.datepicker table {
    appearance: none;
    width: 230px;
}

.datepicker ul {
    background-color: #F5F5F5 !important;
}

.datepicker .dropdown-menu {
    width: auto !important;
}

.datepicker table thead button {
    background-color: #F5F5F5;
}

.datepicker table button {
    border: none;
}

.k-datepicker .k-select .k-icon {
    margin-top: 8px;
}

/*.datepicker .caret-container {
    padding: 5px 8px 5px 7px !important;
}*/


/* TODO: move this to a timepicker.css class when I figure out how to include it */
.bootstrap-timepicker .input-group-addon {
    cursor: pointer;
}

.timepicker-disabled {
    cursor: default !important;
    background-color: #eaeaea !important;
}

.timepicker-disabled input[type=text] {
    color: gray;
}

.timepicker-disabled > i {
    color: darkgray;
}
